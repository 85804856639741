import { Box, Heading, Stack, Text } from '@chakra-ui/layout';
import dynamic from 'next/dynamic';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
import PageTitleBanner from '@components/PageTitleBanner';

import Image, { StaticImageData } from 'next/image';
import { Accommodations, Provider, ProviderService } from '~/contexts/Provider';
import CareTypePricing from './CareTypePricing';

interface Props {
  provider: Provider;
  service: ProviderService;
  description: string;
  careType: string;
  image?: StaticImageData | string;
  accommodations: Array<Accommodations>;
  withButton?: boolean;
}

const ProviderCareTypeDetails: React.FC<Props> = ({
  provider,
  service,
  accommodations,
  careType,
  description,
  image,
  withButton,
}) => {
  return (
    <div>
      <PageTitleBanner
        provider={provider}
        service={service}
        headingElement="h4"
        displayReviews={false}
        headingSize="xl"
      />
      <Container paddingTop={{ base: '6', lg: '0' }}>
        <Box py="4">
          <Box height="96" position="relative" rounded="lg" overflow="hidden">
            {image && (
              <Image src={image} alt="" fill style={{ objectFit: 'cover' }} />
            )}
          </Box>
        </Box>

        <Box display="flex" gap="8" py="8">
          <Stack>
            <Heading as="h5" size="lg">
              {careType}
            </Heading>
            <Text>{description}</Text>
          </Stack>
        </Box>
      </Container>
      {accommodations && (
        <Box bg="gray.100" py="8" roundedBottom="lg">
          <CareTypePricing
            accommodations={accommodations}
            withButton={withButton}
          />
        </Box>
      )}
    </div>
  );
};

export default ProviderCareTypeDetails;
