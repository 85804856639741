const dollarUSLocale = Intl.NumberFormat('en-US');

export const formatNumberLocale = (num: number): string => {
  return dollarUSLocale.format(num);
};

export const formatRating = (rating: number | string) => {
  const parsedRating = typeof rating === 'string' ? parseFloat(rating) : rating;
  return (Math.floor(parsedRating * 10) / 10).toFixed(1);
};

export const parsePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/[^\d]/g, '');
};

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value);
};

export const formatDistance = (distance: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'mile',
    unitDisplay: 'long',
    maximumFractionDigits: 0,
  }).format(distance);
};

export const formatPercentage = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 0,
  }).format(value / 100);
};
