import client from './client';
import {
  GraphQLClient,
  RequestDocument,
  Variables,
  RequestOptions,
} from 'graphql-request';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { VariablesAndRequestHeadersArgs } from 'graphql-request/src/types';
import { THIRTY_MINUTES_IN_MILLISECONDS } from '~/constants';
const baseUrl = process.env.NEXT_PUBLIC_CANARIO_HOST;
const endpoint = `${baseUrl}/v1/magnolia/cache-store?path=/.graphql&scope=graphql&duration=${THIRTY_MINUTES_IN_MILLISECONDS}`;
const options = {};
const cachedClient = new GraphQLClient(endpoint, options);

const originalCachedClientRequest = cachedClient.request.bind(cachedClient);

/**
 * A new request method that will first try to grab the data from ModMon cache
 * and if it fails or is in development mode, it will fall back to Magnolia
 * The overloading is necessary to keep the same signature as the original request method.
 */
async function newRequestCachedClient<T, V extends Variables = Variables>(
  document: RequestDocument | TypedDocumentNode<T, V>,
  ...variablesAndRequestHeaders: VariablesAndRequestHeadersArgs<V>
): Promise<T>;
async function newRequestCachedClient<T, V extends Variables = Variables>(
  options: RequestOptions<V, T>
): Promise<T>;
async function newRequestCachedClient<T, V extends Variables = Variables>(
  documentOrOptions:
    | RequestDocument
    | TypedDocumentNode<T, V>
    | RequestOptions<V>,
  ...variablesAndRequestHeaders: VariablesAndRequestHeadersArgs<V>
): Promise<T> {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NEXT_PUBLIC_MGNL_HOST?.endsWith('/author')
  ) {
    // @ts-ignore - this is a hack to patch the request method
    return client.request(...arguments);
  }

  try {
    // @ts-ignore - this is a hack to patch the request method
    return await originalCachedClientRequest(...arguments);
  } catch (error) {
    console.error(error);
    // @ts-ignore - this is a hack to patch the request method
    return client.request(...arguments);
  }
}

cachedClient.request = newRequestCachedClient;

export default cachedClient;
