import { MagnoliaNode, Page } from '~/types/Magnolia';

export function findDataByTemplate(obj: Page, template: string) {
  if (
    obj?.hasOwnProperty('mgnl:template') &&
    obj?.['mgnl:template'] === template
  ) {
    return obj;
  }

  for (var key in obj) {
    if (typeof obj[key] === 'object') {
      var result = findDataByTemplate(obj[key], template);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export const getNodes = <T = any>(root?: MagnoliaNode): Array<T> => {
  if (!root) return [];
  return root['@nodes'].map((nodeName) => root[nodeName]);
};
