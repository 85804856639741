import { Heading as ChakraHeading } from '@chakra-ui/layout';
import { createID } from '@utils/strings';
import {
  Link,
  ListItem,
  Text as ReactText,
  UnorderedList,
} from '@chakra-ui/react';
import parse, {
  attributesToProps,
  domToReact,
  Element,
  HTMLReactParserOptions,
  DOMNode,
} from 'html-react-parser';
import dynamic from 'next/dynamic';

const Table = dynamic(() => import('./Table'));
const PhoneNumber = dynamic(() => import('./PhoneNumber'));
interface HtmlToReactProps {
  html: string;
}

const HtmlToReact: React.FC<HtmlToReactProps> = ({ html }) => {
  const parserOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        if (domNode?.attribs?.href?.includes('tel:')) {
          const props = attributesToProps(domNode?.attribs);
          return (
            <PhoneNumber props={props}>
              {domToReact(domNode.children as DOMNode[], parserOptions)}
            </PhoneNumber>
          );
        }
        switch (domNode.tagName) {
          case 'ul':
            return (
              <UnorderedList>
                {domToReact(domNode.children as DOMNode[], parserOptions)}
              </UnorderedList>
            );
          case 'li':
            // If the list item is not nested, wrap it in an unordered list CME-2842
            const parentNode = domNode.parentNode as Element;
            if (parentNode === null || parentNode?.name !== 'ul') {
              return (
                <UnorderedList>
                  <ListItem fontSize="sm">
                    {domToReact(domNode.children as DOMNode[], parserOptions)}
                  </ListItem>
                </UnorderedList>
              );
            }
            return (
              <ListItem fontSize="sm">
                {domToReact(domNode.children as DOMNode[], parserOptions)}
              </ListItem>
            );
          case 'a':
            const props = attributesToProps(domNode.attribs);
            return (
              <Link {...props} fontSize="inherit" textDecoration="underline">
                {domToReact(domNode.children as DOMNode[], parserOptions)}
              </Link>
            );
          case 'em':
            return (
              <ReactText as="i">
                {domToReact(domNode.children as DOMNode[], parserOptions)}
              </ReactText>
            );
          case 'h2':
            return (
              <ChakraHeading
                as="h2"
                size="xl"
                id={createID(domNode.children[0]['data'])}
                textAlign="left"
                style={{ scrollMarginTop: 48 }}
              >
                {domToReact(domNode.children as DOMNode[], parserOptions)}
              </ChakraHeading>
            );
          case 'h3':
            return (
              <ChakraHeading
                as="h3"
                size="lg"
                id={createID(domNode.children[0]['data'])}
                textAlign="left"
                style={{ scrollMarginTop: 48 }}
              >
                {domToReact(domNode.children as DOMNode[], parserOptions)}
              </ChakraHeading>
            );
          case 'table': {
            const props = attributesToProps(domNode?.attribs);
            return (
              <Table props={props}>
                {domToReact(domNode.children as DOMNode[], parserOptions)}
              </Table>
            );
          }
        }
      }
    },
  };
  return <>{parse(html, parserOptions)}</>;
};

export default HtmlToReact;
