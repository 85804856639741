// Handles the complexity of determining wether the provider has amenities
import {
  Amenities,
  AlgoliaAmenities,
  LegacyAmenities,
} from '~/contexts/Provider';
import { StringToIconKeys } from '@components/RenderIcon';
import { buildGroups } from '@components/OfferingListing/legacy/ProviderDetailsAmenitiesLegacy';
import amenityGroups from '~/config/amenity-groups';
import startCase from 'lodash/startCase';

// across the legacy schema and the new schema
export const isAmenitiesEmpty = (
  amenities: Amenities | AlgoliaAmenities | LegacyAmenities | undefined,
  domain: string | undefined,
  t: (key: string, fallback: string) => string
) => {
  // New Schema
  if (Array.isArray(amenities)) {
    return amenities.length === 0;
  }

  const { groups } = buildGroups(amenities as LegacyAmenities, t);
  const filteredAmenities = groups.filter(({ amenities, label }) => {
    const shouldFilterOutGroup =
      domain === 'caring.com' && label === 'Medicare';
    return amenities.length > 0 && !shouldFilterOutGroup;
  });

  if (filteredAmenities.length === 0) {
    return filteredAmenities.length === 0;
  }
  // Legacy Schema
  return (
    amenities &&
    Object.keys(amenities).every((key) => {
      return (
        amenities[key] === null ||
        amenities[key] === false ||
        amenities[key] === ''
      );
    })
  );
};

const amenityCategoryIcon: { [key: number]: StringToIconKeys } = {
  [1]: 'FaAmbulance',
  [2]: 'FaSwimmer',
  [3]: 'FaBuilding',
  [4]: 'BsClipboardPlus',
  [5]: 'FaUserFriends',
  [6]: 'IoMdNutrition',
  [7]: 'MdFastfood',
  [8]: 'MdLuggage',
  [9]: 'FaMoneyBill',
  [10]: 'IoIosFitness',
  [11]: 'FaPaintBrush',
  [12]: 'FaSuitcase',
  [13]: 'GiHealthNormal',
  [14]: 'MdCleaningServices',
  [15]: 'FaCouch',
  [16]: 'MdLanguage',
  [17]: 'TbLicense',
  [18]: 'FaBookmark',
  [19]: 'GiFlowerPot',
  [20]: 'RiCarFill',
  [21]: 'FaBath',
  [22]: 'MdPets',
  [23]: 'BsFillPersonLinesFill',
  [24]: 'RiTvFill',
  [25]: 'MdSecurity',
  [26]: 'FaUserNurse',
  [27]: 'TbCertificate',
  [28]: 'RiPsychotherapyFill',
  [29]: 'FaShoppingCart',
  [30]: 'MdKitchen',
  [31]: 'MdVerifiedUser',
  [32]: 'MdOutlineViewList',
  [33]: 'IoIosPeople',
  [34]: 'FaHome',
};

export const getAmenityCategoryIcon = (amenityId: number | undefined) => {
  return amenityId && amenityCategoryIcon[amenityId]
    ? amenityCategoryIcon[amenityId]
    : 'MdHomeWork';
};

export const parseAlgoliaAmenities = (
  amenities: AlgoliaAmenities | undefined | null
): Amenities => {
  if (amenities === undefined || amenities === null) {
    return [];
  }
  const parsedAmenities = amenities.map((amenity) => {
    return {
      id: amenity.id,
      amenityName: amenity.amenityType.name,
      amenityId: amenity.amenityType.id,
      amenityCategoryName: amenity.amenityType.amenityCategory.name,
      amenityCategoryId: amenity.amenityType.amenityCategory.id,
    };
  });
  return parsedAmenities;
};

export const checkLegacyAmenityValue = (
  amenity: string | boolean | null | undefined
): boolean => {
  if (
    amenity === null ||
    amenity === false ||
    amenity === '' ||
    amenity === undefined
  ) {
    return false;
  }
  return true;
};

const featuredAmenityTypeIds = [2, 98, 105, 122];

const filterAmenities = (amenities) => {
  return amenities.filter((amenity) => {
    const amenityId =
      amenity.amenityId || amenity.amenityTypeId || amenity.amenityType?.id;
    return featuredAmenityTypeIds.includes(amenityId || -1);
  });
};

export const getFeaturedAmenities = (
  amenities: Amenities | LegacyAmenities
): {
  pets: boolean;
  internet: boolean;
  wheelchair: boolean;
  transportation: boolean;
} => {
  if (Array.isArray(amenities)) {
    const newAmenities = filterAmenities(amenities);

    return {
      pets: newAmenities.some(
        (amenity) =>
          amenity.amenityId === 98 ||
          amenity.amenityTypeId === 98 ||
          amenity?.amenityType?.id === 98
      ),
      internet: newAmenities.some(
        (amenity) =>
          amenity.amenityId === 105 ||
          amenity.amenityTypeId === 105 ||
          amenity?.amenityType?.id === 105
      ),
      wheelchair: newAmenities.some(
        (amenity) =>
          amenity.amenityId === 2 ||
          amenity.amenityTypeId === 2 ||
          amenity?.amenityType?.id === 2
      ),
      transportation: newAmenities.some(
        (amenity) =>
          amenity.amenityId === 122 ||
          amenity.amenityTypeId === 122 ||
          amenity?.amenityType?.id === 122
      ),
    };
  } else {
    return {
      pets: checkLegacyAmenityValue(amenities?.petsAllowed),
      internet: checkLegacyAmenityValue(amenities?.internetEnabledRooms),
      wheelchair: checkLegacyAmenityValue(
        amenities?.walkingWheelchairAssistance
      ),
      transportation: checkLegacyAmenityValue(
        amenities?.residentTransportation
      ),
    };
  }
};

export const getExtraAmenities = (amenities: LegacyAmenities): Amenities => {
  const extraAmenities: Amenities = [];
  Object.keys(amenityGroups).forEach((groupKey) => {
    amenityGroups[groupKey].forEach((amenityKey, index) => {
      const isExtraAmenity = !!amenityKey.match(
        /extra$|stateLicenses$|licenses$/i
      );
      if (
        isExtraAmenity &&
        amenities[amenityKey] !== undefined &&
        amenities[amenityKey] !== '' &&
        amenities[amenityKey] !== null
      ) {
        extraAmenities.push({
          id: `99999998${index}`,
          amenityCategoryName: 'Additional Amenity Information',
          amenityCategoryId: null,
          amenityId: null,
          amenityName: `${startCase(amenityKey.replace(/Extra$/i, ''))}: ${
            amenities[amenityKey]
          }`,
        });
      }
    });
  });
  return extraAmenities;
};
