import { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { Button } from '@chakra-ui/button';
import { Box, Text, VStack } from '@chakra-ui/layout';
import { AnimatePresence } from 'framer-motion';
import SiteContext from '~/contexts/SiteContext';
import useInquiryFormSubmitted from '@hooks/use-inquiry-form-submitted';
import ProviderContext, { Provider } from '~/contexts/Provider';
import { ParseMagnoliaPage } from '@utils/parser/magnolia';
import ProviderCareTypeService from './ProviderCareTypeService';
import { HeadingElements } from '~/@types/heading';
import Heading from '@components/Heading';
import { CallToActionProps } from '@components/NewCallToAction/NewCallToAction';
import { Metadata } from '~/types/Magnolia';
import { mockModMonProvider } from '@mocks/modmon.mock';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
const MotionBox = dynamic(() => import('@components/MotionBox'));

const ProviderCareTypesContainer = ({
  domain,
  children,
}: {
  domain: string;
  children: React.ReactNode;
}) => {
  switch (domain) {
    case 'caring.com':
      return (
        <Container
          bg="gray.50"
          ignoreMaxWidth
          px={{ base: 0, '2xl': 16 }}
          py={{ base: 8, lg: 16 }}
        >
          <Container my={0}>{children}</Container>
        </Container>
      );
    case 'seniorhomes.com':
      return <Container p={8}>{children}</Container>;
    default:
      return <Container>{children}</Container>;
  }
};

interface ProviderCareTypesProps {
  templateId?: string;
  summary?: string;
  title?: string;
  withImages?: boolean;
  withDescription?: boolean;
  withPricing?: boolean;
  obfuscatePricing?: boolean;
  withButton?: boolean;
  headingElement?: HeadingElements;
  cta?: CallToActionProps;
  metadata: Metadata;
  deviceVisibility?: DeviceVisibility;
}

export const validateProviderCareTypes = (provider: Provider | null) => {
  if (!provider) {
    return false;
  }

  //TODO: name, description and image for care categories should be pulled from Magnolia's Content Catalog
  const services = provider?.services || [];
  if (services.length === 0) return false;

  const servicesWithCosts = services.filter(
    (service) =>
      service.costs !== null && service.costs?.startingPriceCents !== null
  );

  return servicesWithCosts.length > 0;
};

const ProviderCareTypesComponent: React.FC<ProviderCareTypesProps> = ({
  headingElement = 'h2',
  templateId,
  metadata,
  deviceVisibility,
  ...props
}) => {
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';
  const showPrice = useInquiryFormSubmitted();
  const inPlasmic = templateId != '' ? mockModMonProvider : null;
  const provider = useContext(ProviderContext)?.provider || inPlasmic;
  const shouldRenderContent = validateProviderCareTypes(provider);
  const isHidden = useResponsiveDisplay(deviceVisibility);

  useEffect(() => {
    const id = window.location.hash.substring(1);
    if (id.startsWith('provider-costs-')) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  if (isHidden) {
    return <></>;
  }
  if (!shouldRenderContent) {
    return null;
  }

  const services = provider?.services || [];

  const parsed = {
    title: props.title || 'Costs of {provider.name}',
    summary: props.summary || '',
  };

  ParseMagnoliaPage({
    source: parsed,
    values: { provider: provider || {} },
    strip: true,
  });

  return (
    <ProviderCareTypesContainer domain={domain}>
      {provider ? <Box id={`provider-costs-${provider.id}`} /> : null}
      <VStack alignItems="start" spacing="2" mb="8">
        <Heading
          headingElement={headingElement}
          headingSize="lg"
          title={parsed.title}
          withContainer={false}
        />
        {parsed.summary && <Text fontSize="lg">{parsed.summary}</Text>}
      </VStack>
      <Box
        display="grid"
        gridTemplateColumns={{
          base: 'repeat(1,1fr)',
          md: 'repeat(2,1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        overflow="scroll"
        style={{ scrollSnapType: 'x mandatory', scrollPadding: '16px' }}
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
        gap={{ base: 4, lg: 8 }}
        width="100%"
        pb="6"
        mb="2"
      >
        {services.map(
          (service, index) =>
            service.costs?.startingPriceCents && (
              <AnimatePresence key={index}>
                <MotionBox
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  display="flex"
                  style={{ scrollSnapAlign: 'start' }}
                >
                  <ProviderCareTypeService
                    domain={domain}
                    provider={provider as Provider}
                    service={service}
                    withImage={props.withImages}
                    withDescription={props.withDescription}
                    withPricing={props.withPricing}
                    withButton={props.withButton}
                    obfuscateCost={props.obfuscatePricing && !showPrice}
                    obfuscatedCostCta={props.cta}
                    metadata={metadata}
                  />
                </MotionBox>
              </AnimatePresence>
            )
        )}
      </Box>
      {props.withButton && (
        <Button
          variant="outline"
          colorScheme="primary"
          width={{ lg: 'auto', base: 'full' }}
        >
          What can I afford?
        </Button>
      )}
    </ProviderCareTypesContainer>
  );
};

const ProviderCareTypes: React.FC<ProviderCareTypesProps> =
  withHydrationOnDemand({
    on: ['idle', 'visible'],
    initialVisible: true,
  })(ProviderCareTypesComponent);

export default ProviderCareTypes;
