import { stripTags } from '@utils/strings';
import { DEFAULT_EMPTY_DESCRIPTION, DEFAULT_EMPTY_TEXT } from '~/constants';

export const isEmptyText = (text: string | undefined): boolean => {
  const emptySpacesRegex = / /gm;
  const lineBreaksRegex = /[\r\n]/gm;
  const parsedText = text
    ? stripTags(text).replace(emptySpacesRegex, '').replace(lineBreaksRegex, '')
    : undefined;
  return (
    text === undefined ||
    text === DEFAULT_EMPTY_TEXT ||
    text === DEFAULT_EMPTY_DESCRIPTION ||
    !parsedText
  );
};
