import { Box } from '@chakra-ui/layout';
import { theme } from '@chakra-ui/theme';
import { AspectRatio } from '@chakra-ui/react';
import Heading from '@components/Heading';
import { isEmptyText } from '@utils/isEmptyText';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import { IMAGE_DOMAINS } from '~/constants';
import { MagnoliaImage } from '~/types/Magnolia';
import Section from './Section';

const DAMImage = dynamic(() => import('@components/Image/DAMImage'));
const ColumnContainer = dynamic(
  () => import('@components/LayoutStructure/ColumnContainer')
);
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
interface SectionImageProps {
  title?: string;
  fullWidthTitle: boolean;
  headingElement?: HeadingElements;
  text?: string;
  buttonLabel?: string;
  buttonLink?: string;
  maxWidthContainer?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'none';
  horizontalPadding?: 0 | 2 | 4 | 8;
  imageAlignmentWithText?: 'start' | 'center' | 'end';
  imagePositionRow: 'left' | 'right';
  imagePositionColumn: 'default' | 'above' | 'below';
  textAlignment?: 'left' | 'center' | 'right';
  titleAlignment?: 'left' | 'center' | 'right';
  fullWidth?: boolean;
  switchable: {
    field: 'damChooser' | 'externalSource';
    image?: MagnoliaImage;
    imageAlt?: string;
    imageUrl?: string;
  };
  alternateDesktopImage: boolean;
  switchableDesktop?: {
    field?: 'damChooser' | 'externalSource';
    image?: MagnoliaImage;
    imageAlt?: string;
    imageUrl?: string;
  };
  openInNewTab?: boolean;
}

const SectionImage = ({
  title,
  fullWidthTitle = false,
  headingElement,
  text,
  buttonLabel,
  buttonLink,
  imagePositionRow = 'right',
  imagePositionColumn = 'default',
  switchable,
  alternateDesktopImage = false,
  switchableDesktop,
  textAlignment = 'left',
  titleAlignment = 'left',
  fullWidth = false,
  maxWidthContainer = 'none',
  horizontalPadding,
  imageAlignmentWithText = 'center',
  openInNewTab = false,
}: SectionImageProps): React.ReactElement => {
  const hasDesktopImage =
    (alternateDesktopImage && switchableDesktop?.image?.['@id']) ||
    (switchableDesktop?.imageAlt && switchableDesktop?.imageUrl);

  const hasMobileImage =
    switchable?.image?.['@id'] ||
    (switchable?.imageAlt && switchable?.imageUrl);

  if (!title && isEmptyText(text) && !hasMobileImage) return <></>;

  if (
    (switchable?.field === 'externalSource' &&
      !IMAGE_DOMAINS.some((url) => switchable?.imageUrl?.includes(url))) ||
    (alternateDesktopImage &&
      switchableDesktop?.field === 'externalSource' &&
      !IMAGE_DOMAINS.some((url) => switchableDesktop?.imageUrl?.includes(url)))
  ) {
    console.error('Invalid host in image url');
    return <></>;
  }

  const isHidden = (conditional: string, displayParams: string) => {
    return {
      css: {
        [`@media screen and (${conditional}-width: ${theme.breakpoints.md})`]: {
          display: displayParams,
        },
      },
    };
  };

  const SwitchableImage = () => {
    return (
      <>
        {hasMobileImage &&
        switchable?.image &&
        switchable?.field === 'damChooser' ? (
          <Box {...isHidden('min', alternateDesktopImage ? 'none' : 'initial')}>
            <DAMImage
              src={switchable?.image}
              sizes="(max-width: 48em) 100vw, 50vw"
            />
          </Box>
        ) : (
          hasMobileImage &&
          switchable?.field === 'externalSource' &&
          switchable?.imageUrl && (
            <Box {...isHidden('min', 'none')}>
              <AspectRatio maxW="100%" ratio={1}>
                <Image
                  src={switchable?.imageUrl}
                  alt={switchable?.imageAlt || ''}
                  width={100}
                  height={100}
                  style={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              </AspectRatio>
            </Box>
          )
        )}
        {hasDesktopImage &&
        switchableDesktop?.image &&
        switchableDesktop?.field === 'damChooser' ? (
          <Box {...isHidden('max', alternateDesktopImage ? 'none' : 'initial')}>
            <DAMImage
              src={switchableDesktop?.image}
              sizes="(max-width: 48em) 100vw, 50vw"
            />
          </Box>
        ) : (
          hasDesktopImage &&
          switchableDesktop?.field === 'externalSource' &&
          switchableDesktop?.imageUrl && (
            <Box {...isHidden('max', 'none')}>
              <AspectRatio maxW="100%" ratio={1}>
                <Image
                  src={switchableDesktop?.imageUrl ?? ''}
                  alt={switchableDesktop?.imageAlt ?? ''}
                  width={100}
                  height={100}
                  style={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              </AspectRatio>
            </Box>
          )
        )}
      </>
    );
  };

  const ImageAboveText = () => {
    return hasDesktopImage || hasMobileImage ? (
      <Container paddingBottom={0}>
        {imagePositionColumn === 'above' && (
          <Box
            borderRadius="6"
            overflow="hidden"
            paddingBottom={{ base: 4, lg: 8 }}
            {...isHidden('min', 'none')}
          >
            <SwitchableImage />
          </Box>
        )}
        {fullWidthTitle && title && (
          <Heading
            headingElement={headingElement}
            headingSize="xl"
            title={title}
            withContainer={false}
            textAlign={titleAlignment}
          />
        )}
      </Container>
    ) : null;
  };

  const ImageRow = () => {
    return hasDesktopImage || hasMobileImage ? (
      <Box
        borderRadius="6"
        overflow="hidden"
        {...isHidden('max', imagePositionColumn === 'default' ? '' : 'none')}
      >
        <SwitchableImage />
      </Box>
    ) : null;
  };

  return (
    <>
      {(fullWidthTitle || imagePositionColumn === 'above') && (
        <ImageAboveText />
      )}
      <ColumnContainer
        px={horizontalPadding}
        maxW={fullWidth ? 'none' : 'container.xl'}
        alignItems={{ md: imageAlignmentWithText }}
      >
        {imagePositionRow === 'left' && <ImageRow />}

        <Section
          title={fullWidthTitle ? '' : title}
          headingElement={headingElement}
          richText={text}
          buttonLabel={buttonLabel}
          buttonLink={buttonLink}
          textAlignment={textAlignment}
          titleAlignment={titleAlignment}
          maxWidth={maxWidthContainer}
          openInNewTab={openInNewTab}
        />

        {imagePositionRow === 'right' && <ImageRow />}
        {imagePositionColumn === 'below' && (
          <Box borderRadius="6" overflow="hidden" {...isHidden('min', 'none')}>
            <SwitchableImage />
          </Box>
        )}
      </ColumnContainer>
    </>
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
})(SectionImage);
