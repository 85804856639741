import { Grid, GridItem, GridItemProps, GridProps } from '@chakra-ui/layout';
import LAYOUT from './layoutConstants';

interface CompoundComponents {
  Header: typeof Header;
  Content: typeof Content;
  Footer: typeof Footer;
}

const Header: React.FC<GridItemProps> = ({ children, ...rest }) => {
  return (
    <GridItem area="header" {...rest}>
      {children}
    </GridItem>
  );
};

const Content: React.FC<GridItemProps> = ({ children, ...rest }) => {
  return (
    <GridItem
      area="content"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        // This is a workaround for the EditablePage component not supporting custom CSS classes.
        '& > div': {
          w: '100%',
        },
      }}
      {...rest}
    >
      {children}
    </GridItem>
  );
};

const Footer: React.FC<GridItemProps> = ({ children, ...rest }) => {
  return (
    <GridItem area="footer" {...rest}>
      {children}
    </GridItem>
  );
};

const RootLayout: React.FC<GridProps> & CompoundComponents = ({
  children,
  ...rest
}) => {
  return (
    <Grid
      minH="100vh"
      templateAreas={`"header"
                      "content"
                      "footer"`}
      gridTemplateRows={{
        base: `${LAYOUT.HEADER_MOBILE_HEIGHT} 1fr min-content`,
        xl: `${LAYOUT.HEADER_DESKTOP_HEIGHT} 1fr min-content`,
      }}
      {...rest}
    >
      {children}
    </Grid>
  );
};

RootLayout.Header = Header;
RootLayout.Content = Content;
RootLayout.Footer = Footer;

export default RootLayout;
