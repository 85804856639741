import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { Parser } from './index';
import { ParsingProps } from './index.types';

export const ParseMagnoliaPage = (props: ParsingProps) => {
  const { source, values, strip = false, twofold = false } = props;

  if (!isObject(source)) {
    return;
  }

  if (twofold) {
    ParseMagnoliaPage({
      source: source,
      values: values,
      strip: false,
      twofold: false,
    });

    ParseMagnoliaPage({
      source: source,
      values: values,
      strip: false,
      twofold: false,
    });
  }

  Object.keys(source).forEach((key) => {
    if (
      !key.startsWith('@') &&
      !key.startsWith('mgnl:') &&
      !key.startsWith('jcr:')
    ) {
      if (isString(source[key])) {
        source[key] = Parser({
          source: source[key],
          values: values,
          strip: strip,
        });
      }

      if (isObject(source[key])) {
        ParseMagnoliaPage({
          source: source[key],
          values: values,
          strip: strip,
          twofold: false,
        });
      }
    }
  });
};
