import { Heading, Text, VStack } from '@chakra-ui/layout';
import { NewCallToAction } from '@components/NewCallToAction';
import { CallToActionProps } from '@components/NewCallToAction/NewCallToAction';
import { formatNumberLocale } from '~/utils/number';
import { Metadata } from '~/types/Magnolia';
import { ElementNames } from '@components/Analytics/events/ElementClicked';
interface Props {
  minimumCost: number | null;
  obfuscated?: boolean;
  obfuscatedCostCta?: CallToActionProps;
  metadata: Metadata;
}

const CostSection: React.FC<Props> = ({
  minimumCost,
  obfuscated = false,
  obfuscatedCostCta,
  metadata,
}) => {
  if (!minimumCost) return null;

  return (
    <VStack spacing="4" w="full" alignItems="start" data-testid="service-price">
      <Heading
        as="p"
        size={{ base: '2xl', md: 'xl' }}
        filter={obfuscated ? 'auto' : undefined}
        blur={obfuscated ? '10px' : undefined}
        data-testid="formatted-cost"
      >
        ${formatNumberLocale(minimumCost)}
      </Heading>
      <Text fontSize="lg" fontWeight="bold">
        Starting Price
      </Text>
      {obfuscated && obfuscatedCostCta?.text && (
        <NewCallToAction
          cta1={{
            ...obfuscatedCostCta,
            dataTestId: 'unlock-price-cta',
          }}
          onlyCTA={{
            enabled: true,
          }}
          metadata={metadata}
          trackingName={ElementNames.UNLOCK_PRICE}
        />
      )}
    </VStack>
  );
};

export default CostSection;
