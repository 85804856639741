import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const $padding = cssVar('card-padding');

const baseStyle = definePartsStyle({
  container: {
    borderRadius: 'xl',
  },
  header: {
    p: 0,
  },
  body: {
    p: 0,
  },
  footer: {
    p: 0,
  },
});

const sizes = {
  sm: definePartsStyle({
    container: {
      p: 5,
      gap: 4,
    },
  }),
  md: definePartsStyle({
    container: {
      p: 8,
      gap: 4,
    },
  }),
  lg: definePartsStyle({
    container: {
      px: { base: 8, md: '54px' },
      py: 8,
      gap: { base: '34px', md: 6 },
    },
  }),
};

const variants = {
  filled: definePartsStyle({
    container: {
      backgroundColor: 'gray.50',
      borderWidth: '0',
    },
  }),
  outline: definePartsStyle({
    container: {
      borderColor: 'gray.400',
      borderRadius: 'none',
      borderWidth: '1px',
    },
  }),
  elevated: definePartsStyle({
    container: {
      borderColor: 'gray.50',
      borderRadius: '12px',
      borderWidth: '1px',
      boxShadow: 'md',
    },
  }),
  unstyled: {
    container: {
      [$padding.variable]: 0,
      padding: $padding.reference,
    },
    body: {
      [$padding.variable]: 0,
    },
    header: {
      [$padding.variable]: 0,
    },
    footer: {
      [$padding.variable]: 0,
    },
  },
};

export const cardTheme = defineMultiStyleConfig({
  defaultProps: {
    size: 'md',
    variant: 'filled',
  },
  baseStyle,
  sizes,
  variants,
});
