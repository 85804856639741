export enum CaringDomains {
  LIVE = 'caring.com',
  DXP_LIVE = 'dxp.caring.com',
  LOCALHOST = 'caring.localhost',
  LOCALHOST_WITH_PORT = 'caring.localhost:3000',
  DEV_DXP = 'caring.dev.dxp.caring.com',
  STG_DXP = 'caring.stg.dxp.caring.com',
  PROD_DXP = 'caring.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.caring.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.caring.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.caring.prod.dxp.caring.com',
}

export enum SeniorHomesDomains {
  LIVE = 'seniorhomes.com',
  DXP_LIVE = 'dxp.seniorhomes.com',
  LOCALHOST = 'seniorhomes.localhost',
  LOCALHOST_WITH_PORT = 'seniorhomes.localhost:3000',
  DEV_DXP = 'seniorhomes.dev.dxp.caring.com',
  STG_DXP = 'seniorhomes.stg.dxp.caring.com',
  PROD_DXP = 'seniorhomes.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.seniorhomes.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.seniorhomes.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.seniorhomes.prod.dxp.caring.com',
}

export enum AssistedLivingDomains {
  LIVE = 'assistedliving.org',
  DXP_LIVE = 'dxp.assistedliving.org',
  LOCALHOST = 'assistedliving.localhost',
  LOCALHOST_WITH_PORT = 'assistedliving.localhost:3000',
  DEV_DXP = 'assistedliving.dev.dxp.caring.com',
  STG_DXP = 'assistedliving.stg.dxp.caring.com',
  PROD_DXP = 'assistedliving.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.assistedliving.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.assistedliving.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.assistedliving.prod.dxp.caring.com',
}

export enum MemoryCareDomains {
  LIVE = 'memorycare.com',
  DXP_LIVE = 'dxp.memorycare.com',
  LOCALHOST = 'memorycare.localhost',
  LOCALHOST_WITH_PORT = 'memorycare.localhost:3000',
  DEV_DXP = 'memorycare.dev.dxp.caring.com',
  STG_DXP = 'memorycare.stg.dxp.caring.com',
  PROD_DXP = 'memorycare.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.memorycare.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.memorycare.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.memorycare.prod.dxp.caring.com',
}

export enum MedicalAlertDomains {
  LIVE = 'medicalalert.org',
  DXP_LIVE = 'dxp.medicalalert.org',
  LOCALHOST = 'medicalalert.localhost',
  LOCALHOST_WITH_PORT = 'medicalalert.localhost:3000',
  DEV_DXP = 'medicalalert.dev.dxp.caring.com',
  STG_DXP = 'medicalalert.stg.dxp.caring.com',
  PROD_DXP = 'medicalalert.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.medicalalert.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.medicalalert.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.medicalalert.prod.dxp.caring.com',
}

export enum PayingForSeniorCareDomains {
  LIVE = 'payingforseniorcare.com',
  DXP_LIVE = 'dxp.payingforseniorcare.com',
  LOCALHOST = 'payingforseniorcare.localhost:3000',
  DEV_DXP = 'payingforseniorcare.dev.dxp.caring.com',
  STG_DXP = 'payingforseniorcare.stg.dxp.caring.com',
  PROD_DXP = 'payingforseniorcare.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.payingforseniorcare.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.payingforseniorcare.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.payingforseniorcare.prod.dxp.caring.com',
}

export enum HomeCareDomains {
  LIVE = 'homecare.org',
  DXP_LIVE = 'dxp.homecare.org',
  LOCALHOST = 'homecare.localhost:3000',
  DEV_DXP = 'homecare.dev.dxp.caring.com',
  STG_DXP = 'homecare.stg.dxp.caring.com',
  PROD_DXP = 'homecare.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.homecare.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.homecare.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.homecare.prod.dxp.caring.com',
}

export enum SeniorAdviceDomains {
  LIVE = 'senioradvice.com',
  DXP_LIVE = 'dxp.senioradvice.com',
  LOCALHOST = 'senioradvice.localhost:3000',
  DEV_DXP = 'senioradvice.dev.dxp.caring.com',
  STG_DXP = 'senioradvice.stg.dxp.caring.com',
  PROD_DXP = 'senioradvice.prod.dxp.caring.com',
  AUTHOR_DEV_DXP = 'author.senioradvice.dev.dxp.caring.com',
  AUTHOR_STG_DXP = 'author.senioradvice.stg.dxp.caring.com',
  AUTHOR_PROD_DXP = 'author.senioradvice.prod.dxp.caring.com',
}

export enum APIDOMAINS {
  'caring.com' = 'CARING',
  'seniorhomes.com' = 'SENIOR_HOMES',
}

export const Domains = {
  CaringDomains,
  SeniorHomesDomains,
  AssistedLivingDomains,
  MemoryCareDomains,
  MedicalAlertDomains,
  PayingForSeniorCareDomains,
  HomeCareDomains,
  SeniorAdviceDomains,
};
export type Domains = typeof Domains;

export type Domain = {
  [K in keyof Domains]: {
    [K2 in keyof Domains[K]]: Domains[K][K2];
  }[keyof Domains[K]];
}[keyof Domains];
