import { ParseMagnoliaPage } from '@utils/parser/magnolia';
import { gql } from 'graphql-request';
import { z } from 'zod';
import {
  CHAT_OVERLAY_TYPES,
  FormAction,
  MenuItemInquiryForm,
  NAVIGATION_ITEM_POSITION_TYPES,
  NAVIGATION_ITEM_TYPES,
  RollUpType,
  VISIBILITY_TYPES,
} from '~/components/Navigation';
import { showOnDesktop, showOnMobile } from '~/utils/visibility';
import cachedClient from './cached-client';
import { GlobalParser, globalsFragment } from './globals';

const navigationFragment = gql`
  fragment NavigationFragment on Query {
    navigations(path: $path) {
      name
      bgColor
      bgColorRange
      intro
      logo {
        link
        caption
      }
      logoMaxWidth
      mobileLogo {
        link
        caption
      }
      mobileLogoMaxWidth
      logoAlt
      logoUrl
      mainMenuPosition
      showShadow
      chatOverlay {
        field
        formAction
        rollUpType
        title
        thankYouMessage
        thankYouMessageColor
        legalDisclosure
        allowedPageTypes {
          dataName
        }
        popup {
          field
          title
          icon
          color
          size
          variant
          visibility
        }
      }
      menuItems {
        position
        type
        variant
        visibility
        text
        textColor
        textColorRange
        secondText
        secondTextColor
        secondTextColorRange
        icon
        url
        actionBehavior {
          field
          formAction
          ctaText
          rollUpType
          title
          thankYouMessage
          thankYouMessageColor
          legalDisclosure
          url
        }
        subMenuItems {
          text
          url
        }
        _metadata {
          id
        }
      }
      _metadata {
        id
      }
    }
  }
`;

const getNavigationDocument = gql`
  query GetNavigation($path: String!) {
    ...NavigationFragment
    ...GlobalsFragment
  }
  ${navigationFragment}
  ${globalsFragment}
`;

const NavigationParser = z
  .array(
    z
      .object({
        name: z
          .enum([
            'header',
            'footer',
            'header-provider-scrolled-page',
            'header-geo-scrolled-page',
            'header-sem',
            'footer-sem',
          ])
          .catch((ctx) => {
            if (ctx.input.includes('footer')) {
              return 'footer';
            }
            return 'header';
          }),
        bgColor: z.string().catch(''),
        bgColorRange: z.string().catch(''),
        intro: z.string().catch(''),
        logo: z
          .object({
            link: z.string(),
            caption: z.string(),
          })
          .nullable(),
        logoMaxWidth: z.string().catch('100'),
        mobileLogo: z
          .object({
            link: z.string(),
            caption: z.string(),
          })
          .nullable(),
        mobileLogoMaxWidth: z.string().catch('100'),
        logoAlt: z.string().catch(''),
        logoUrl: z.string().catch(''),
        mainMenuPosition: z.enum(['center', 'left', 'right']).catch('center'),
        showShadow: z.enum(['true', 'false']).catch('true'),
        chatOverlay: z
          .object({
            field: z.enum(['true', 'false']).catch('false'),
            formId: z.string().optional(),
            formAction: z.nativeEnum(FormAction).catch(FormAction.REQUEST_INFO),
            rollUpType: z.nativeEnum(RollUpType).nullable(),
            title: z.string().nullable(),
            thankYouMessage: z.string().nullable(),
            thankYouMessageColor: z.string().nullable(),
            legalDisclosure: z.string().nullable(),
            allowedPageTypes: z
              .array(
                z
                  .object({ dataName: z.string() })
                  .transform(({ dataName }) => dataName)
              )
              .nullable()
              .catch([]),
            popup: z
              .object({
                field: z.enum(CHAT_OVERLAY_TYPES).catch('bannerStickToBottom'),
                title: z.string().nullable(),
                icon: z.string().nullable(),
                color: z.string().nullable(),
                size: z.string().nullable(),
                variant: z.string().nullable(),
                visibility: z.enum(VISIBILITY_TYPES).catch('always'),
              })
              .nullable(),
          })
          .nullable(),
        menuItems: z
          .array(
            z
              .object({
                position: z.enum(NAVIGATION_ITEM_POSITION_TYPES).catch('main'),
                type: z.enum(NAVIGATION_ITEM_TYPES).nullable(),
                variant: z
                  .enum(['outline', 'solid', 'ghost'] as const)
                  .nullable()
                  .optional(),
                visibility: z.enum(VISIBILITY_TYPES),
                text: z.string().nullable(),
                textColor: z.string().nullable(),
                textColorRange: z.string().nullable(),
                secondText: z.string().nullable(),
                secondTextColor: z.string().nullable(),
                secondTextColorRange: z.string().nullable(),
                icon: z.string().nullable(),
                url: z.string().nullable(),
                actionBehavior: z
                  .object({
                    field: z.enum(['openInquiry', 'openLink']).nullable(),
                    formAction: z.nativeEnum(FormAction).nullable(),
                    ctaText: z.string().nullable(),
                    rollUpType: z.nativeEnum(RollUpType).nullable(),
                    title: z.string().nullable(),
                    thankYouMessage: z.string().nullable(),
                    thankYouMessageColor: z.string().nullable(),
                    legalDisclosure: z.string().nullable(),
                    url: z.string().nullable(),
                  })
                  .nullable(),
                subMenuItems: z
                  .array(
                    z.object({
                      text: z.string(),
                      url: z.string(),
                    })
                  )
                  .nullable(),
                _metadata: z.object({
                  id: z.string(),
                }),
              })
              .transform((menuItem) => {
                // TODO: The defaults values are for the current header/footer component APIs
                const children = (menuItem.subMenuItems || []).filter(
                  (subItem) => subItem.text && subItem.url
                );

                const hasLink =
                  menuItem.actionBehavior?.field === 'openLink' ||
                  // Compatibility with legacy menu links:
                  (!menuItem.actionBehavior?.field && !!menuItem.url);

                const menuItemInquiryForm: MenuItemInquiryForm =
                  menuItem.actionBehavior?.field !== 'openInquiry'
                    ? { enabled: false }
                    : {
                        enabled: true,
                        ctaAction:
                          menuItem.actionBehavior.formAction ||
                          FormAction.REQUEST_INFO,
                        ctaText: menuItem.actionBehavior.ctaText,
                        rollUpType: menuItem.actionBehavior.rollUpType,
                        title: menuItem.actionBehavior.title || '',
                        thankYouMessage:
                          menuItem.actionBehavior.thankYouMessage || '',
                        thankYouMessageColor:
                          menuItem.actionBehavior.thankYouMessageColor || '',
                        legalDisclosure:
                          menuItem.actionBehavior.legalDisclosure || '',
                      };

                return {
                  id: menuItem._metadata.id,
                  type: menuItem.type,
                  position: menuItem.position,
                  variant: menuItem.variant,
                  visibility: menuItem.visibility,
                  mobile: showOnMobile(menuItem.visibility),
                  desktop: showOnDesktop(menuItem.visibility),
                  text: menuItem.text || '',
                  textColor: menuItem.textColor || '',
                  textColorRange: menuItem.textColorRange || '',
                  secondText: menuItem.secondText || '',
                  secondTextColor: menuItem.secondTextColor || '',
                  secondTextColorRange: menuItem.secondTextColorRange || '',
                  icon: menuItem.icon || '',
                  link: {
                    enabled: hasLink,
                    url: hasLink
                      ? menuItem.actionBehavior?.url || menuItem.url || ''
                      : '',
                  },
                  inquiryForm: menuItemInquiryForm,
                  children,
                };
              })
          )
          .nullable(),
        _metadata: z.object({
          id: z.string(),
        }),
      })
      .transform((nav) => {
        const main = (nav.menuItems || []).filter(
          (item) => item.position === 'main'
        );
        const secondary = (nav.menuItems || []).filter(
          (item) => item.position === 'secondary'
        );

        const isFooter = nav.name === 'footer';
        const hasChatOverlay = nav.chatOverlay?.field === 'true';
        const chatOverlay = isFooter && hasChatOverlay ? nav.chatOverlay : null;

        return {
          name: nav.name,
          intro: nav.intro,
          bgColor: nav.bgColor || null,
          bgColorRange: nav.bgColorRange || null,
          logo: {
            logo: nav.logo,
            logoMaxWidth: nav.logoMaxWidth,
            mobileLogo: nav.mobileLogo,
            mobileLogoMaxWidth: nav.mobileLogoMaxWidth,
            logoAlt: nav.logoAlt,
            logoUrl: nav.logoUrl,
          },
          main,
          secondary,
          mainMenuPosition: nav.mainMenuPosition || 'center',
          showShadow: nav.showShadow !== 'false',
          chatOverlay,
          metadata: {
            ['@id']: nav._metadata.id,
          },
        };
      })
  )
  .min(2)
  .transform((val) => {
    const header = val.find((item) => item.name === 'header') || null;
    const footer = val.find((item) => item.name === 'footer') || null;
    const headerProviderScrolledPage =
      val.find((item) => item.name === 'header-provider-scrolled-page') || null;
    const headerGeoScrolledPage =
      val.find((item) => item.name === 'header-geo-scrolled-page') || null;
    const headerSem = val.find((item) => item.name === 'header-sem') || null;
    const footerSem = val.find((item) => item.name === 'footer-sem') || null;
    return {
      header,
      footer,
      headerProviderScrolledPage,
      headerGeoScrolledPage,
      headerSem,
      footerSem,
    };
  });

export type QueryResponse = {
  navigations: z.input<typeof NavigationParser>;
  globals: z.input<typeof GlobalParser>;
};

export const getNavigation = async (path: string) => {
  try {
    const { navigations, globals } = await cachedClient.request<QueryResponse>(
      getNavigationDocument,
      { path }
    );
    const parsedNavigation = NavigationParser.parse(navigations);
    const parsedGlobals = GlobalParser.parse(globals);

    ParseMagnoliaPage({
      source: parsedNavigation ?? {},
      values: {
        global: parsedGlobals ?? {},
      },
      twofold: false,
    });

    return {
      header: parsedNavigation.header,
      footer: parsedNavigation.footer,
      headerProviderScrolledPage:
        parsedNavigation.headerProviderScrolledPage ?? null,
      headerGeoScrolledPage: parsedNavigation.headerGeoScrolledPage ?? null,
      headerSem: parsedNavigation.headerSem ?? null,
      footerSem: parsedNavigation.footerSem ?? null,
    };
  } catch (error) {
    console.error('Error getting navigation: ', JSON.stringify(error));
    return {
      header: null,
      footer: null,
      headerProviderScrolledPage: null,
      headerGeoScrolledPage: null,
    };
  }
};

export type Navigation = z.infer<typeof NavigationParser>;
