import { ChakraProvider } from '@chakra-ui/react';
import { getNavigation } from '@services/graphql/navigation';
import Analytics from '@components/Analytics/Analytics';
import AnalyticsShim from '@components/Analytics/AnalyticsShim';
import Fonts from '@components/Fonts';
import RootLayout from '@components/Layouts/RootLayout';
import useInitializeAnalytics from '@hooks/InitializeAnalytics';
import { FooterNavigation, HeaderNavigation } from '@components/Navigation';
import { getSiteTheme, getThemeExtension } from '@services/magnolia/getTheme';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { setUTMParameters } from '@utils/utmParameters';
import App, { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useMemo, useState } from 'react';
import { ONE_DAY_IN_MILLISECONDS } from '~/constants';
import CatalogContext from '~/contexts/CatalogContext';
import { GuidedSearchProvider } from '~/contexts/GuidedSearchContext';
import { ModalProvider } from '~/contexts/ModalContext';
import { ProviderContextWrapper } from '~/contexts/Provider';
import { SessionContext } from '~/contexts/SessionContext';
import SiteContext, {
  findSiteForContext,
  SiteDefinition,
} from '~/contexts/SiteContext';
import { TenantFunctionsProvider } from '~/contexts/TenantFunctionsContext';
import '~/styles/globals.css';
import { PageProps } from '~/types/Magnolia';
import Toast from '@components/Toast/Toast';
import StoryContext from '~/contexts/StoryContext';
import { HeaderContainerProps } from '@components/Navigation/Header/HeaderContainer';
import { FooterContainerProps } from '@components/Navigation/Footer/FooterContainer';
import { RawPageContext } from '@components/LayoutStructure/EditablePage';
import { getColor } from '~/utils/getColor';
import Script from 'next/script';
import { DataDogSetup } from '@services/data-dog/data-dog-setup';

const TalkFurther = dynamic(
  () => import('@components/TalkFurther/TalkFurther')
);
const HeaderContainer = dynamic<HeaderContainerProps>(
  () => import('@components/Navigation/Header/HeaderContainer')
);
const FooterContainer = dynamic<FooterContainerProps>(
  () => import('@components/Navigation/Footer/FooterContainer')
);

interface CustomPageProps {
  header: HeaderNavigation | null;
  footer: FooterNavigation | null;
  isAccountEnabled: boolean;
  pageData: PageProps;
  site: SiteDefinition;
  ssrContext: {
    preview: boolean;
    previewData: unknown;
    req: {
      headers: {
        host: string;
      };
    };
    resolvedUrl: string;
    params: unknown;
    query: {
      utm_source?: string;
      utm_campaign?: string;
      utm_content?: string;
      utm_medium?: string;
    };
  };
  themeExtension: Record<string, any>;
}

interface CaringProps extends AppProps<CustomPageProps> {
  site: SiteDefinition;
  themeExtension: Record<string, any>;
  header: HeaderNavigation | null;
  footer: HeaderNavigation | null;
  headerProviderScrolledPage: HeaderNavigation | null;
  headerGeoScrolledPage: HeaderNavigation | null;
  headerSem: HeaderNavigation | null;
  footerSem: HeaderNavigation | null;
  isAccountEnabled: boolean;
}

// Temp fix for the green bar issue: MGNLFE-633 - https://jira.magnolia-cms.com/browse/MGNLFE-633
declare global {
  var mgnlFrameReady: () => void | undefined;
}

const CaringApp = ({
  Component,
  pageProps,
  site,
  isAccountEnabled,
  headerSem,
  headerProviderScrolledPage,
  headerGeoScrolledPage,
  header,
  footer,
  footerSem,
  themeExtension,
}: CaringProps) => {
  const baseTheme = useMemo(() => getSiteTheme(site.path), [site.path]);
  const siteTheme = useMemo(
    () => ({
      ...baseTheme,
      colors: { ...baseTheme.colors, ...themeExtension?.colors },
      fonts: { ...baseTheme.fonts, ...themeExtension?.fonts },
    }),
    [baseTheme, themeExtension]
  );
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: ONE_DAY_IN_MILLISECONDS,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    try {
      const localStoragePersister = createSyncStoragePersister({
        storage: window.localStorage,
      });

      persistQueryClient({
        queryClient,
        persister: localStoragePersister,
      });
    } catch (err) {
      console.error("Can't create session in localStorage");
    }
  }, [queryClient]);

  useEffect(() => {
    pageProps.ssrContext && setUTMParameters(pageProps.ssrContext.query);
  }, [pageProps.ssrContext]);

  if (Component.displayName === 'PlasmicHost') {
    return <Component theme={siteTheme} {...pageProps} />;
  }

  return (
    <ChakraProvider
      theme={siteTheme}
      toastOptions={{
        defaultOptions: { render: Toast },
      }}
    >
      <Head>
        <link rel="shortcut icon" href={`/${site.publicFolder}/favicon.ico`} />
      </Head>

      {site.path === 'caring.com' ? (
        <Head>
          <link
            rel="alternate"
            href={`/${site.publicFolder}/geo-listings.rss.xml`}
            type="application/rss+xml"
            title="City Listings RSS Feed"
          ></link>
        </Head>
      ) : (
        <></>
      )}
      <Fonts />
      {/* Datadog RUM https://docs.datadoghq.com/real_user_monitoring/browser/ */}
      <Script
        strategy="lazyOnload"
        src="https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js"
        onLoad={() => DataDogSetup()}
      />
      <TalkFurther
        domain={site.path}
        pageType={pageProps?.pageData?.page?.pageType}
      />
      <QueryClientProvider client={queryClient}>
        <SiteContext.Provider value={{ site }}>
          <TenantFunctionsProvider tenant={site.path}>
            <GuidedSearchProvider>
              <ModalProvider>
                <Analytics
                  segmentCdnURL={site.segmentCdnURL}
                  segmentWriteKey={site.segmentWriteKey}
                />
                <AnalyticsShim preview={pageProps.ssrContext?.preview} />
                <RawPageContext.Provider value={pageProps.pageData?.rawPage}>
                  <ProviderContextWrapper
                    provider={pageProps.pageData?.provider}
                  >
                    <CatalogContext.Provider
                      value={pageProps.pageData?.catalog}
                    >
                      <StoryContext.Provider value={pageProps.pageData?.story}>
                        <CaringAppBody
                          isAccountEnabled={isAccountEnabled}
                          Component={Component}
                          headerProviderScrolledPage={
                            headerProviderScrolledPage
                          }
                          headerGeoScrolledPage={headerGeoScrolledPage}
                          header={header}
                          headerSem={headerSem}
                          footerSem={footerSem}
                          footer={footer}
                          pageProps={pageProps}
                          site={site}
                        />
                      </StoryContext.Provider>
                    </CatalogContext.Provider>
                  </ProviderContextWrapper>
                </RawPageContext.Provider>
              </ModalProvider>
            </GuidedSearchProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </TenantFunctionsProvider>
        </SiteContext.Provider>
      </QueryClientProvider>
      {/* @TODO remove this when on the upcoming next upgrade when globlathis be supported for old browsers  */}
      {/* < chrome 71 and older browsers versions */}
      <script
        defer
        src="https://polyfill-fastly.io/v3/polyfill.min.js?features=globalThis"
      />
    </ChakraProvider>
  );
};

const CaringAppBody = ({
  Component,
  pageProps,
  isAccountEnabled,
  headerProviderScrolledPage,
  headerGeoScrolledPage,
  header,
  footer,
  site,
  headerSem,
  footerSem,
}) => {
  const { pageData = {} } = pageProps;
  const { page, provider, catalog } = pageData;
  const { externalIds } = pageProps?.plasmic || {};
  const { color, range } = page?.backgroundColor || {};
  const backgroundColor = getColor(color, range);

  const sessionData = useInitializeAnalytics({
    page,
    provider,
    catalog,
    site,
    externalIds,
  });

  return (
    <SessionContext.Provider value={sessionData}>
      <RootLayout backgroundColor={backgroundColor}>
        <RootLayout.Header>
          <HeaderContainer
            mainHeader={header}
            headerProviderScrolledPage={headerProviderScrolledPage}
            headerGeoScrolledPage={headerGeoScrolledPage}
            headerSem={headerSem}
            isAccountEnabled={isAccountEnabled}
            page={page}
          />
        </RootLayout.Header>

        <RootLayout.Content>
          <Component {...pageProps} />
        </RootLayout.Content>

        {footer && (
          <RootLayout.Footer>
            <FooterContainer
              mainFooter={footer}
              footerSem={footerSem}
              page={page}
              template={page?.['mgnl:template']}
            />
          </RootLayout.Footer>
        )}
      </RootLayout>
    </SessionContext.Provider>
  );
};

CaringApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);
  const site = findSiteForContext(context.ctx);

  const [navigation, themeExtension] = await Promise.all([
    getNavigation(`/${site.path}`),
    getThemeExtension(site),
  ]);

  const isAccountEnabled = false;

  return {
    ...ctx,
    site,
    header: navigation?.header,
    footer: navigation?.footer,
    headerProviderScrolledPage: navigation?.headerProviderScrolledPage,
    headerGeoScrolledPage: navigation?.headerGeoScrolledPage,
    headerSem: navigation?.headerSem,
    footerSem: navigation?.footerSem,
    isAccountEnabled,
    themeExtension,
  };
};

export default CaringApp;
