import { NextRouter } from 'next/router';
import { Provider } from '../Provider';
import { TenantFunctions } from '../TenantFunctionsContext';
import { buildProviderUrl } from './utils';

const tenantFunctions: TenantFunctions = {
  getProviderDetailsPath: () => '',
  getProviderDescription: () => '',
  getBreadcrumbLinks: () => [],
  getCareTypePath: () => '',
  generateContentForStateSitemap: ([]) => {},
  getTrailingSlashURL: () => '',
  getReviewPath: () => '',
  getCountyForSearch: () => '',
  getCareTypePathForSitemap: () => '',
  getCareTypeParamsForSitemap: () => ({
    state: '',
    cityOrCounty: '',
    careType: '',
  }),
};

export const buildDefaultFunction = (router?: NextRouter): TenantFunctions => {
  return {
    ...tenantFunctions,
    getProviderDetailsPath,
  };
};

const getProviderDetailsPath: TenantFunctions['getProviderDetailsPath'] = (
  provider: Provider
): string => {
  const {
    url,
    locationUrl,
    slug,
    address: { city, state } = { city: '', state: '' },
  } = provider;

  return (
    url ||
    locationUrl ||
    buildProviderUrl({ state, city, slug })
  ).toLowerCase();
};
