import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Box, Flex, Stack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import BackButton from '@components/Navigation/BackButton';
import ShareButton from '@components/ShareButton/ShareButton';
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicLayoutEffect';
import { BreadcrumbJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import CatalogContext from '~/contexts/CatalogContext';
import ProviderContext from '~/contexts/Provider';
import SiteContext from '~/contexts/SiteContext';
import StoryContext from '~/contexts/StoryContext';
import { useTenantFunctions } from '~/contexts/TenantFunctionsContext';
import { Domains } from '~/types/Domains';
import AdvertisingDisclosure from '@components/AdvertisingDisclosure';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

export type BreadcrumbItem = {
  label: string;
  url: string;
};

export const hasHistory = (): boolean => {
  const referrer = document.referrer;
  const sameHost = referrer
    ? new URL(referrer).hostname === window.location.hostname
    : false;

  const gotHistory = window.history.length > 1;

  return sameHost && gotHistory;
};

const Breadcrumbs = ({
  items,
  showBackButton = false,
}: {
  items: BreadcrumbItem[];
  showBackButton?: boolean;
}) => {
  return (
    <ChakraBreadcrumb
      fontWeight={400}
      sx={{ ol: { flexWrap: 'wrap' }, span: { color: 'gray.700' } }}
      fontSize="sm"
      spacing="0.25rem"
    >
      {showBackButton ? (
        <BreadcrumbItem>
          <BackButton showButton={true} />
        </BreadcrumbItem>
      ) : null}

      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        return (
          <BreadcrumbItem
            key={index}
            color="gray.700"
            isCurrentPage={isLastItem}
          >
            {isLastItem ? (
              <BreadcrumbLink
                as="span"
                cursor="initial"
                color="gray.700"
                _hover={{ textDecoration: 'none' }}
              >
                {item?.label}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbLink href={item.url}>{item.label}</BreadcrumbLink>
            )}
          </BreadcrumbItem>
        );
      })}
    </ChakraBreadcrumb>
  );
};

export type Props = {
  noHorizontalPadding?: boolean;
  advertisingDisclosure?: string;
};

const Breadcrumb = ({ noHorizontalPadding, advertisingDisclosure }: Props) => {
  const [showBackButton, setShowBackButton] = useState(false);
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const siteContext = useContext(SiteContext);
  const DOMAIN = `https://www.${siteContext.site?.path}`;
  const router = useRouter();
  const provider = useContext(ProviderContext)?.provider;
  const catalog = useContext(CatalogContext);
  const storyContext = useContext(StoryContext);

  const displayHasAdvertisingDisclosure =
    advertisingDisclosure && storyContext?.hasAdvertisingDisclosure;
  const { getBreadcrumbLinks } = useTenantFunctions();
  const items = getBreadcrumbLinks({ provider, router, catalog });
  const mobileItems =
    siteContext.site?.path === Domains.CaringDomains.LIVE
      ? items.filter((item) => item.label !== 'Home')
      : items;

  useIsomorphicLayoutEffect(() => {
    setShowBackButton(hasHistory());
  }, []);

  return (
    <Container
      noHorizontalPadding={noHorizontalPadding}
      pt={showBackButton && !isDesktop ? '2' : '8'}
      pb="6"
    >
      {isDesktop ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Breadcrumbs items={items} showBackButton={showBackButton} />
          {provider ? <ShareButton showText={showBackButton} /> : null}
          {displayHasAdvertisingDisclosure && (
            <AdvertisingDisclosure
              advertisingDisclosure={advertisingDisclosure}
              displayOnDesktop
            />
          )}
        </Stack>
      ) : (
        <Stack
          alignItems={showBackButton ? 'flex-start' : 'center'}
          direction={showBackButton ? 'column' : 'row'}
        >
          {showBackButton ? (
            <>
              {/* On provider pages, the back button needs to be displayed along with the share button. */}
              {provider ? (
                <>
                  <Flex alignSelf="stretch" alignItems="center" height="40px">
                    <Box flex="1">
                      <BackButton showButton={showBackButton} />
                    </Box>

                    <Box flex="1">
                      <ShareButton showText={showBackButton} />
                    </Box>
                  </Flex>

                  <Breadcrumbs items={mobileItems} />
                </>
              ) : (
                <Breadcrumbs
                  items={mobileItems}
                  showBackButton={showBackButton}
                />
              )}
            </>
          ) : (
            <>
              <Breadcrumbs items={mobileItems} />

              {provider ? <ShareButton showText={showBackButton} /> : null}
            </>
          )}
        </Stack>
      )}

      <BreadcrumbJsonLd
        itemListElements={items.map((item, index) => ({
          position: index + 1,
          name: item?.label,
          item: `${DOMAIN}${item?.url}`,
        }))}
      />
    </Container>
  );
};

export default Breadcrumb;
