import { useContext, createContext } from 'react';

export interface SessionContextType {
  pageSessionId: string;
  sessionId: string;
}

export const SessionContext = createContext<SessionContextType>({
  pageSessionId: '',
  sessionId: '',
});

export const useSessionContext = () => {
  const result = useContext(SessionContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
