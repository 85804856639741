export const truncateText = (
  text: string,
  maxLength: number
): { textStart: string; textEnd: string } => {
  const textSliceIndex = text.slice(0, maxLength).lastIndexOf(' ');
  const textSliceIndexOpenTag = text.slice(0, maxLength).lastIndexOf('<');
  const textSliceIndexCloseTag = text.indexOf('>', textSliceIndexOpenTag) + 1;
  const isWithinTag =
    textSliceIndex > textSliceIndexOpenTag &&
    textSliceIndex < textSliceIndexCloseTag &&
    textSliceIndexOpenTag !== -1;
  const sliceIndex = isWithinTag ? textSliceIndexOpenTag : textSliceIndex;

  const textStart = text.slice(0, sliceIndex);
  const textEnd = text.slice(sliceIndex, text.length);

  return { textStart, textEnd };
};
