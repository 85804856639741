import kebabCase from 'lodash/kebabCase';

export const states = [
  'alaska',
  'alabama',
  'arkansas',
  'arizona',
  'california',
  'colorado',
  'connecticut',
  'district-of-columbia',
  'delaware',
  'florida',
  'georgia',
  'guam',
  'hawaii',
  'iowa',
  'idaho',
  'illinois',
  'indiana',
  'kansas',
  'kentucky',
  'louisiana',
  'massachusetts',
  'maryland',
  'maine',
  'michigan',
  'minnesota',
  'missouri',
  'mississippi',
  'montana',
  'north-carolina',
  'north-dakota',
  'nebraska',
  'new-hampshire',
  'new-jersey',
  'new-mexico',
  'nevada',
  'new-york',
  'ohio',
  'oklahoma',
  'oregon',
  'pennsylvania',
  'puerto-rico',
  'rhode-island',
  'south-carolina',
  'south-dakota',
  'tennessee',
  'texas',
  'utah',
  'virginia',
  'vermont',
  'washington',
  'wisconsin',
  'west-virginia',
  'wyoming',
];

export const isAState = (text: string): boolean => {
  return states.includes(kebabCase(text));
};

export default isAState;
