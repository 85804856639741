import { Domains } from '~/types/Domains';

// Get all domains from Domains enum and flatten the result
export const ALLOWED_DOMAINS = Object.values(Domains).flatMap((domain) => {
  return Object.values(domain);
});

// Create a regex pattern to match all allowed domains
export const getAllowedTracingUrlsRegex = (domains: Array<string>) => {
  const escapedDomains = domains.map((domain) => domain.replace(/\./g, '\\.'));
  const regexPattern = `(${escapedDomains.join('|')})/api/(.*)`;
  return new RegExp(regexPattern);
};

const ALLOWED_TRACING_URLS_REGEX = getAllowedTracingUrlsRegex(ALLOWED_DOMAINS);

export const DataDogSetup = () => {
  const excludedUrls = ['www.caring.com/digital', 'www.seniorhomes.com/aff'];
  const currentUrl = window.location.href;

  if (excludedUrls.some((excludedUrl) => currentUrl.includes(excludedUrl))) {
    return;
  }

  // if (
  //   !process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID ||
  //   !process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN
  // ) {
  //   return;
  // }

  const DD_RUM = (window as any).DD_RUM;

  DD_RUM.onReady(function () {
    DD_RUM.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || '',
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || '',
      site: 'datadoghq.com',
      service: 'dxp-frontend-public',
      env: process.env.NEXT_PUBLIC_DATADOG_RUM_ENVIRONMENT || 'development',
      version: process.env.NEXT_PUBLIC_DXP_VERSION || '0.0.1',
      sessionSampleRate:
        process.env.NEXT_PUBLIC_DATADOG_RUM_ENVIRONMENT === 'production'
          ? 20
          : 0,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [
        (url: string) => ALLOWED_TRACING_URLS_REGEX.test(url),
      ],
    });
  });
};
