import dwellEngagement from '@components/Analytics/events/DwellEngagement';
import useReportExternalData from '@components/Analytics/events/ReportExternalData';
import scrollEngagement from '@components/Analytics/events/ScrollEngagement';
import { trackSessionStarted } from '@components/Analytics/events/SessionStarted';
import { trackCoreWebVitals } from '@utils/cwv';
import { createSession, getSession } from '@utils/session';
import { useEffect, useState } from 'react';
import storeJs from 'store';
import { v4 as uuidv4 } from 'uuid';
import { Provider } from '~/contexts/Provider';
import { SiteDefinition } from '~/contexts/SiteContext';
import { Catalog } from '~/types/LocaleCatalog';
import { Page } from '~/types/Magnolia';
import PageView from './PageView';

interface AnalyticsProps {
  page: Page;
  provider?: Provider;
  catalog?: Catalog;
  site?: SiteDefinition;
  externalIds?: Record<string, string>;
}

export interface SessionData {
  pageSessionId: string;
  sessionId: string;
  isNewSession: boolean;
}

export const useInitializeAnalytics = ({
  page,
  provider,
  catalog,
  site,
  externalIds,
}: AnalyticsProps): SessionData => {
  const sessionData = useSessionData();
  const reportExternalData = useReportExternalData();

  useEffect(() => {
    if (sessionData.pageSessionId !== '') {
      SetSessionTimeout();
      if (sessionData.isNewSession) {
        SetDwellEngagement(sessionData, 30000);
        SetScrollEngagement(sessionData, 50);
      }

      trackCoreWebVitals(sessionData);
      PageView({
        pageSessionId: sessionData.pageSessionId,
        sessionId: sessionData.sessionId,
        page,
        provider,
        catalog,
        site,
      });
      if (externalIds) {
        reportExternalData(
          sessionData.sessionId,
          sessionData.pageSessionId,
          externalIds
        );
      }
    }
  }, [
    sessionData,
    page,
    provider,
    catalog,
    site,
    externalIds,
    reportExternalData,
  ]);

  return sessionData;
};

const useSessionData = () => {
  const [state, setState] = useState({
    pageSessionId: '',
    sessionId: '',
    isNewSession: true,
  });

  // Initialize session data:
  // 1. useEffect makes sure the code runs on the client side only
  // 2. no dependencies here so we only run this once
  useEffect(() => {
    const session = getSession();
    const sessionId = session ? session.ID : createSession();
    const pageSessionId = uuidv4();
    if (!session) {
      trackSessionStarted(pageSessionId, sessionId);
    }

    setState({
      pageSessionId,
      sessionId,
      isNewSession: !session,
    });
  }, []);

  return state;
};

export const SetSessionTimeout = () => {
  const THIRTY_SECONDS_IN_MILLISECONDS = 30000;
  window.addEventListener('beforeunload', () => {
    const timeout = Date.now() + THIRTY_SECONDS_IN_MILLISECONDS;
    if (storeJs.get('session')) {
      const sessionCookie = storeJs.get('session');
      storeJs.set('session', {
        ID: sessionCookie.ID,
        Expiration: sessionCookie.Expiration,
        Timeout: timeout,
      });
    }
  });
};

const SetDwellEngagement = (sessionData: SessionData, dwellTime: number) => {
  const dwellTimeout = setTimeout(() => {
    dwellEngagement({ sessionData, dwellTime });
  }, dwellTime);

  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      clearTimeout(dwellTimeout);
    }
  });
  window.addEventListener('blur', () => {
    clearTimeout(dwellTimeout);
  });
};

const SetScrollEngagement = (sessionData: SessionData, scrollDepth: number) => {
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const halfwayPoint = documentHeight / 2;

    if (scrollPosition > halfwayPoint) {
      scrollEngagement({ sessionData, scrollDepth });
      window.removeEventListener('scroll', handleScroll);
    }
  };
  window.addEventListener('scroll', handleScroll);
};

export default useInitializeAnalytics;
