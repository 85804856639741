import { validateAndProcessFAQs } from '@components/FAQ/FAQ';
import { getRollupForCareType } from '@utils/careTypeRollups';
import isArray from 'lodash/isArray';
import {
  LocationServiceOption,
  Provider,
  ProviderService,
} from '~/contexts/Provider';
import { Page } from '~/types/Magnolia';
import { stripTags } from '~/utils/strings';
import { getApiCareType } from './careTypeToApiCareType';
import { findDataByTemplate } from './magnolia';
export const convertProviderServiceToLocationServiceOption = (
  providerServices: ProviderService[]
): LocationServiceOption[] => {
  const options = [] as LocationServiceOption[];

  if (!providerServices || providerServices.length < 1) {
    return options;
  }

  providerServices.forEach((serviceItem) => {
    if (serviceItem.id && serviceItem.category.name) {
      options.push({
        id: serviceItem.id,
        name: serviceItem.category.name,
      });
    }
  });
  return options;
};

export const calculateProviderMinPrice = ({
  result: { services },
  careType,
}: {
  result: Provider;
  careType?: string;
}) => {
  const servicesOfTargetCareType = careType
    ? services?.filter((o) => o.category.name === careType) || []
    : services ?? [];

  const minPrice = Math.min(
    ...servicesOfTargetCareType.map((o) =>
      isArray(o?.costs)
        ? Number.POSITIVE_INFINITY
        : o?.costs?.startingPriceCents || Number.POSITIVE_INFINITY
    )
  );

  return minPrice === Number.POSITIVE_INFINITY ? 0 : minPrice;
};

export const contractIsSubscription = (provider: Provider | null): boolean =>
  !!provider?.services?.find(
    (service) => service?.contract?.type?.toLowerCase() === 'subscription'
  );

export const providerIsEnhancedAndNotSuppressed = (
  provider: Provider | null | undefined
): boolean => {
  if (!provider) return false;
  return (
    !!provider.enhancedListing &&
    !!provider.services?.find(
      (service) => service?.contract?.isSuppressed === false
    )
  );
};

export const buildCareTypesDescription = (
  tags: Provider['tags'],
  city?: string,
  state?: string
): Provider['careTypesDescription'] => {
  switch (tags?.length) {
    case 0:
    case 1:
      return buildCareTypeTagLink(tags.toString(), city, state);
    case 2:
      return `${buildCareTypeTagLink(
        tags[0],
        city,
        state
      )} and ${buildCareTypeTagLink(tags[1], city, state)}`;
    default: {
      return tags !== undefined
        ? tags
            .map((tag, index) => {
              if (index === tags.length - 1) {
                return `and ${buildCareTypeTagLink(tag, city, state)}`;
              }
              return `${buildCareTypeTagLink(tag, city, state)}, `;
            })
            .join('')
        : '';
    }
  }
};

const buildCareTypeTagLink = (
  careType: string,
  city?: string,
  state?: string
) => {
  if (careType === '') return '';
  const careTypeSlug = getApiCareType(careType);
  const rollup = getRollupForCareType(careTypeSlug);
  if (
    careTypeSlug === '55-plus' ||
    careTypeSlug === 'low-income-affordable' ||
    careTypeSlug === undefined
  ) {
    return `<span>${careType}</span>`;
  }
  return `<a href='/${rollup}/${careTypeSlug}/${state}/${city}'>${careType}</a>`;
};

export const providerIsEnhanced = (provider: Provider | null) => {
  return provider?.enhancedListing === true;
};

export const providerHasReviews = (provider: Provider | null) => {
  return provider?.reviewCount && provider?.reviewCount > 0;
};

export const providerIsClaimed = (provider: Provider | null): boolean => {
  if (!provider) return false;
  return !!provider.services?.find((service) => service?.isClaimed === true);
};

export const providerHasFAQs = (data: Page, provider: Provider | null) => {
  const faqsTemplate = findDataByTemplate(data, 'spa-lm:components/sectionFAQ');
  const hasFaqs = validateAndProcessFAQs(faqsTemplate?.switchable, provider);
  return hasFaqs.length > 0;
};

export const providerHasDescriptionWithMoreThan200Words = (
  provider: Provider | null
) => {
  return (
    provider?.description &&
    stripTags(provider?.description.replace(/(\r\n|\r|\n)/g, ' '))
      .replace(/\s\s+/g, ' ')
      .split(' ').length > 200
  );
};

export const providerHasMoreThan5Amenities = (provider: Provider | null) => {
  return provider?.amenities && provider?.amenities?.length > 5;
};

interface ReplacementData {
  [key: string]: string;
}

export function replaceVariables<T extends ReplacementData>(
  template: string,
  data: Object
): string {
  return template?.replace(/\{(\w+(\.\w+)*)\}/g, (match, key) => {
    const keys = key.split('.');
    let value: any = data;

    for (const k of keys) {
      if (value && k in value) {
        value = value[k];
      } else {
        return match;
      }
    }

    return value !== undefined ? value : match;
  });
}
