import caringTheme from '@styles/themes/caring';
import seniorhomesTheme from '@styles/themes/seniorhomes';
import { SiteDefinition } from '~/contexts/SiteContext';

const defaultBaseUrl = process.env.NEXT_PUBLIC_MGNL_HOST;
const themeApi = defaultBaseUrl + '/.rest/delivery/theme';

export const getSiteTheme = (site) => {
  switch (site) {
    case 'assistedliving.org':
    case 'caring.com':
      return caringTheme;
    case 'homecare.org':
    case 'medicalalert.org':
    case 'memorycare.com':
    case 'payingforseniorcare.com':
    case 'senioradvice.com':
    case 'seniorhomes.com':
      return seniorhomesTheme;
    default:
      return caringTheme;
  }
};

const range = ['50', '100', '200', 300, 400, 500, 600, 700, 800, 900];
const colors = [
  'primary',
  'secondary',
  'tertiary',
  'error',
  'success',
  'link',
  'info',
  'accent',
  'graphic',
];

export interface Theme {
  domain: string;
  body?: string;
  heading?: string;
  'primary.50'?: string;
  'primary.100'?: string;
  'primary.200'?: string;
  'primary.300'?: string;
  'primary.400'?: string;
  'primary.500'?: string;
  'primary.600'?: string;
  'primary.700'?: string;
  'primary.800'?: string;
  'primary.900'?: string;
  'secondary.50'?: string;
  'secondary.100'?: string;
  'secondary.200'?: string;
  'secondary.300'?: string;
  'secondary.400'?: string;
  'secondary.500'?: string;
  'secondary.600'?: string;
  'secondary.700'?: string;
  'secondary.800'?: string;
  'secondary.900'?: string;
  'tertiary.50'?: string;
  'tertiary.100'?: string;
  'tertiary.200'?: string;
  'tertiary.300'?: string;
  'tertiary.400'?: string;
  'tertiary.500'?: string;
  'tertiary.600'?: string;
  'tertiary.700'?: string;
  'tertiary.800'?: string;
  'tertiary.900'?: string;
  'accent.50'?: string;
  'accent.100'?: string;
  'accent.200'?: string;
  'accent.300'?: string;
  'accent.400'?: string;
  'accent.500'?: string;
  'accent.600'?: string;
  'accent.700'?: string;
  'accent.800'?: string;
  'accent.900'?: string;
  'graphic.50'?: string;
  'graphic.100'?: string;
  'graphic.200'?: string;
  'graphic.300'?: string;
  'graphic.400'?: string;
  'graphic.500'?: string;
  'graphic.600'?: string;
  'graphic.700'?: string;
  'graphic.800'?: string;
  'graphic.900'?: string;
  'info.50'?: string;
  'info.100'?: string;
  'info.200'?: string;
  'info.300'?: string;
  'info.400'?: string;
  'info.500'?: string;
  'info.600'?: string;
  'info.700'?: string;
  'info.800'?: string;
  'info.900'?: string;
  'success.50'?: string;
  'success.100'?: string;
  'success.200'?: string;
  'success.300'?: string;
  'success.400'?: string;
  'success.500'?: string;
  'success.600'?: string;
  'success.700'?: string;
  'success.800'?: string;
  'success.900'?: string;
  'error.50'?: string;
  'error.100'?: string;
  'error.200'?: string;
  'error.300'?: string;
  'error.400'?: string;
  'error.500'?: string;
  'error.600'?: string;
  'error.700'?: string;
  'error.800'?: string;
  'error.900'?: string;
  'link.50'?: string;
  'link.100'?: string;
  'link.200'?: string;
  'link.300'?: string;
  'link.400'?: string;
  'link.500'?: string;
  'link.600'?: string;
  'link.700'?: string;
  'link.800'?: string;
  'link.900'?: string;
}

const getHexValue = (value: number): string => {
  return `#${(value >>> 0).toString(16).slice(2, 8)}`;
};

const getThemeValue = (theme) => {
  return colors.reduce((colorsArray, color) => {
    colorsArray[color] = range.reduce((rangesArray, range) => {
      if (theme[`${color}.${range}`]) {
        rangesArray[range] = getHexValue(theme[`${color}.${range}`]);
      }
      return rangesArray;
    }, {});
    return colorsArray;
  }, {});
};

const formatTheme = (theme: Theme) => {
  const { heading, body, ...colors } = theme;
  return {
    fonts: {
      heading,
      body,
    },
    colors: getThemeValue(colors),
  };
};

export const getThemeExtension = async (site: SiteDefinition) => {
  const url = `${themeApi}?domain=${site.path}`;
  const request = await fetch(url);
  const data = await request.json();
  return data?.results?.length > 0 ? formatTheme(data?.results[0]) : {};
};
