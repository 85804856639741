import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextPageContext,
} from 'next';
import { createContext } from 'react';
import {
  AssistedLivingDomains,
  CaringDomains,
  Domain,
  HomeCareDomains,
  MedicalAlertDomains,
  MemoryCareDomains,
  PayingForSeniorCareDomains,
  SeniorAdviceDomains,
  SeniorHomesDomains,
} from '~/types/Domains';

export interface SiteDefinition {
  name: string;
  domains: Array<String>;
  path: Domain;
  segmentWriteKey: string;
  segmentCdnURL: string;
  partnerToken: string;
  publicFolder: string;
  storyPaths: Array<string>;
}

export interface SiteContextType {
  site: SiteDefinition | null;
}

const SiteContext = createContext<SiteContextType>({
  site: null,
});

export const sitesConfig: Array<SiteDefinition> = [
  {
    name: 'Assisted Living',
    domains: [
      AssistedLivingDomains.LIVE,
      AssistedLivingDomains.DXP_LIVE,
      AssistedLivingDomains.LOCALHOST,
      AssistedLivingDomains.DEV_DXP,
      AssistedLivingDomains.STG_DXP,
      AssistedLivingDomains.PROD_DXP,
      AssistedLivingDomains.AUTHOR_DEV_DXP,
      AssistedLivingDomains.AUTHOR_STG_DXP,
      AssistedLivingDomains.AUTHOR_PROD_DXP,
    ],
    path: AssistedLivingDomains.LIVE,
    segmentWriteKey:
      process.env.NEXT_PUBLIC_SEGMENT_ASSISTEDLIVING_WRITE_KEY ?? '',
    segmentCdnURL: process.env.NEXT_PUBLIC_SEGMENT_ASSISTEDLIVING_CDN ?? '',
    partnerToken: process.env.NEXT_PUBLIC_ASSISTEDLIVING_PARTNER_TOKEN ?? '',
    publicFolder: 'assistedliving_public',
    storyPaths: [],
  },
  {
    name: 'Caring.com',
    domains: [
      CaringDomains.LIVE,
      CaringDomains.DXP_LIVE,
      CaringDomains.LOCALHOST,
      CaringDomains.DEV_DXP,
      CaringDomains.STG_DXP,
      CaringDomains.PROD_DXP,
      CaringDomains.AUTHOR_DEV_DXP,
      CaringDomains.AUTHOR_STG_DXP,
      CaringDomains.AUTHOR_PROD_DXP,
    ],
    path: CaringDomains.LIVE,
    segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_CARING_WRITE_KEY ?? '',
    segmentCdnURL: process.env.NEXT_PUBLIC_SEGMENT_CARING_CDN ?? '',
    partnerToken: process.env.NEXT_PUBLIC_CARING_PARTNER_TOKEN ?? '',
    publicFolder: 'caring_public',
    storyPaths: [
      'answers',
      'bestseniorliving',
      'caregivers',
      'medicare',
      'resources',
      'senior-products',
    ],
  },
  {
    name: 'Home Care',
    domains: [
      HomeCareDomains.LIVE,
      HomeCareDomains.DXP_LIVE,
      HomeCareDomains.LOCALHOST,
      HomeCareDomains.DEV_DXP,
      HomeCareDomains.STG_DXP,
      HomeCareDomains.PROD_DXP,
      HomeCareDomains.AUTHOR_DEV_DXP,
      HomeCareDomains.AUTHOR_STG_DXP,
      HomeCareDomains.AUTHOR_PROD_DXP,
    ],
    path: HomeCareDomains.LIVE,
    segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_HOMECARE_WRITE_KEY ?? '',
    segmentCdnURL: process.env.NEXT_PUBLIC_SEGMENT_HOMECARE_CDN ?? '',
    partnerToken: process.env.NEXT_PUBLIC_HOMECARE_PARTNER_TOKEN ?? '',
    publicFolder: 'homecare_public',
    storyPaths: [],
  },
  {
    name: 'Medical Alert',
    domains: [
      MedicalAlertDomains.LIVE,
      MedicalAlertDomains.DXP_LIVE,
      MedicalAlertDomains.LOCALHOST,
      MedicalAlertDomains.DEV_DXP,
      MedicalAlertDomains.STG_DXP,
      MedicalAlertDomains.PROD_DXP,
      MedicalAlertDomains.AUTHOR_DEV_DXP,
      MedicalAlertDomains.AUTHOR_STG_DXP,
      MedicalAlertDomains.AUTHOR_PROD_DXP,
    ],
    path: MedicalAlertDomains.LIVE,
    segmentWriteKey:
      process.env.NEXT_PUBLIC_SEGMENT_MEDICALALERT_WRITE_KEY ?? '',
    segmentCdnURL: process.env.NEXT_PUBLIC_SEGMENT_MEDICALALERT_CDN ?? '',
    partnerToken: process.env.NEXT_PUBLIC_MEDICALALERT_PARTNER_TOKEN ?? '',
    publicFolder: 'medicalalert_public',
    storyPaths: [],
  },
  {
    name: 'Memory Care',
    domains: [
      MemoryCareDomains.LIVE,
      MemoryCareDomains.DXP_LIVE,
      MemoryCareDomains.LOCALHOST,
      MemoryCareDomains.DEV_DXP,
      MemoryCareDomains.STG_DXP,
      MemoryCareDomains.PROD_DXP,
      MemoryCareDomains.AUTHOR_DEV_DXP,
      MemoryCareDomains.AUTHOR_STG_DXP,
      MemoryCareDomains.AUTHOR_PROD_DXP,
    ],
    path: MemoryCareDomains.LIVE,
    segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_MEMORYCARE_WRITE_KEY ?? '',
    segmentCdnURL: process.env.NEXT_PUBLIC_SEGMENT_MEMORYCARE_CDN ?? '',
    partnerToken: process.env.NEXT_PUBLIC_MEMORYCARE_PARTNER_TOKEN ?? '',
    publicFolder: 'memorycare_public',
    storyPaths: [],
  },
  {
    name: 'Paying For Senior Care',
    domains: [
      PayingForSeniorCareDomains.LIVE,
      PayingForSeniorCareDomains.DEV_DXP,
      PayingForSeniorCareDomains.LOCALHOST,
      PayingForSeniorCareDomains.DEV_DXP,
      PayingForSeniorCareDomains.STG_DXP,
      PayingForSeniorCareDomains.PROD_DXP,
      PayingForSeniorCareDomains.AUTHOR_DEV_DXP,
      PayingForSeniorCareDomains.AUTHOR_STG_DXP,
      PayingForSeniorCareDomains.AUTHOR_PROD_DXP,
    ],
    path: PayingForSeniorCareDomains.LIVE,
    segmentWriteKey:
      process.env.NEXT_PUBLIC_SEGMENT_PAYINGFORSENIORCARE_WRITE_KEY ?? '',
    segmentCdnURL:
      process.env.NEXT_PUBLIC_SEGMENT_PAYINGFORSENIORCARE_CDN ?? '',
    partnerToken:
      process.env.NEXT_PUBLIC_PAYINGFORSENIORCARE_PARTNER_TOKEN ?? '',
    publicFolder: 'payingforseniorcare_public',
    storyPaths: [],
  },
  {
    name: 'Senior Advice',
    domains: [
      SeniorAdviceDomains.LIVE,
      SeniorAdviceDomains.DXP_LIVE,
      SeniorAdviceDomains.LOCALHOST,
      SeniorAdviceDomains.DEV_DXP,
      SeniorAdviceDomains.STG_DXP,
      SeniorAdviceDomains.PROD_DXP,
      SeniorAdviceDomains.AUTHOR_DEV_DXP,
      SeniorAdviceDomains.AUTHOR_STG_DXP,
      SeniorAdviceDomains.AUTHOR_PROD_DXP,
    ],
    path: SeniorAdviceDomains.LIVE,
    segmentWriteKey:
      process.env.NEXT_PUBLIC_SEGMENT_SENIORADVICE_WRITE_KEY ?? '',
    segmentCdnURL: process.env.NEXT_PUBLIC_SEGMENT_SENIORADVICE_CDN ?? '',
    partnerToken: process.env.NEXT_PUBLIC_SENIORADVICE_PARTNER_TOKEN ?? '',
    publicFolder: 'senioradvice_public',
    storyPaths: [],
  },
  {
    name: 'Senior Homes',
    domains: [
      SeniorHomesDomains.LIVE,
      SeniorHomesDomains.DXP_LIVE,
      SeniorHomesDomains.LOCALHOST,
      SeniorHomesDomains.LOCALHOST_WITH_PORT,
      SeniorHomesDomains.DEV_DXP,
      SeniorHomesDomains.STG_DXP,
      SeniorHomesDomains.PROD_DXP,
      SeniorHomesDomains.AUTHOR_DEV_DXP,
      SeniorHomesDomains.AUTHOR_STG_DXP,
      SeniorHomesDomains.AUTHOR_PROD_DXP,
    ],
    path: SeniorHomesDomains.LIVE,
    segmentWriteKey:
      process.env.NEXT_PUBLIC_SEGMENT_SENIORHOMES_WRITE_KEY ?? '',
    segmentCdnURL: process.env.NEXT_PUBLIC_SEGMENT_SENIORHOMES_CDN ?? '',
    partnerToken: process.env.NEXT_PUBLIC_SENIORHOMES_PARTNER_TOKEN ?? '',
    publicFolder: 'seniorhomes_public',
    storyPaths: [],
  },
];

export const findSiteForDomain = (domain: string): SiteDefinition => {
  const [domainName, ...rest] = domain.split(':');
  const baseDomain = domainName.replace('www.', '');
  const site = sitesConfig.find((site) => site.domains.includes(baseDomain));
  if (site) {
    return site;
  } else {
    return sitesConfig[0];
  }
};

export const findSiteForContext = (
  context: NextPageContext | GetServerSidePropsContext
): SiteDefinition => {
  const domain = context.req?.headers.host || '';
  return findSiteForDomain(domain);
};

export const findSiteForRequest = (req: NextApiRequest): SiteDefinition => {
  const domain = req?.headers.host || '';
  return findSiteForDomain(domain);
};

export const findSiteForHost = (host: string): SiteDefinition => {
  const domain = host || '';
  return findSiteForDomain(domain);
};

export default SiteContext;
