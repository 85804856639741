import segmentEvents from '~/config/segment-events';
import { SessionData } from '@hooks/InitializeAnalytics';

import { dwellEngagement } from './eventContracts';

interface DwellEngagementType {
  sessionData: SessionData;
  dwellTime: number;
}

/**
 * @param sessionData - The session data.
 * @param dwellTime - The dwell time in milliseconds.
 */
const dwellEngagement = ({ sessionData, dwellTime }: DwellEngagementType) => {
  const dwellEngagementContract: dwellEngagement = {
    page_session_id: sessionData.pageSessionId ?? '',
    session_id: sessionData.sessionId ?? '',
    dwell: {
      time_in_seconds: dwellTime / 1000,
    },
  };
  window.tracking.track(
    segmentEvents.DWELL_ENGAGEMENT,
    dwellEngagementContract
  );
};

export default dwellEngagement;
