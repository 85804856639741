import Button, { ButtonProps } from '@components/Button/Button';

import dynamic from 'next/dynamic';
const ReviewSubmissionModal = dynamic(
  () => import('@components/Account/Reviews/ReviewSubmissionModal')
);
import { StringToIconKeys } from '@components/RenderIcon';
import { useNewReview } from '@hooks/use-new-review';
import { useContext } from 'react';
import { REVIEW_TYPES } from '~/constants';
import ProviderContext from '~/contexts/Provider';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { Metadata } from '~/types/Magnolia';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export interface ProviderDetailsWriteAReviewLinkProps extends ButtonProps {
  providerName: string;
  icon?: StringToIconKeys;
  text?: string;
  buttonVariant?: string;
  buttonSize?: string;
  reviewGuidelinesURL?: string;
  contactUsURL?: string;
  defaultOpenModal?: boolean;
  metadata: Pick<Metadata, '@id'>;
}

const ProviderDetailsWriteAReviewLink: React.FC<
  ProviderDetailsWriteAReviewLinkProps
> = ({
  providerName,
  icon,
  text,
  buttonVariant = 'solid',
  buttonSize,
  reviewGuidelinesURL,
  contactUsURL,
  defaultOpenModal = false,
  metadata,
  elementAction = ElementActions.OPEN_MODAL,
  ...rest
}) => {
  const queryClient = new QueryClient();
  const { onClick, isOpen, onClose } = useNewReview(
    providerName,
    defaultOpenModal
  );
  const { provider } = useContext(ProviderContext) || {};
  const linkText = text ?? 'Write a review';
  let reviewType = REVIEW_TYPES.GENERIC;
  if (provider?.rollup?.urlName === '' + REVIEW_TYPES.SENIOR_CARE) {
    reviewType = REVIEW_TYPES.SENIOR_CARE;
  } else if (provider?.rollup?.urlName === '' + REVIEW_TYPES.SENIOR_LIVING) {
    reviewType = REVIEW_TYPES.SENIOR_LIVING;
  }

  return (
    <>
      <Button
        title={linkText}
        onClick={onClick}
        style={{ textDecoration: 'none' }}
        colorScheme="primary"
        variant={buttonVariant}
        leftIcon={icon ? icon : 'MdEdit'}
        fontSize="md"
        size={buttonSize}
        elementType={ElementTypes.BUTTON}
        elementName={ElementNames.WRITE_A_REVIEW}
        elementAction={elementAction}
        {...rest}
      >
        {linkText}
      </Button>
      <QueryClientProvider client={queryClient}>
        <ReviewSubmissionModal
          formType={reviewType}
          isOpen={isOpen}
          onClose={onClose}
          reviewGuidelinesURL={reviewGuidelinesURL}
          contactUsURL={contactUsURL}
          metadata={metadata}
        />
      </QueryClientProvider>
    </>
  );
};

export default ProviderDetailsWriteAReviewLink;
