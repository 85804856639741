var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"VP8GVhsNgE1Wnzbu-p8Ka"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  BrowserClient,
  Breadcrumbs,
  defaultStackParser,
  getCurrentHub,
  GlobalHandlers,
  makeFetchTransport,
  LinkedErrors,
  HttpContext,
} from '@sentry/browser';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const client = new BrowserClient({
  dsn:
    SENTRY_DSN ||
    'https://027ee4912b0743a184600087a720e0e0@o38624.ingest.sentry.io/4504208965500928',
  tracesSampleRate: 1.0,
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  integrations: [
    new Breadcrumbs(),
    new GlobalHandlers(),
    new LinkedErrors(),
    new HttpContext(),
  ],
});

getCurrentHub().bindClient(client);
