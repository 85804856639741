import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  opacity: 1,
  borderColor: 'gray.300',
});

export const dividerTheme = defineStyleConfig({
  baseStyle,
});
