import segmentEvents from '~/config/segment-events';

interface Experiment {
  experiment_name: string;
  variation_name: string;
}

interface reportExternalData {
  page_session_id: string;
  session_id: string;
  externalIds: Array<Experiment> | [];
}

interface ExternalDataType {
  sessionId;
  pageSessionId;
  externalIds: Record<string, string>;
}

const experimentData = ({
  sessionId,
  pageSessionId,
  externalIds,
}: ExternalDataType) => {
  if (externalIds && Object.keys(externalIds).length !== 0) {
    const experiments: Experiment[] = [];

    Object.keys(externalIds).map((testname, index) => {
      experiments.push({
        experiment_name: testname,
        variation_name: externalIds[testname],
      });
    });

    const contract: reportExternalData = {
      page_session_id: pageSessionId ?? '',
      session_id: sessionId ?? '',
      externalIds: experiments,
    };
    window.tracking.track(segmentEvents.EXPERIMENT_EXTERNAL_IDS, contract);
  }
};

const useReportExternalData = () => {
  return (
    sessionId: string,
    pageSessionId: string,
    externalIds: Record<string, string>
  ) => {
    return experimentData({
      sessionId,
      pageSessionId,
      externalIds,
    });
  };
};

export default useReportExternalData;
