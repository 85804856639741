export const careTypeRollups = {
  'adult-day-care': 'senior-living',
  'assisted-living': 'senior-living',
  'care-homes': 'senior-living',
  'continuing-care': 'senior-living',
  'continuing-care-retirement-communities': 'senior-living',
  'geriatric-care-managers': 'senior-care',
  'home-care': 'senior-care',
  'home-health-agencies': 'senior-care',
  hospices: 'senior-care',
  hospice: 'senior-care',
  'in-home-care': 'senior-care',
  'independent-living': 'senior-living',
  'memory-care': 'senior-living',
  'memory-care-facilities': 'senior-living',
  'nursing-homes': 'senior-living',
  'retirement-communities': 'senior-living',
  'respite-care': 'senior-living',
  '55-communities': 'senior-living',
  '55-plus-communities': 'senior-living',
  'senior-apartments': 'senior-living',
  'senior-living': 'senior-living',
  'senior-care': 'senior-care',
  'senior-apartment': 'senior-living',
  'continuing-care-communities': 'senior-living',
};

export const getCareTypesByRollup = (rollup) => {
  return Object.keys(careTypeRollups).filter(
    (key) => careTypeRollups[key] === rollup
  );
};

export const getRollupForCareType = (careType) => {
  return careTypeRollups[careType] ?? null;
};
