import segmentEvents from '~/config/segment-events';
import { SessionData } from '@hooks/InitializeAnalytics';
import { scrollEngagement } from './eventContracts';

interface ScrollEngagementType {
  sessionData: SessionData;
  scrollDepth: number;
}

/**
 * @param sessionData - The session data.
 * @param scrollDepth - The scroll depth.
 */
const scrollEngagement = ({
  sessionData,
  scrollDepth,
}: ScrollEngagementType) => {
  const scrollEngagementContract: scrollEngagement = {
    page_session_id: sessionData.pageSessionId ?? '',
    session_id: sessionData.sessionId ?? '',
    scroll_depth: scrollDepth,
  };
  window.tracking.track(
    segmentEvents.SCROLL_ENGAGEMENT,
    scrollEngagementContract
  );
};

export default scrollEngagement;
