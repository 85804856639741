import segmentEvents, { getCookieNameFromEvent } from '~/config/segment-events';
import useCookieStorageValue from './use-cookie-storage-value';

const cookieName = getCookieNameFromEvent(segmentEvents.INQUIRY_SUBMITTED);

const useInquiryFormSubmitted = () => {
  return !!useCookieStorageValue(cookieName);
};

export default useInquiryFormSubmitted;
