import capitalize from 'lodash/capitalize';
import kebabCase from 'lodash/kebabCase';
import { z as zod } from 'zod';
import { customErrorMap } from '~/utils/zodCustomErrorMap';

zod.setErrorMap(customErrorMap);
const schema = zod.string();
export const pluralize = (
  count: number,
  word: string,
  suffix = 's'
): string => {
  if (typeof word !== 'string') return '';
  if (count === 1) return word;
  return `${word}${suffix}`;
};

export const labelToSlug = (label: string) => {
  return kebabCase(label ? label.toLowerCase() : '');
};

export const formatPhone = (str: string): string => {
  if (str.startsWith('+1')) {
    str = str.replace('+1', '');
  }

  //Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return '';
};

export const toCapitalizedWordsNew = (str: string) => {
  return str.split(' ').map(capitalize).join(' ');
};

export const toCapitalizedWords = (name: string) => {
  const words = name?.match(/[A-Za-z][a-z]*/g) || [];
  return words.map(capitalize).join(' ');
};

export const toCapitalizedSentence = (sentence: string) => {
  try {
    schema.parse(sentence);
    const words = sentence.split(' ');
    if (words[0].length === 0) {
      return sentence;
    }
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

    return words.join(' ');
  } catch (err) {
    throw new Error('toCapitalizedSentence: Invalid string');
  }
};

export const capitalizeFullSentence = (name: string) => {
  return name?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
};

export const removeTrailingNewline = (text: string) => {
  try {
    schema.parse(text);
    return text.replace(/\n$/, '');
  } catch (err) {
    throw new Error('removeTrailingNewline: Invalid string');
  }
};

export const createID = (text: string) => {
  // Temp fix for CME-1410, I will leave comments on the ticket for the team to discuss in order to agree on how
  // we should handle Exceptions.
  // See use in file /caring-website/components/RelatedArticle/RelatedArticle.tsx
  // function: extractPages on line 61
  // Please see https://caring.atlassian.net/browse/CME-1410 for additional discussions.

  if (!text) {
    return typeof text === 'string'
      ? ''
      : Math.random().toString(36).slice(2, 7);
  }

  try {
    schema.parse(text);
    return text
      ?.toLowerCase()
      .replace('-', ' ')
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '-');
  } catch (err) {
    throw new Error('createID: Invalid string');
  }
};

export const stringSanitizer = (str: string) => {
  try {
    schema.parse(str);
    const dataWithoutTags = str.replace(/(<([^>]+)>)/gi, '');
    const stringWithoutEmptyLines = dataWithoutTags.replace(
      /^\s*[\r\n]?/gm,
      ''
    );
    return stringWithoutEmptyLines.replace(/[\r\n]/gm, '');
  } catch (err) {
    throw new Error('stringSanitizer: Invalid string');
  }
};

export const toEllipsisText = (text: string, size = 20) => {
  try {
    schema.parse(text);
    return text.length > size ? `${text.substring(0, size)}...` : text;
  } catch (err) {
    throw new Error('toEllipsisText: Invalid string');
  }
};

export const getOnlyNumbers = (text: string) => {
  try {
    schema.parse(text);
    return text.replace(/\D/g, '');
  } catch (err) {
    throw new Error('getOnlyNumbers: Invalid string');
  }
};

export const stripTags = (text: string) => {
  try {
    schema.parse(text);
    return text.replace(/(<([^>]+)>)/gi, '');
  } catch (err) {
    throw new Error('stripTags: Invalid string');
  }
};

export const formatPhoneNumber = (
  number: string,
  withSpace: boolean = false
): string => {
  const stringSeparator = withSpace ? ' ' : '-';
  const formattedPhone = `${number.substring(
    0,
    1
  )}${stringSeparator}${number.substring(
    1,
    4
  )}${stringSeparator}${number.substring(
    4,
    7
  )}${stringSeparator}${number.substring(7, number.length)}`;

  if (formattedPhone.slice(-1) === '-')
    return formattedPhone.substring(0, formattedPhone.length - 1);
  return formattedPhone;
};

export const toNumber = (str: unknown) => {
  const parsed = schema.parse(str);
  return Number(parsed);
};
