import { Medicares } from '~/contexts/Provider';

export const mockMedicareReviews: Medicares = {
  id: '20009',
  federalProviderNumber: '347210',
  healthInspectionRating: '4.0',
  overallRating: '3.0',
  qmRating: '5.0',
  staffingRating: '2.0',
  ownershipType: 'Non - Profit Other',
  match: true,
  latitude: 34.9828,
  longitude: -80.5492,
  state: 'NC',
  localResourceType: 'home_healthcare_agencies',
  createdAt: '2023-10-26T15:30:10.952Z',
  updatedAt: '2023-10-26T15:30:10.952Z',
  offersNursingCareServices: 1,
  offersPhysicalTherapyServices: 1,
  offersOccupationalTherapyServices: 1,
  offersSpeechPathologyServices: 1,
  offersMedicalSocialServices: 1,
  offerHomeHealthAideServices: 1,
  dateCertified: '2020-10-01T07:00:00.000Z',
  ratingDate: '2023-10-01T07:00:00.000Z',
  frequencyBeginCare: '96.4',
  frequencyDrugTeaching: '99.5',
  frequencyFallCheck: '99.9',
  frequencyDepressionCheck: '99.4',
  frequencyFluShot: '77.7',
  frequencyPneumoniaShot: '87.1',
  frequencyDiabetesFootCare: '99.7',
  frequencyPainMoving: '86.0',
  improvementWalkingOrMovingAround: '78.2',
  improvementGettingInAndOutOfBed: '81.4',
  improvementBathing: '81.5',
  improvementBreathing: '85.6',
  improvementWounds: '90.1',
  improvementMedicationCompliance: '72.3',
  frequencyAdmittedToHospital: '15.1',
  frequencyUrgentCareNotReadmitted: '15.9',
  frequencyReadmitted: 'Same As Expected',
  frequencyHospitalRecentVisitCareNotReadmitted: 'Worse Than Expected',
  frequencyHospitalCareNotReadmitted: null,
  name: 'UNION REGIONAL HOME CARE',
  street: '101 EAST FRANKLIN STREET',
  city: 'MONROE',
  zipCode: '28112',
  phoneNumber: '9809937250',
  facilityTypeDescription: null,
  nameScore: 100,
  addressScore: 100,
  localResourceId: '1258077',
  confirmedMatch: true,
  federalIdMatch: true,
  legacyId: '123',
  locationServiceId: '456',
  deletedAt: null,
};
