import { Box, BoxProps } from '@chakra-ui/layout';
import { MagnoliaImage } from '~/types/Magnolia';
import useThemeToRGB from '@hooks/use-theme-to-rgb';
import { OverlayColors } from '~/types/OverlayColors';

type BackgroundType = 'bgColorForm' | 'bgImageForm' | 'bgExternalImageForm';

type BaseBackgroundProps = {
  children: React.ReactNode;
  type?: BackgroundType;
  bgColorRange?: string;
  bgColor?: string;
  bgImagePosition?: string;
  image?: MagnoliaImage;
  bgImageOverlay?: OverlayColors;
  bgOverlayOpacity?: string;
  bgBorderRadius?: string;
  bgOverlayGradient?: 'solid' | 'to left' | 'to right';
  imageUrl?: string;
} & BoxProps;

function getOverlayGradient(
  overlayColor: string,
  opacity: number,
  bgOverlayGradient: string
) {
  if (overlayColor) {
    const rgba = `rgba(${overlayColor},${opacity})`;
    switch (bgOverlayGradient) {
      case 'to left':
      case 'to right':
        return `linear-gradient(${bgOverlayGradient}, ${rgba}, transparent)`;
      default:
        return `linear-gradient(${rgba}, ${rgba})`;
    }
  }
}

function getOverlayColor(bgImageOverlay: string, themeToRGB) {
  switch (bgImageOverlay) {
    case 'default':
      return '';
    case 'light':
      return '255, 255, 255';
    case 'dark':
      return '0, 0, 0';
    default:
      return themeToRGB(`${bgImageOverlay}.900`) ?? '0, 0, 0';
  }
}

const BaseBackground = ({
  children,
  type,
  bgColorRange = '50',
  bgColor,
  bgImagePosition,
  image,
  bgImageOverlay = 'default',
  bgOverlayOpacity = '0',
  bgOverlayGradient = 'solid',
  bgBorderRadius,
  imageUrl,
  ...rest
}: BaseBackgroundProps) => {
  const themeToRGB = useThemeToRGB;
  let bgImage = {};
  let backgroundColor = '';
  const opacity = parseInt(bgOverlayOpacity) * 0.01;
  const overlayColor = getOverlayColor(bgImageOverlay, themeToRGB);
  const overlay = getOverlayGradient(overlayColor, opacity, bgOverlayGradient);
  switch (type) {
    case 'bgImageForm':
    case 'bgExternalImageForm': {
      const url =
        type === 'bgImageForm' && image
          ? `${process.env.NEXT_PUBLIC_MGNL_HOST}/dam/${image['@id']}${image['@path']}`
          : imageUrl;
      bgImage = {
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: bgImagePosition,
      };
      break;
    }
    case 'bgColorForm': {
      backgroundColor =
        bgColor === 'white' || bgColor === 'black'
          ? bgColor
          : `${bgColor}.${bgColorRange}`;
    }
  }

  return (
    <Box
      bg={type === 'bgColorForm' ? backgroundColor : ''}
      {...bgImage}
      borderRadius={bgBorderRadius}
      position="relative"
      data-testid="base-background"
    >
      <Box background={overlay} {...rest}>
        {children}
      </Box>
    </Box>
  );
};

export default BaseBackground;
