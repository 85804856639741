import { Button } from '@chakra-ui/button';
import { Box, Heading, Stack, VStack } from '@chakra-ui/layout';
import dynamic from 'next/dynamic';
const MotionBox = dynamic(() => import('@components/MotionBox'));
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { Accommodations } from '~/contexts/Provider';

import CareTypePricingItem from './CareTypePricingItem';

interface Props {
  accommodations: Array<Accommodations>;
  withButton?: boolean;
}

const CareTypePricing: React.FC<Props> = ({ accommodations, withButton }) => {
  const [viewAll, setViewAll] = useState(false);

  return (
    <Box maxWidth="container.xl" marginX="auto">
      <VStack alignItems="start" spacing="8">
        <Box paddingX="6">
          <Heading as="h5" size="lg">
            Pricing & floor plans
          </Heading>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{ lg: 'repeat(3, 1fr)', base: '1fr' }}
          paddingX="6"
          gap="8"
          width="full"
        >
          <AnimatePresence initial={false}>
            {accommodations.map(
              (accommodation, index) =>
                (index <= 2 || viewAll) && (
                  <MotionBox
                    key={index}
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    transition={{ duration: 0.4, ease: 'easeInOut' }}
                    width="full"
                  >
                    <CareTypePricingItem
                      title={accommodation.title}
                      image={accommodation.images[0].url || ''}
                      startingPriceCents={accommodation.startingPriceCents}
                      numberOfBaths={accommodation.bathroomCount}
                      numberOfBeds={accommodation.bedCount}
                      description={accommodation.description}
                      features={accommodation.features}
                      area={accommodation.squareFeetSize}
                    />
                  </MotionBox>
                )
            )}
          </AnimatePresence>
        </Box>
        <Stack
          direction="row"
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          paddingX="6"
          gap="4"
        >
          {accommodations.length > 3 && (
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={() => setViewAll((currentValue) => !currentValue)}
            >
              {viewAll
                ? 'View fewer accommodations'
                : 'View more accommodations'}
            </Button>
          )}
          {withButton && (
            <Button variant="outline" colorScheme="primary">
              What can I afford?
            </Button>
          )}
        </Stack>
      </VStack>
    </Box>
  );
};

export default CareTypePricing;
