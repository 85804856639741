import Image, { ImageProps } from 'next/image';

interface GrapQLImageProps extends Omit<ImageProps, 'src'> {
  src: {
    link: string;
    caption: string;
  };
}

const GraphQLImage: React.FC<GrapQLImageProps> = ({ src, alt, ...rest }) => {
  const baseUrl = process.env.NEXT_PUBLIC_MGNL_HOST || null;
  let imageURL = baseUrl + src.link;

  if (!src.link || !baseUrl) {
    return null;
  }

  if (baseUrl.endsWith('/author') && src.link.startsWith('/author/')) {
    imageURL = baseUrl + src.link.replace('/author', '');
  }

  if (
    baseUrl.endsWith('/caring-paas-webapp-1.3') &&
    src.link.startsWith('/caring-paas-webapp-1.3/')
  ) {
    imageURL = baseUrl + src.link.replace('/caring-paas-webapp-1.3', '');
  }

  return <Image src={imageURL} alt={alt || src.caption} {...rest} />;
};

export default GraphQLImage;
