import { OPEN_SANS, WORK_SANS, MONTSERRAT } from '@styles/fonts';

interface FontsProps {}

const Fonts: React.FC<FontsProps> = () => {
  return (
    <style jsx global>{`
      :root {
        --font-open-sans: ${OPEN_SANS.style.fontFamily};
        --font-work-sans: ${WORK_SANS.style.fontFamily};
        --font-montserrat: ${MONTSERRAT.style.fontFamily};
      }
    `}</style>
  );
};

export default Fonts;
