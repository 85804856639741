import {
  Box,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/layout';
import Image from 'next/image';
import { MdCheck } from 'react-icons/md';
import { formatNumberLocale } from '~/utils/number';

interface Props {
  title: string | null;
  area: string | null;
  description?: string | null;
  numberOfBeds: number;
  numberOfBaths: number;
  features: string[];
  image: string;
  startingPriceCents: number | null;
}

const CareTypePricingItem: React.FC<Props> = ({
  title,
  area,
  description = '',
  numberOfBeds,
  numberOfBaths,
  features,
  image,
  startingPriceCents,
}) => {
  return (
    <Box bgColor="white" borderRadius="md" shadow="sm" paddingX="4">
      <VStack p="4" spacing="4" alignItems={{ base: 'center', lg: 'start' }}>
        <Image src={image} alt={title || ''} width="360" height="290" />
        <VStack spacing="2" alignItems="start">
          <Heading as="h6" size="md">
            {title} | {area ? `${area}sqft` : ''}
          </Heading>
          <Text fontSize="sm">
            {numberOfBeds} Bed, {numberOfBaths} Bath
          </Text>
          <Text fontSize="sm">{description}</Text>
          {features && features.length && (
            <VStack alignItems="start">
              <Text fontSize="sm" mt={5} fontWeight="bold">
                Features
              </Text>
              <List>
                {features?.map((feature) => (
                  <ListItem key={feature} fontSize="sm">
                    <ListIcon as={MdCheck} color="primary.500" boxSize="6" />
                    {feature}
                  </ListItem>
                ))}
              </List>
            </VStack>
          )}
        </VStack>
        {startingPriceCents && (
          <Heading as="h4" size="lg">
            ${formatNumberLocale(Number(startingPriceCents) / 100)}
          </Heading>
        )}
      </VStack>
    </Box>
  );
};

export default CareTypePricingItem;
