import { Icon } from '@chakra-ui/icon';
import { Flex, GridItem, HStack, Stack, Text } from '@chakra-ui/layout';
import Heading from '@components/Heading';
import { PageContext } from '@components/LayoutStructure/EditablePage';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import { MdAttachMoney, MdLocationOn } from 'react-icons/md';
import { RiArrowRightLine } from 'react-icons/ri';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
import ProviderContext, {
  Provider,
  ProviderService,
} from '~/contexts/Provider';
import SiteContext from '~/contexts/SiteContext';
import { formatNumberLocale } from '~/utils/number';
import { MdVerified, MdOutlineHelpOutline } from 'react-icons/md';
import { Icon as ChakraIcon } from '@chakra-ui/icon';
import { Tooltip } from '@chakra-ui/tooltip';
import { useDisclosure } from '@chakra-ui/hooks';
import { getProviderWebsiteDetailsVariant } from '~/utils/getProviderWebsiteDetailsVariant';
import {
  contractIsSubscription,
  providerIsClaimed,
  providerIsEnhancedAndNotSuppressed,
} from '~/utils/providers';
import { Divider, Center, Grid } from '@chakra-ui/layout';
import { Show } from '@chakra-ui/media-query';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
const PageTitleBannerClaimListingButton = dynamic(
  () => import('./PageTitleBannerClaimListingButton')
);
const CaringStarsMultiYearBadge = dynamic(
  () => import('@components/CaringStars/CaringStarsMultiYearBadge')
);
const PageTitleBannerHeading = dynamic(
  () => import('./PageTitleBannerHeading')
);
const PageTitleBannerItem = dynamic(() => import('./PageTitleBannerItem'));
const PageTitleBannerMobileButton = dynamic(
  () => import('./PageTitleBannerMobileButton')
);
const PageTitleBannerReviews = dynamic(
  () => import('./PageTitleBannerReviews')
);
const PageTitleBannerTags = dynamic(() => import('./PageTitleBannerTags'));
const PageTitleBannerPhoneNumber = dynamic(
  () => import('./PageTitleBannerPhoneNumber')
);
const PageTitleBannerAmenities = dynamic(
  () => import('./PageTitleBannerAmenities')
);
interface PageTitleBannerProps {
  provider: Provider;
  service: ProviderService;
  headingElement: HeadingElements;
  headingSize: HeadingSizes;
  phoneNumberPreText?: string;
  phoneNumber?: string;
  phoneNumberPostText?: string;
  displayReviews?: boolean;
  displayBadges?: boolean;
  displayPricing?: boolean;
  verifiedText?: string;
  ratingColor?: string;
  ratingColorRange?: string;
  metadata?: object;
  noHorizontalPadding?: boolean;
  deviceVisibility?: DeviceVisibility;
}

const itemColumnGap = (domain) => {
  switch (domain) {
    case 'seniorhomes.com':
      return 8;
    case 'caring.com':
      return 0;
    default:
      return 2;
  }
};

const containerMarginBottom = (domain) => {
  switch (domain) {
    case 'seniorhomes.com':
      return 16;
    case 'caring.com':
      return 6;
    default:
      return 8;
  }
};

const Verified = ({
  isSubscription,
  isEnhanced,
  domain = '',
  verifiedText = 'Verified providers have been vetted for quality by the Caring.com team',
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if ((isSubscription || isEnhanced) && domain === 'caring.com') {
    return (
      <>
        <Show above="md">
          <Center height="36px" mx="22px">
            <Divider orientation="vertical" />
          </Center>
        </Show>
        <Tooltip
          hasArrow
          label={verifiedText}
          bg="primary.600"
          color="white"
          fontSize="sm"
          px={6}
          py={2}
          textAlign="center"
          borderRadius={4}
          isOpen={isOpen}
          display="inline-block"
        >
          <Flex
            display="inline-flex"
            color="primary.600"
            alignItems="center"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={onOpen}
          >
            <ChakraIcon
              as={MdVerified}
              w={{ base: '24px', lg: '26px' }}
              h={{ base: '24px', lg: '26px' }}
              role="presentation"
            />
            <Text
              className="verified-partner"
              lineHeight="base"
              fontSize={{ base: 'md', lg: 'xl' }}
              fontWeight="bold"
              mx={1}
            >
              Verified Partner
            </Text>
            <ChakraIcon
              as={MdOutlineHelpOutline}
              w="18px"
              h="18px"
              role="presentation"
            />
          </Flex>
        </Tooltip>
      </>
    );
  }
  return null;
};

const PageTitleBanner = ({
  headingElement = 'h1',
  headingSize = 'md',
  deviceVisibility,
  ...props
}: PageTitleBannerProps): React.ReactElement => {
  const pageProps = useContext(PageContext);
  const providerCtx = useContext(ProviderContext)?.provider;

  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  let provider = props.provider;
  if (!provider && providerCtx) {
    provider = providerCtx;
  }

  if (provider) {
    return (
      <ProviderPageTitleBanner
        {...props}
        headingElement={headingElement}
        headingSize={headingSize}
        provider={provider}
      />
    );
  }

  return (
    <Container py="10">
      <Heading
        headingElement={headingElement}
        headingSize={headingSize}
        title={pageProps?.page.title ?? ''}
        withContainer={false}
      />
    </Container>
  );
};

const ProviderDetailsContainer = ({ domain, children }) => {
  switch (domain) {
    case 'seniorhomes.com': {
      return (
        <Flex direction="column" gap={3}>
          {children}
        </Flex>
      );
    }
    default: {
      return <>{children}</>;
    }
  }
};

const ProviderPageTitleBanner = ({
  service,
  provider,
  headingElement,
  headingSize,
  phoneNumberPreText = '',
  phoneNumberPostText = '',
  phoneNumber = '',
  displayBadges = false,
  displayReviews = false,
  displayPricing = false,
  ratingColor,
  ratingColorRange,
  verifiedText,
  noHorizontalPadding = false,
}: PageTitleBannerProps) => {
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const isSubscription = contractIsSubscription(provider);
  const isEnhanced = providerIsEnhancedAndNotSuppressed(provider);
  const showMobileButton = isSubscription
    ? !isEnhanced || !provider.phoneNumber
    : true;

  const { city, state } = provider as Provider;
  // Only home care providers will have the city and state abbreviation in the title
  const homeCare: string[] = ['Home Care', 'Home Health Agencies'];
  const hasModifiedTitle =
    provider.tags?.some((tag) => homeCare.includes(tag)) &&
    !isEnhanced &&
    !providerIsClaimed(provider);

  let title =
    domain === 'caring.com' && hasModifiedTitle
      ? `${provider.name} ${city?.name || ''}${
          city?.name && state?.code ? ', ' + state?.code : ''
        }`
      : provider.name;

  if (service) {
    title = `${service.category.name} at ${provider.name}`;
  }

  let costRange: string | null = null;
  const minCost = provider.minimumCost?.costCents;
  const maxCost = provider.maximumCost?.costCents;
  if (minCost && maxCost) {
    costRange = `$${formatNumberLocale(minCost / 100)} - $${formatNumberLocale(
      maxCost / 100
    )}`;
  }

  const providerDetailsVariants = getProviderWebsiteDetailsVariant(
    provider,
    domain
  );
  return (
    <Container
      mb={containerMarginBottom(domain)}
      noHorizontalPadding={noHorizontalPadding}
    >
      <Grid gridTemplateColumns="repeat(3, 1fr)">
        <GridItem colSpan={{ base: 3, lg: 2 }}>
          <Stack spacing="10px" color="primary.900">
            <PageTitleBannerHeading
              title={title}
              headingElement={headingElement}
              headingSize={headingSize}
            />
            <PageTitleBannerItem
              text={provider.address?.formattedAddress}
              icon={MdLocationOn}
              fontSize={{ base: 'sm', lg: 'md' }}
              iconProps={{ width: '24px', height: '24px' }}
            />
          </Stack>
          <Stack my={{ base: '16px', lg: '18px' }}>
            <Flex
              direction={{ base: 'column', lg: 'row' }}
              flexWrap="wrap"
              rowGap={2}
              columnGap={itemColumnGap(domain)}
            >
              <ProviderDetailsContainer domain={domain}>
                <PageTitleBannerPhoneNumber
                  phoneNumber={phoneNumber}
                  providerPhoneNumber={provider.phoneNumber}
                  isSubscription={isSubscription}
                  isEnhanced={isEnhanced}
                  phoneNumberPreText={phoneNumberPreText}
                  phoneNumberPostText={phoneNumberPostText}
                />

                <Verified
                  isSubscription={isSubscription}
                  isEnhanced={isEnhanced}
                  verifiedText={verifiedText}
                  domain={domain}
                />
                <Show above="md">
                  <PageTitleBannerClaimListingButton
                    isEnhanced={isEnhanced}
                    isSubscription={isSubscription}
                    legacyId={provider.legacyId}
                  />
                </Show>
              </ProviderDetailsContainer>
            </Flex>
          </Stack>
          <Stack spacing={2}>
            {providerDetailsVariants &&
              displayReviews &&
              providerDetailsVariants.averageRating &&
              providerDetailsVariants.ratingCount && (
                <PageTitleBannerReviews
                  averageRating={providerDetailsVariants.averageRating || '1'}
                  reviewCount={providerDetailsVariants.ratingCount || 0}
                  ratingColor={ratingColor}
                  ratingColorRange={ratingColorRange}
                />
              )}
            {costRange && displayPricing && (
              <Stack direction="row" color="primary.900">
                <PageTitleBannerItem
                  text={costRange}
                  icon={MdAttachMoney}
                  iconProps={{ width: '24px', height: '24px' }}
                />
                <HStack fontWeight="bold">
                  <Text ml={2} fontSize="sm">
                    Cost Breakdown
                  </Text>
                  <Icon as={RiArrowRightLine} width={6} height={6} />
                </HStack>
              </Stack>
            )}
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              gap={{ lg: '6px' }}
            >
              <PageTitleBannerAmenities
                amenities={provider.amenities}
                legacyAmenities={provider.amenitiesLegacy}
              />
              {provider.tags && (
                <PageTitleBannerTags
                  tags={provider.tags}
                  tagsDescription={provider.careTypesDescription}
                />
              )}
            </Stack>
          </Stack>
          <Show below="md">
            <PageTitleBannerClaimListingButton
              isEnhanced={isEnhanced}
              isSubscription={isSubscription}
              legacyId={provider.legacyId}
              alignSelf="start"
            />
          </Show>
          {showMobileButton && (
            <PageTitleBannerMobileButton phoneNumber={phoneNumber} />
          )}
        </GridItem>
        <GridItem
          colSpan={{ base: 3, lg: 1 }}
          justifySelf={{ base: 'left', lg: 'center' }}
          height={{ base: 'auto', lg: 0 }}
          mt={{ base: 4, lg: 0 }}
        >
          {displayBadges &&
            provider.caringStars &&
            provider.caringStars.length > 0 && (
              <CaringStarsMultiYearBadge years={provider.caringStars} />
            )}
        </GridItem>
      </Grid>
    </Container>
  );
};

export default PageTitleBanner;
