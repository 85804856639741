import { Box, Text } from '@chakra-ui/layout';
import { CloseButton } from '@chakra-ui/close-button';
import { RenderProps } from '@chakra-ui/toast/dist/toast.types';
import { AlertStatus } from '@chakra-ui/alert';

const Toast = ({
  onClose,
  id,
  title,
  description,
  status = 'success',
}: RenderProps) => {
  const backgroundMap: Record<AlertStatus, string> = {
    success: 'primary.600',
    error: 'red.600',
    warning: 'orange.500',
    info: 'blue.500',
    loading: 'blue.500',
  };

  return (
    <Box
      color="white"
      px={8}
      py={5}
      bg={backgroundMap[status]}
      borderRadius="md"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      as="section"
      aria-labelledby={`${id}-title`}
      position="relative"
    >
      <Box>
        <Text
          id={`${id}-title`}
          aria-describedby={`${id}-desc`}
          as="h1"
          fontSize="xl"
          fontWeight="bold"
          color="white"
        >
          {title}
        </Text>
        <Text id={`${id}-desc`} as="p" fontWeight={400} color="white">
          {description}
        </Text>
      </Box>
      <CloseButton position="absolute" top={4} right={4} onClick={onClose} />
    </Box>
  );
};

export default Toast;
