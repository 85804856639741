import snakeCase from 'lodash/snakeCase';

const COOKIE_PREFIX = 'c_ajs';

export const getCookieNameFromEvent = (event: string) => {
  const event_name = snakeCase(event);
  return `${COOKIE_PREFIX}_${event_name}`;
};

// Segment events
enum segmentEvents {
  EXPERIMENT_VIEWED = 'Experiment Viewed',
  EXPERIMENT_EXTERNAL_IDS = 'Experiment External IDS',
  // TODO: Change event name to follow naming convention (https://segment.com/docs/connections/spec/track/#event)
  INQUIRY_SUBMITTED = 'Inquiry Submission',
  SESSION_STARTED = 'Session Started',
  SEARCH_STEP_SUBMITTED = 'Search Step Submission',
  SEARCH_SUBMITTED = 'Search Submission',
  FORM_STEP_SUBMITTED = 'Form Step Submission',
  FORM_SUBMITTED = 'Form Submission',
  CORE_WEB_VITALS = 'Core Web Vitals',
  ELEMENT_CLICKED = 'Element Clicked',
  SCROLL_ENGAGEMENT = 'Scroll Engagement',
  DWELL_ENGAGEMENT = 'Dwell Engagement',
  PROVIDER_LIST_FILTERED = 'Provider List Filtered',
  PRODUCT_CLICKED = 'Product Clicked',
  FORM_FIELD_FOCUSED = 'Form Field Focused',
  LOCATION_COMPARISON_VIEWED = 'Location Comparison Viewed',
}

export default segmentEvents;
