import { useLocalStorage } from '@hooks/useLocalStorage';
import { createContext, ReactNode, useContext } from 'react';
import { GuidedSearchQuestion, GuidedSearchResult } from '~/types/GuidedSearch';

type guidedSearchContextType = {
  guidedSearchQuestions: GuidedSearchQuestion[] | null;
  guidedSearchValues: GuidedSearchResult | null;
  guidedSearchMatchingCareType: string;
  setGuidedSearchQuestions: (questions: GuidedSearchQuestion[]) => void;
  setGuidedSearchValues: (values: GuidedSearchResult) => void;
  setGuidedSearchMatchingCareType: (careType: string) => void;
};

type Props = {
  children: ReactNode;
};

export function useGuidedSearchByLocalStorage(key, data) {
  return useLocalStorage(key, data);
}

const GuidedSearchContextDefaultValues: guidedSearchContextType = {
  guidedSearchQuestions: null,
  guidedSearchValues: null,
  guidedSearchMatchingCareType: '',
  setGuidedSearchQuestions: () => {},
  setGuidedSearchValues: () => {},
  setGuidedSearchMatchingCareType: () => {},
};

const GuidedSearchContext = createContext<guidedSearchContextType>(
  GuidedSearchContextDefaultValues
);

export function useGuidedSearchData() {
  return useContext(GuidedSearchContext);
}

export function GuidedSearchProvider({ children }: Props) {
  const [guidedSearchQuestions, setGuidedSearchQuestionsData] =
    useGuidedSearchByLocalStorage('guidedSearchQuestions', '');

  const setGuidedSearchQuestions = (data) => {
    setGuidedSearchQuestionsData(data);
  };
  const [guidedSearchValues, setGuidedSearchValuesData] =
    useGuidedSearchByLocalStorage('guidedSearchValues', '');

  const setGuidedSearchValues = (data) => {
    setGuidedSearchValuesData(data);
  };
  const [guidedSearchMatchingCareType, setGuidedSearchMatchingCareTypeData] =
    useGuidedSearchByLocalStorage('guidedSearchMatchingCareType', '');

  const setGuidedSearchMatchingCareType = (data) => {
    setGuidedSearchMatchingCareTypeData(data);
  };

  const value = {
    guidedSearchQuestions,
    setGuidedSearchQuestions,
    guidedSearchValues,
    setGuidedSearchValues,
    guidedSearchMatchingCareType,
    setGuidedSearchMatchingCareType,
  };

  return (
    <GuidedSearchContext.Provider value={value}>
      {children}
    </GuidedSearchContext.Provider>
  );
}
