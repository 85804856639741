import { BreadcrumbItem } from '@components/Navigation/Breadcrumb';
import {
  FilteredEntries,
  StateDataResponse,
} from '@components/Sitemap/SitemapState/SitemapState.types';
import { getCareTypeFromURL } from '@utils/getCareTypeFromURL';
import isACareType from '@utils/isACareType';
import { isAState } from '@utils/isAState';
import { modifyTrailingSlash } from '@utils/modifyTrailingSlash';
import { labelToSlug } from '@utils/strings';
import isArray from 'lodash/isArray';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { NextRouter } from 'next/router';
import { DEFAULT_CARE_TYPE } from '~/config/senior-homes';
import { Link } from '~/types/LocaleCatalog';
import {
  TrailingSlashLogicType,
  TrailingSlashLogicTypesMap,
} from '~/types/TenantFunctions';
import { Provider } from '../Provider';
import { TenantFunctions } from '../TenantFunctionsContext';
import { buildProviderUrl } from './utils';

const homeLink = {
  label: 'Home',
  url: `/`,
};

export const buildSeniorHomesFunction = (): TenantFunctions => {
  return {
    getProviderDetailsPath,
    getProviderDescription,
    getBreadcrumbLinks,
    getCareTypePath,
    getCareTypePathForSitemap,
    getCareTypeParamsForSitemap,
    generateContentForStateSitemap,
    getTrailingSlashURL,
    getReviewPath,
    getCountyForSearch,
  };
};

const getProviderDetailsPath: TenantFunctions['getProviderDetailsPath'] = (
  provider: Provider
): string => {
  const {
    address: { city, state } = { city: '', state: '' },
    seniorHomesUrl,
    locationUrl,
    url,
  } = provider;

  return (
    seniorHomesUrl ||
    locationUrl ||
    // Usually the `url` on the root of the provider is the one defined for Caring, but
    // if that is not the case, it means it was overwritten to match the Senior Homes
    // domain when the request was made to ModMon
    (url && !isCaringProviderUrl(url)
      ? url
      : buildProviderUrl({
          state,
          city,
          slug: getSlug(provider),
        }))
  ).toLowerCase();
};

const isCaringProviderUrl = (url?: string): boolean => {
  if (!url) return false;
  return url.startsWith('/senior-living/') || url.startsWith('/senior-care/');
};

const getProviderDescription: TenantFunctions['getProviderDescription'] = (
  provider: Provider
): string => provider.seniorHomesDescription || provider.description || '';

const getBreadcrumbLinksFromRouter = (
  router: NextRouter,
  defaultCareType: string
): BreadcrumbItem[] => {
  // The order of the segments in the URLSegments array must match the order of the segments in the URL.
  const URLSegments = [
    'careTypeOrState',
    'careTypeOrCountyOrCity',
    'careTypeOrProvider',
  ];
  const careTypeFromRouter = Object.values(router.query).find((value) =>
    isACareType(value as string)
  );
  const stateFromRouter = Object.values(router.query).find((value) =>
    isAState(value as string)
  );
  const careTypeStartCase = startCase(
    (careTypeFromRouter as string) || defaultCareType
  );
  const careTypeKebab = kebabCase(careTypeStartCase);

  // Filter out undefined values from the URLSegments array.
  let breadcrumbSegments = URLSegments.map(
    (segment) => router.query[segment] as string
  ).filter(Boolean);

  // Remove the care type from the breadcrumb segments so we can add it at the beginning of the breadcrumb links array later.
  if (careTypeFromRouter) {
    breadcrumbSegments = breadcrumbSegments.filter(
      (segment) => segment !== careTypeFromRouter
    );
  }

  const breadcrumbItems = breadcrumbSegments.reduce((acc, segment, index) => {
    const lastSegment = acc[index - 1];
    const label = startCase(segment);
    const segments = lastSegment
      ? [...lastSegment.segments, segment]
      : [segment];
    acc.push({ label, segments });
    return acc;
  }, [] as { label: string; segments: string[] }[]);

  const breadcrumbLinks = breadcrumbItems.map(({ label, segments }) => {
    // If the care type is the default care type, do not add the care type to the URL.
    const hasDefaultCareType = careTypeKebab === DEFAULT_CARE_TYPE;
    // This prevents generating an invalid URL when the segments array contains all the segments in the URLSegments array.
    const hasAllURLSegments = segments.length === URLSegments.length;
    const AvailableURLSegments =
      hasDefaultCareType || hasAllURLSegments
        ? segments
        : [...segments, careTypeKebab];
    return {
      label,
      url: `/${AvailableURLSegments.join('/')}/`,
    };
  });

  // Add the care type at the beginning of the breadcrumb links array for pages that match the structure of the URLSegments array.
  const hasCareTypeOrState = careTypeFromRouter || stateFromRouter;

  if (hasCareTypeOrState) {
    const careTypeLink = {
      label: careTypeStartCase,
      url: `/${careTypeKebab}/`,
    };
    return [homeLink, careTypeLink, ...breadcrumbLinks];
  }

  return [homeLink, ...breadcrumbLinks];
};

const normalizeCareType = (careType: string): string => {
  const normalizedCareTypes = {
    'Continuing Care Communities': 'Continuing Care',
  };
  return normalizedCareTypes[careType] || careType;
};

const getBreadcrumbLinksFromProvider = (
  provider: Provider,
  defaultCareType: string
): BreadcrumbItem[] => {
  const careTypeFromProvider = provider.services?.some(
    (service) => kebabCase(service.category.name) === DEFAULT_CARE_TYPE
  )
    ? DEFAULT_CARE_TYPE
    : provider.services?.[0]?.category?.name ?? DEFAULT_CARE_TYPE;

  const careType = normalizeCareType(careTypeFromProvider || defaultCareType);
  const careTypeKebab = kebabCase(careType);
  const careTypeStartCase = startCase(careType);
  // If the care type is the default care type, do not add the care type to the URL.
  const hasDefaultCareType = careTypeKebab === DEFAULT_CARE_TYPE;

  return [
    homeLink,
    {
      label: careTypeStartCase,
      url: `/${careTypeKebab}/`,
    },
    {
      label: provider.state?.name ?? '',
      url: hasDefaultCareType
        ? `/${provider.state?.urlName}/`
        : `/${provider.state?.urlName}/${careTypeKebab}/`,
    },
    {
      label: provider.city?.name ?? '',
      url: hasDefaultCareType
        ? `/${provider.state?.urlName}/${provider.city?.urlName}/`
        : `/${provider.state?.urlName}/${provider.city?.urlName}/${careTypeKebab}/`,
    },
    {
      label: provider.name,
      // Provider pages do not have a trailing slash.
      url:
        provider.url ||
        `/${provider.state?.urlName}/${provider.city?.urlName}/${getSlug(
          provider
        )}`,
    },
  ];
};

const getBreadcrumbLinks: TenantFunctions['getBreadcrumbLinks'] = ({
  router,
  provider,
  catalog,
}): BreadcrumbItem[] => {
  // If careType is not provided, default to 'assisted-living'.
  const defaultCareType = catalog?.careType || DEFAULT_CARE_TYPE;

  // If the provider is available, use it to create the breadcrumb links.
  if (provider) {
    return getBreadcrumbLinksFromProvider(provider, defaultCareType);
  }

  // Otherwise, use the path to create the breadcrumb links.
  return getBreadcrumbLinksFromRouter(router, defaultCareType);
};

const getCareTypePath: TenantFunctions['getCareTypePath'] = (
  resource: Link,
  router: NextRouter,
  enableTrailingSlash: boolean
): string => {
  // TODO: remove the basePath variable, and replace it with an slash.
  const pagePath = router.asPath;
  const careTypeFromURL = getCareTypeFromURL(router.query) || '';
  const state = resource?.localResourceTypeRegion?.state?.urlName || '';
  const region = resource?.localResourceTypeRegion?.region?.urlName || '';
  const localResourceType =
    resource?.localResourceTypeRegion?.localResourceType?.urlName || '';

  const basePath = pagePath.split(careTypeFromURL)[0];
  const path = modifyTrailingSlash(
    enableTrailingSlash,
    `${basePath}${labelToSlug(state)}/${labelToSlug(region)}/${labelToSlug(
      localResourceType
    )}`
  );
  return path;
};

const getCareTypePathForSitemap: TenantFunctions['getCareTypePathForSitemap'] =
  (router: NextRouter) => {
    const params = getCareTypeParamsForSitemap(router);
    const careType = kebabCase(params.careType);
    const state = kebabCase(params.state);
    const cityOrCounty = kebabCase(params.cityOrCounty);

    return careType !== DEFAULT_CARE_TYPE
      ? `/${state}/${cityOrCounty}/${careType}/`
      : `/${state}/${cityOrCounty}/`;
  };

const getCareTypeParamsForSitemap: TenantFunctions['getCareTypeParamsForSitemap'] =
  (router: NextRouter) => {
    return {
      state: String(router?.query?.param1),
      cityOrCounty: String(router?.query?.param2),
      careType: String(router?.query?.param3),
    };
  };
const generateContentForStateSitemap: TenantFunctions['generateContentForStateSitemap'] =
  (filteredEntries: FilteredEntries[]): StateDataResponse => {
    const content: StateDataResponse = {
      cities: {},
      counties: {},
    };

    for (const element of filteredEntries) {
      const { urlPath, city, county } = element;

      const newElement = { ...element };

      let pathArray = urlPath.split('/');
      pathArray.shift(); /* remove the first empty string */

      if ([2, 3].includes(pathArray.length)) {
        /* It skips registers that are related only to care types  */
        if (!city && !county) continue;

        /* /state/cityOrCounty/careType OR /state/cityOrCounty */
        const [, cityOrCounty] = pathArray;

        const name: string = cityOrCounty ?? element.name;

        const countyKey = county.toLowerCase().replace(/ /g, '-');
        const cityKey = city.toLowerCase().replace(/ /g, '-');
        newElement.name = name;
        if (!isACareType(pathArray[pathArray.length - 1])) {
          newElement.urlPath =
            newElement.urlPath.toLowerCase().replace(/ /g, '-') +
            '/' +
            DEFAULT_CARE_TYPE;
        }
        newElement.urlPath =
          '/sitemap' +
          newElement.urlPath.toLowerCase().replace(/ /g, '-') +
          '/';

        if (cityOrCounty.includes('county')) {
          if (!isArray(content.counties[countyKey])) {
            content.counties[countyKey] = [];
          }
          content.counties[countyKey].push(newElement);
        } else {
          if (!isArray(content.cities[cityKey])) {
            content.cities[cityKey] = [];
          }
          content.cities[cityKey].push(newElement);
        }
      }
    }

    return content;
  };

const getTrailingSlashURL: TenantFunctions['getTrailingSlashURL'] = (
  url: string,
  type: TrailingSlashLogicType = 'default'
): string => {
  const logic: TrailingSlashLogicTypesMap = {
    state: true,
    city: true,
    provider: false,
    default: true,
  };

  return modifyTrailingSlash(logic[type], url);
};

const getReviewPath: TenantFunctions['getReviewPath'] = ({
  state,
  city,
  slug,
  url,
}) => {
  return (url || buildProviderUrl({ state, city, slug })).toLowerCase();
};

const getSlug = (provider: Provider): string => {
  return provider.seniorHomesSlug || provider.slug;
};

const getCountyForSearch: TenantFunctions['getCountyForSearch'] = (
  county: string
) => {
  return county.replace('County', '').trim();
};
