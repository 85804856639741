import { extendTheme } from '@chakra-ui/theme-utils';
import { tagTheme } from './components/tag';
import { dividerTheme } from './components/divider';

const base = extendTheme({
  breakpoints: {
    '3xl': '100em',
  },
  styles: {
    global: {
      html: {
        scrollPaddingTop: { base: 16, md: 14 },
        scrollBehavior: 'smooth',
      },
      // TODO: Remove this once we have a better solution for the global style injection with SSR. See more: https://github.com/chakra-ui/chakra-ui/issues/6727#issuecomment-1265508360
      '*, *::before, &::after': {
        borderColor: 'gray.200',
      },
      '*::placeholder': {
        color: 'gray.500',
      },
      // End of TODO
      '.magnolia-text ol, .magnolia-text ul': {
        paddingInlineStart: '1.5rem',
      },
      '.magnolia-text p + p, .magnolia-text p + ul, .magnolia-text ul + p, .magnolia-text .wp-block-caring-responsive-image-container':
        {
          marginTop: '1rem',
        },
      '.magnolia-text a': {
        textDecoration: 'underline',
        color: 'link.600',
        _hover: {
          color: 'link.700',
        },
      },
      '.flex-columns': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        columnGap: 8,
      },
      '.column-container .layout-container, .column-container .collapsible-container, .collapsible-container .layout-container':
        {
          padding: '0',
          marginBottom: '32px',
        },
      '.hidden': {
        display: 'none',
      },
      'table th': {
        padding: '32px 24px',
        textTransform: 'uppercase',
        lineHeight: '120%',
      },
      'table tbody tr:nth-of-type(odd)': {
        backgroundColor: 'gray.50',
      },
      'table td': {
        padding: '16px 24px',
        lineHeight: '150%',
      },
      '.magnolia-text table ol, .magnolia-text table ul': {
        paddingInlineStart: 6,
      },
      '.magnolia-text li': {
        fontSize: '1rem',
      },
      'table, .magnolia-text table li': {
        fontSize: '14px',
      },
      '.wp-block-columns': {
        paddingTop: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { base: 'column', md: 'row' },
      },
      '.wp-block-caring-container': {
        border: '1px solid',
        borderColor: ' gray.200',
        borderRadius: 'md',
        padding: '1rem',
        margin: '1rem 0',
      },
    },
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          bg: 'white',
        },
      },
    },
    Button: {
      variants: {
        outline: {
          borderWidth: '2px',
        },
      },
    },
    Divider: dividerTheme,
    Tag: tagTheme,
  },
  fonts: {
    heading: 'var(--font-open-sans)',
    body: 'var(--font-open-sans)',
  },
  colors: {
    white: {
      50: '#F0F0F055',
    },
    yellow: {
      '50': '#F5F3EF',
      '100': '#E4DDD3',
      '200': '#D3C6B6',
      '300': '#C1B09A',
      '400': '#B09A7D',
      '500': '#9E8461',
      '600': '#7F6A4D',
      '700': '#5F4F3A',
      '800': '#3F3527',
      '900': '#201A13',
    },
    teal: {
      '50': '#F0F3F4',
      '100': '#D5DCE2',
      '200': '#BAC6CF',
      '300': '#9FAFBC',
      '400': '#8499A9',
      '500': '#698296',
      '600': '#546878',
      '700': '#3F4E5A',
      '800': '#2A343C',
      '900': '#151A1E',
    },
    purple: {
      '50': '#EEF3F6',
      '100': '#D0DDE7',
      '200': '#B2C8D7',
      '300': '#94B2C7',
      '400': '#769DB7',
      '500': '#5887A8',
      '600': '#466C86',
      '700': '#355165',
      '800': '#233643',
      '900': '#121B22',
    },
    gray: {
      50: '#F5F5F5',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#D6D6D6',
      400: '#C2C2C2',
      500: '#A3A3A3',
      600: '#858585',
      700: '#5C5C5C',
      800: '#333333',
      900: '#141414',
    },
    red: {
      50: '#FFE8E0',
      100: '#FFBFB1',
      200: '#FF917F',
      300: '#FF604D',
      400: '#FE431B',
      500: '#E53A01',
      600: '#B33700',
      700: '#812F00',
      800: '#4F1F00',
      900: '#200D00',
    },
    orange: {
      50: '#FFFAF0',
      100: '#FEEBCB',
      200: '#FBD38D',
      300: '#F6AD55',
      400: '#ED8936',
      500: '#DD6B20',
      600: '#C05621',
      700: '#9C4221',
      800: '#7B341E',
      900: '#652B19',
    },
    green: {
      50: '#F0FFF4',
      100: '#C6F6D5',
      200: '#9AE6B4',
      300: '#68D391',
      400: '#48BB78',
      500: '#38A169',
      600: '#25855A',
      700: '#276749',
      800: '#22543D',
      900: '#1C4532',
    },
    blue: {
      50: '#EBF8FF',
      100: '#BEE3F8',
      200: '#90CDF4',
      300: '#63B3ED',
      400: '#4299E1',
      500: '#3182CE',
      600: '#2B6CB0',
      700: '#2C5282',
      800: '#2A4365',
      900: '#1A365D',
    },
    caringGray: {
      100: '#F0F0F0',
      200: '#EBEBEB',
    },
    black: {
      500: '#333333',
    },
  },
});

export default base;
