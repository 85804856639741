import { useEffect, useState } from 'react';
import SessionStorage from 'utils/sessionStorage';

export default function useSessionStorageValue(key) {
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    try {
      if (SessionStorage.enabled) {
        const subId = SessionStorage.observe(key, (val, oldVal) => {
          setValue(val);
        });

        return () => {
          SessionStorage.unobserve(subId);
        };
      }
    } catch (err) {
      console.error('useSessionStorageValue: sessionStorage is not available');
    }
  }, [key]);

  return value;
}

export enum SessionStorageKeys {
  HIDE_BOTTOM_POPUP = 'hideBottomPopup',
  UTM_SOURCE = 'utm_source',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_CONTENT = 'utm_content',
  UTM_MEDIUM = 'utm_medium',
  UTM_TERM = 'utm_term',
}
