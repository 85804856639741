import { isEmptyText } from '@utils/isEmptyText';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useContext } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import SiteContext from '~/contexts/SiteContext';
import Section from './Section';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

const MIN_TEXT_LENGTH = 250;

interface SectionTitleProps {
  headingElement: HeadingElements | 'title';
  title: string;
  titleColor: string;
  titleColorRange: string;
  alertStyle?: 'default' | 'info' | 'warning' | 'success' | 'error';
  alertText?: string;
  text: string;
  textColor: string;
  textColorRange: string;
  clientSideRendering?: boolean;
  textAlignment?: 'left' | 'center' | 'right';
  titleAlignment?: 'left' | 'center' | 'right';
  hideIfTextIsEmpty?: boolean;
  deviceVisibility?: DeviceVisibility;
  collapsible: {
    field: 'true' | 'false';
    minLength?: string;
    defaultState?: 'expanded' | 'collapsed';
  };
}

const SectionTitle = ({
  headingElement,
  title,
  titleColor,
  titleColorRange,
  alertStyle,
  alertText,
  text,
  textColor,
  textColorRange,
  clientSideRendering,
  textAlignment = 'left',
  titleAlignment = 'left',
  hideIfTextIsEmpty = false,
  deviceVisibility,
  collapsible = {
    field: 'false',
  },
}: SectionTitleProps): React.ReactElement => {
  const siteProps = useContext(SiteContext);
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  const domain = siteProps.site?.path ?? '';
  if (domain === 'caring.com') {
    titleColor = titleColor ? titleColor : 'gray';
    titleColorRange = titleColorRange ? titleColorRange : '900';
    textColor = textColor ? textColor : 'gray';
    textColorRange = textColorRange ? textColorRange : '700';
  }

  if (domain === 'seniorhomes.com') {
    titleColor = titleColor ? titleColor : 'primary';
    titleColorRange = titleColorRange ? titleColorRange : '700';
    textColor = textColor ? textColor : 'primary';
    textColorRange = textColorRange ? textColorRange : '400';
  }

  const headingTag = headingElement === 'title' ? 'h1' : headingElement;
  const textIsEmpty = isEmptyText(text);
  if (!title && textIsEmpty) return <></>;
  if (hideIfTextIsEmpty && textIsEmpty) return <></>;
  return (
    <Container>
      {headingElement === 'title' && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <Section
        title={title}
        titleColor={titleColor}
        titleColorRange={titleColorRange}
        alert={alertText}
        alertStatus={alertStyle}
        headingElement={headingTag}
        richText={text}
        richTextColor={textColor}
        richTextColorRange={textColorRange}
        clientSideRendering={clientSideRendering}
        textAlignment={textAlignment}
        titleAlignment={titleAlignment}
        collapsible={collapsible.field === 'true'}
        collapsibleLength={Number(collapsible.minLength) || MIN_TEXT_LENGTH}
        collapsibleDefaultState={collapsible.defaultState || 'expanded'}
      />
    </Container>
  );
};

export default withHydrationOnDemand({
  on: ['visible'],
})(SectionTitle);
