import get from 'lodash/get';

import en from '~/locales/en';

const useTranslation = () => {
  const t = (key: string, fallback = '') => {
    const translation = get(en, key) as string | undefined;
    return translation || fallback;
  };

  return { t };
};

export default useTranslation;
