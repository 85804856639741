import { Button } from '@chakra-ui/button';
import { Box, LinkBox, LinkOverlay } from '@chakra-ui/layout';
import Section from '@components/Sections/Section';
import useCareType from '@hooks/use-care-type';
import { createID } from '@utils/strings';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import { DEFAULT_EMPTY_TEXT } from '~/constants';
import { CareType } from '~/types/LocaleCatalog';
import { MagnoliaImage, Metadata } from '~/types/Magnolia';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

const DAMImage = dynamic(() => import('@components/Image/DAMImage'));

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

interface PageWithImage {
  type: 'internal' | 'external';
  careType?: CareType;
  title: string;
  url: string;
  page?: Metadata;
  image: MagnoliaImage | string;
}

export interface RelatedArticlesProps {
  headingElement: HeadingElements;
  title?: string;
  titleAlignment?: 'left' | 'center' | 'right';
  text?: string;
  textAlignment?: 'left' | 'center' | 'right';
  withImages: boolean;
  cta?: string;
  ctaColor?: string;
  ctaColorRange?: string;
  state?: 'solid' | 'outline' | 'ghost' | 'link';
  tilesPerRow?: number;
  pages?: Pick<Metadata, '@nodes'>;
  metadata: Pick<Metadata, '@path'>;
  deviceVisibility?: DeviceVisibility;
}

export const extractPages = (
  pages: Pick<Metadata, '@nodes'>,
  careTypeSlug?: string
): Array<PageWithImage> => {
  const extracted: Array<PageWithImage> = [];
  if (pages['@nodes']) {
    pages['@nodes'].forEach((key) => {
      if (pages[key]?.switchable?.title) {
        const subpage = {
          type: pages[key].switchable.field,
          page: pages[key].switchable.page,
          title: pages[key].switchable.title,
          url: pages[key].switchable.url,
          image: pages[key].image,
          careType: pages[key].careType,
        };
        extracted.push(subpage);
      }
    });
  }

  return extracted.filter(
    (node) => !node.careType || createID(node.careType.name) === careTypeSlug
  );
};

export const RelatedArticles: React.FC<RelatedArticlesProps> = ({
  headingElement = 'h2',
  title,
  titleAlignment = 'left',
  text,
  textAlignment = 'left',
  withImages,
  cta,
  ctaColor = 'primary',
  ctaColorRange = '400',
  state = 'solid',
  tilesPerRow = 1,
  pages,
  metadata,
  deviceVisibility,
}) => {
  const careType = useCareType();
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  const related = pages ? extractPages(pages, careType) : [];
  let inSidebar = false;
  if (metadata['@path']) {
    inSidebar = metadata['@path'].includes('/sidebar/');
  }

  if (!title || text === DEFAULT_EMPTY_TEXT || related.length === 0)
    return <></>;

  return (
    <Container>
      <Section
        headingElement={headingElement}
        headingSize={inSidebar ? 'sm' : 'lg'}
        title={title}
        richText={text}
        textAlignment={textAlignment}
        titleAlignment={titleAlignment}
      />
      <Box
        display="grid"
        gridTemplateColumns={{
          base: 'repeat(1, 1fr)',
          lg: `repeat(${tilesPerRow}, 1fr)`,
        }}
        gap={inSidebar ? '8px' : '32px'}
        mt="35px"
      >
        {related.map((page, i) => (
          <RelatedArticle
            key={i}
            page={page}
            withImages={withImages}
            cta={cta}
            ctaColor={ctaColor}
            buttonState={state}
            inSidebar={inSidebar}
          />
        ))}
      </Box>
    </Container>
  );
};

const RelatedArticle = ({
  page,
  withImages,
  cta,
  ctaColor = 'primary',
  inSidebar,
  buttonState = 'solid',
}: {
  page: PageWithImage;
  withImages: boolean;
  cta?: string;
  ctaColor?: string;
  inSidebar: boolean;
  buttonState?: 'solid' | 'outline' | 'ghost' | 'link';
}) => {
  const styles = {
    borderColor: withImages ? 'white' : 'primary.600',
    padding: withImages ? '32px' : inSidebar ? '8px 12px' : '16px',
    minHeight: withImages ? '272px' : 0,
    textColor: withImages ? 'white' : 'primary.600',
    textSize: inSidebar ? 'md' : 'lg',
    shadow: withImages ? 'base' : 'none',
  };
  let title, url;

  if (page.type === 'internal' && page.page) {
    title = page.page.title;
    try {
      const parse = new URL(page.page['@link']);
      url = parse.pathname?.replace('.html', '');
    } catch (err) {
      console.error(`Error parsing related article url: ${page.page['@link']}`);
      return null;
    }
  } else if (page.type === 'external') {
    title = page.title;
    url = page.url;
  } else {
    return null;
  }

  if (!url.endsWith('/')) {
    url = `${url}/`;
  }

  return (
    <LinkBox
      display="block"
      border="1px"
      borderColor={styles.borderColor}
      borderRadius="6px"
      p={styles.padding}
      minHeight={styles.minHeight}
      shadow={styles.shadow}
      position="relative"
      overflow="hidden"
      color={styles.textColor}
      textDecoration={'none'}
      data-testid="related-article"
    >
      <LinkOverlay fontSize={styles.textSize} fontWeight="bold" href={url}>
        {title}
        {cta && (
          <Button
            display="flex"
            width="fit-content"
            colorScheme={ctaColor}
            size="sm"
            mt="8px"
            textDecoration={'none'}
            variant={buttonState}
          >
            {cta}
          </Button>
        )}
      </LinkOverlay>

      {withImages && (
        <Box position="absolute" inset="0" zIndex="-1">
          {page.image && typeof page.image === 'object' && (
            <DAMImage
              src={page.image}
              fallbackAlt={title}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          )}
          {page.image && typeof page.image === 'string' && (
            <Image
              src={page.image}
              alt={title}
              width={500}
              height={500}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          )}
          <Box
            position="absolute"
            inset="0"
            bgGradient="linear(to-b, #00000099, #00000000)"
          />
        </Box>
      )}
    </LinkBox>
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(RelatedArticles);
