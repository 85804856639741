import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const $bg = cssVar('switch-bg');

const baseStyle = definePartsStyle({
  track: {
    [$bg.variable]: 'colors.gray.500',
  },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
