import { HeadingElements, HeadingSizes } from '~/@types/heading';

export const headingSizes: { [K in HeadingElements]: HeadingSizes } = {
  h1: '2xl',
  h2: 'xl',
  h3: 'lg',
  h4: 'md',
  h5: 'sm',
  h6: 'xs',
};
