import kebabCase from 'lodash/kebabCase';
import { NextRouter } from 'next/router';
import { z as zod } from 'zod';
import { isARollUpType } from '~/utils/isARollUpType';

export const getRollUpFromRouter = (
  router?: NextRouter
): string | undefined => {
  const segments =
    router?.asPath
      ?.split('?')[0] // remove query params
      .split('#')[0] // remove anchor
      .split('/')
      .filter((p) => p) ?? [];

  return Object.values(segments).find((value) =>
    isARollUpType(value as string)
  );
};

interface BuildProviderUrlParams {
  rollupType?: string;
  state: string;
  city: string;
  slug: string;
}

export const buildProviderUrlWithRollup = (params: BuildProviderUrlParams) => {
  return buildProviderUrl(params, { withRollUp: true });
};

export const buildProviderUrl = (
  { rollupType, state, city, slug }: BuildProviderUrlParams,
  options?: { withRollUp?: boolean }
): string => {
  try {
    const paramsParser = zod.object({
      rollupType: zod.string().min(1),
      state: zod.string().min(1),
      city: zod.string().min(1),
      slug: zod.string().min(1),
    });

    const parsedParams = paramsParser.parse({
      rollupType: options?.withRollUp ? rollupType : 'senior-living',
      state,
      city,
      slug,
    });

    const parsedRollupType = parsedParams.rollupType;
    const parsedState = kebabCase(parsedParams.state);
    const parsedCity = kebabCase(parsedParams.city);
    const parsedSlug = parsedParams.slug;

    return options?.withRollUp
      ? `/${parsedRollupType}/${parsedState}/${parsedCity}/${parsedSlug}`
      : `/${parsedState}/${parsedCity}/${parsedSlug}`;
  } catch (error) {
    if (error instanceof zod.ZodError) {
      console.error('buildProviderUrl: Invalid params', error.issues);
    }

    return '';
  }
};
