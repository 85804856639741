import { z as zod } from 'zod';

export const customErrorMap: zod.ZodErrorMap = (issue, ctx) => {
  if (issue.code === zod.ZodIssueCode.too_small) {
    // this is to remove () from 'character(s)', the default error message
    return {
      message: `String must contain at least ${issue.minimum} character${
        issue.minimum == 1 ? '' : 's'
      }`,
    };
  }

  if (
    issue.code === zod.ZodIssueCode.invalid_string &&
    issue.validation === 'email'
  ) {
    return {
      message: `The email you have entered does not match the required format.\nexample123@caring.com is the expected format`,
    };
  }

  if (
    issue.code === zod.ZodIssueCode.invalid_type &&
    issue.expected === 'string'
  ) {
    return { message: `This ain't a string!` };
  }

  return { message: ctx.defaultError };
};
