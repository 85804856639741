import segmentEvents from '~/config/segment-events';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import { elementClicked } from './eventContracts';

export enum ElementTypes {
  BUTTON = 'button',
  LINK = 'link',
  TELEPHONE = 'tel',
  SMS = 'sms',
  EMAIL = 'mailto',
  ICON = 'icon',
  CHECKBOX = 'checkbox',
  DROPDOWN = 'dropdown',
}

export enum ElementActions {
  OPEN_MODAL = 'open_modal',
  CLOSE_MODAL = 'close_modal',
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
  JUMP_LINK = 'jump_link',
  INTERNAL_LINK = 'internal_link',
  EXTERNAL_LINK = 'external_link',
  FILTERS = 'filters',
  VIEW_PROVIDER = 'view_provider',
  GO_BACK = 'go_back',
  PHONE_CALL = 'phone_call',
  SHARE = 'share',
  SORT = 'sort',
}

export enum ElementNames {
  INFO_REQUEST_SECTION = 'info_request_section',
  UNLOCK_PRICE = 'unlock_price',
  TABLE_OF_CONTENTS_BUTTON = 'table_of_contents_button',
  UNLOCK_AMENITIES = 'unlock_amenities',
  VIEW_ALL_SERVICES = 'view_all_services',
  VIEW_FEWER_SERVICES = 'view_fewer_services',
  READ_MORE_REVIEW = 'read_more_review',
  READ_LESS_REVIEW = 'read_less_review',
  WRITE_A_REVIEW = 'write_a_review',
  HEADER_BUTTON = 'header_button',
  GENERIC_BUTTON = 'generic_button',
  FILTER_CHIP = 'filter_chip',
  FLOATING_CTA = 'floating_cta',
  VIEW_MORE_DETAILS = 'view_more_details',
  VIEW_FEWER_DETAILS = 'view_fewer_details',
  PROVIDER_CARD = 'provider_card',
  CARING_STARS_BADGE = 'caring_stars_badge',
  WHY_CARING_BUTTON = 'eeat_button',
  BACK_BUTTON = 'back_button',
  PRODUCT_OVERVIEW_CARD = 'product_overview_card',
  PRODUCT_REVIEW_CARD = 'product_review_card',
  FEATURE_CARD = 'feature_card',
  MENU_TABLE_OF_CONTENTS_BUTTON = 'menu_table_of_contents_button',
  PHONE_CALL_BUTTON = 'phone_call_button',
  WHO_ARE_YOU_SEARCHING_FOR = 'who_are_you_searching_for',
  TOGGLE_EXPAND_SECTION = 'toggle_expand_section',
  SHARE_BUTTON = 'share_button',
  COMPARISON_PROVIDER_RATING = 'comparison_provider_rating',
  COMPARISON_PROVIDER_AMMENITIES = 'comparison_provider_ammenitites',
  COMPARISON_PROVIDER_IMAGE = 'comparison_provider_image',
  COMPARISON_PROVIDER_TITLE = 'comparison_provider_title',
  VIEW_GALLERY = 'view_gallery',
  PROVIDER_REVIEWS = 'provider_reviews',
  COMPARE_PROVIDERS = 'compare_providers',
  STREET_VIEW = 'street_view',
}

export const isTypeEvent = (type: unknown): type is ElementTypes => {
  return Object.values<unknown>(ElementTypes).includes(type);
};

interface ElementProperties {
  element: {
    id?: string;
    type: ElementTypes;
    action: ElementActions;
    name: ElementNames;
    text: string;
    color: string;
    textColor: string;
  };
  query?: {
    locationId: string;
    queryId: string;
    listId: string;
  };
  destinationUrl?: string;
}
interface ElementClickedType {
  sessionContext: SessionContextType;
  elementProperties: ElementProperties;
}

const elementClicked = ({
  sessionContext,
  elementProperties,
}: ElementClickedType) => {
  const element = elementProperties.element;
  const query = elementProperties.query;
  const elementClickedContract: elementClicked = {
    element: {
      id: element.id,
      type: element.type,
      action: element.action,
      name: element.name,
      text: element.text,
      color: element.color,
      text_color: element.textColor,
    },
    destination_url: elementProperties.destinationUrl || '',
    query: {
      location_id: query?.locationId || '',
      query_id: query?.queryId || '',
      list_id: query?.listId || '',
    },
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
  };
  window.tracking.track(segmentEvents.ELEMENT_CLICKED, elementClickedContract);
};

const useElementClicked = () => {
  const sessionContext = useSessionContext();
  return (elementProperties: ElementProperties) => {
    return elementClicked({ sessionContext, elementProperties });
  };
};

export default useElementClicked;
