export const modifyTrailingSlash = (
  enableTrailingSlash: boolean,
  url: string
): string => {
  return enableTrailingSlash && !url.endsWith('/')
    ? url.concat('/')
    : !enableTrailingSlash && url.endsWith('/')
    ? url.slice(0, -1)
    : url;
};
