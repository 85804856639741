import Button from '@components/Button';
import { ButtonGroup } from '@chakra-ui/button';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
} from '@chakra-ui/card';
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import BaseBackground from '@components/Background/BaseBackground';
import Container from '@components/LayoutStructure/Container';
import {
  StringToIconKeys,
  STRING_TO_ICON_COMPONENT,
} from '@components/RenderIcon';
import { getButtonColor, getColor } from '@utils/getColor';
import { Fragment } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
import { useModalDispatch } from '~/contexts/ModalContext';
import { MagnoliaImage, Metadata } from '~/types/Magnolia';
import NewCTAWriteAReviewLink from '@components/NewCallToAction/NewCTAWriteAReviewLink';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import SiteContext from '~/contexts/SiteContext';
import { useContext } from 'react';
import { OverlayColors } from '~/types/OverlayColors';

export interface CallToActionProps {
  text?: string;
  textColor?: string;
  bgColor?: string;
  isInquiry?: boolean;
  inquiryId?: string;
  actionBehavior?: {
    field: 'openInquiry' | 'openLink' | 'openReviewModal';
    inquiryId?: string;
    url?: string;
    type?: 'tel' | 'mailto' | 'sms';
    behavior?: '_blank' | '_self' | '_parent' | '_top';
    rel?: Array<'external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'>;
  };
  type?: 'tel' | 'mailto' | 'sms';
  state?: 'solid' | 'outline' | 'ghost';
  behavior?: '_blank' | '_self' | '_parent' | '_top';
  rel?: ['external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'];
  url?: string;
  icon?: StringToIconKeys;
  dataTestId?: string;
  desktopWidth?: 'fit-content' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '100%';
  mobileWidth?: 'fit-content' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '100%';
}

interface SwitchableProps {
  image?: MagnoliaImage;
  imageUrl?: string;
  bgImagePosition?: string;
  bgColor?: string;
  bgColorVariation?: string;
  field?: 'mini' | 'fullwidth';
  bgImageOverlay: OverlayColors;
  bgOverlayOpacity?: string;
  switchable?: SwitchableProps & {
    field?: 'bgImageColor' | 'bgColorForm' | 'bgExternalImageForm';
  };
}

interface NewCTAPropsFromAdmin {
  headingElement?: HeadingElements;
  headingSize?: HeadingSizes;
  heading?: string;
  summary?: string;
  size?: SwitchableProps;
  headingColor?: string;
  headingColorRange?: string;
  summaryColor?: string;
  summaryColorRange?: string;
  minHeightBase?: string;
  minHeightLG?: string;
  paddingBase?: string;
  paddingSM?: string;
  paddingMD?: string;
  paddingLG?: string;
  paddingXL?: string;
  padding2XL?: string;
  marginBottom?: string;
  cta1?: CallToActionProps;
  cta2?: CallToActionProps;
  cta3?: CallToActionProps;
  cta4?: CallToActionProps;
  card?: {
    enabled?: boolean;
    size?: CardProps['size'];
    variant?: CardProps['variant'];
  };
  onlyCTA?: {
    enabled?: boolean;
  };
  trackingName?: ElementNames;
  metadata: Metadata;
  className?: string;
}

const DEFAULT_HEADING_ELEMENT: HeadingElements = 'h2';
export const DEFAULT_CTA_BEHAVIOR = '_self';

export const getIcon = (iconName?: StringToIconKeys) => {
  if (!iconName) return '';
  return { leftIcon: STRING_TO_ICON_COMPONENT[iconName] };
};

const getActionBehavior = (
  cta: CallToActionProps
): NonNullable<CallToActionProps['actionBehavior']> => {
  if (cta.actionBehavior) {
    return cta.actionBehavior;
  } else if (cta.isInquiry) {
    return {
      field: 'openInquiry',
      inquiryId: cta.inquiryId,
    };
  } else if (cta.url) {
    return {
      field: 'openLink',
      url: cta.url,
      rel: cta.rel,
      behavior: cta.behavior,
      type: cta.type,
    };
  }
  return {} as NonNullable<CallToActionProps['actionBehavior']>;
};

const NewCTAComponent: React.FC<NewCTAPropsFromAdmin> = (props) => {
  const {
    heading,
    headingElement = DEFAULT_HEADING_ELEMENT,
    headingSize = '2xl',
    headingColor,
    headingColorRange,
    summaryColor,
    summaryColorRange,
    summary,
    size,
    minHeightBase = 'xs',
    minHeightLG = 'auto',
    marginBottom,
    paddingBase = '8',
    paddingSM,
    paddingMD,
    paddingLG = '14',
    paddingXL,
    padding2XL,
    cta1,
    cta2,
    cta3,
    cta4,
    card,
    onlyCTA,
    trackingName,
    metadata,
    className,
  } = props;
  const ctas = [cta1, cta2, cta3, cta4].filter(
    (cta) => cta?.text
  ) as CallToActionProps[];
  const { showModal } = useModalDispatch();
  const siteContext = useContext(SiteContext);

  const containerMiniProps = size?.field === 'mini' && {
    maxW: { base: 'full', md: 'container.xl' },
    mx: 'auto',
  };

  const boxMiniProps = size?.field === 'mini' && {
    borderRadius: { base: 0, md: 'xl' },
    alignItems: { base: 'flex-start', md: 'center' },
  };

  const getTextSize = (kind) => {
    if (kind === 'heading') return headingSize;
    return size?.field === 'mini' ? 'xl' : { base: 'xl', lg: '3xl' };
  };

  const ctaButtons = ctas.map((cta, index) => {
    const { field, inquiryId, url, rel, behavior, type } =
      getActionBehavior(cta);
    const isInquiry = field === 'openInquiry';
    const isReviewModal = field === 'openReviewModal';

    const handleInquiryClick = (id) => {
      showModal(id);
    };

    let renderComponent: JSX.Element | null = null;
    if (isInquiry) {
      renderComponent = (
        <Button
          width={{
            base: cta.mobileWidth ?? '100%',
            sm: cta.desktopWidth ?? 'auto',
          }}
          colorScheme={cta.bgColor}
          variant={cta.state}
          data-testid={cta.dataTestId}
          onClick={() => handleInquiryClick(inquiryId)}
          {...getButtonColor(cta)}
          leftIcon={cta.icon}
          elementAction={ElementActions.OPEN_MODAL}
          elementType={ElementTypes.BUTTON}
          elementName={trackingName}
        >
          {cta.text}
        </Button>
      );
    } else if (isReviewModal) {
      renderComponent = (
        <NewCTAWriteAReviewLink
          text={cta.text}
          width={{
            base: cta.mobileWidth ?? '100%',
            sm: cta.desktopWidth ?? 'auto',
          }}
          colorScheme={cta.bgColor}
          leftIcon={cta.icon}
          variant={cta.state}
          data-testid={cta.dataTestId}
          metadata={metadata}
          {...getButtonColor(cta)}
          elementType={ElementTypes.BUTTON}
          elementAction={ElementActions.OPEN_MODAL}
          elementName={ElementNames.WRITE_A_REVIEW}
        />
      );
    } else if (url) {
      renderComponent = (
        <Button
          width={{
            base: cta.mobileWidth ?? '100%',
            sm: cta.desktopWidth ?? 'auto',
          }}
          colorScheme={cta.bgColor}
          data-testid={cta.dataTestId}
          variant={cta.state}
          as="a"
          href={`${type ? `${type}:` : ''}${url}`}
          key={cta.text}
          target={behavior || DEFAULT_CTA_BEHAVIOR}
          rel={rel?.join(' ') || ''}
          {...getButtonColor(cta)}
          leftIcon={cta.icon}
          elementType={(cta.type as ElementTypes) || ElementTypes.LINK}
          elementAction={
            cta.url?.startsWith('/') ||
            siteContext.site?.domains.map(
              (domain) => domain.indexOf(cta.url || '') >= 0
            )
              ? ElementActions.INTERNAL_LINK
              : ElementActions.EXTERNAL_LINK
          }
          elementName={trackingName || ElementNames.GENERIC_BUTTON}
          destinationUrl={cta.url || ''}
        >
          {cta.text}
        </Button>
      );
    }
    return (
      <Fragment key={index}>
        {renderComponent}
        {/* TODO: Add support for state management*/}
        {!cta?.url && <></>}
      </Fragment>
    );
  });

  if (onlyCTA?.enabled) {
    return <>{ctaButtons}</>;
  }

  if (card?.enabled) {
    return (
      <Container className={className}>
        <Card
          align="center"
          textAlign="center"
          variant={card?.variant}
          size={card?.size}
        >
          <CardHeader>
            {heading && (
              <Heading
                as={headingElement}
                color={getColor(headingColor, headingColorRange)}
                size={headingSize}
              >
                {heading}
              </Heading>
            )}
          </CardHeader>
          <CardBody>
            {summary && (
              <Text
                as="div"
                dangerouslySetInnerHTML={{ __html: summary }}
                color={getColor(summaryColor, summaryColorRange)}
              />
            )}
          </CardBody>
          <CardFooter>
            <ButtonGroup spacing="4" width="full">
              {ctaButtons}
            </ButtonGroup>
          </CardFooter>
        </Card>
      </Container>
    );
  }

  return (
    <Container
      ignoreMaxWidth
      className={className}
      {...containerMiniProps}
      mb={marginBottom ? `${marginBottom} !important` : undefined}
    >
      <BaseBackground
        textColor="white"
        minHeight={{
          base: minHeightBase,
          lg: minHeightLG,
        }}
        padding={{
          base: paddingBase,
          sm: paddingSM,
          md: paddingMD,
          lg: paddingLG,
          xl: paddingXL,
          '2xl': padding2XL,
        }}
        position="relative"
        zIndex="0"
        alignItems="center"
        display="flex"
        bgColor={
          size?.field === 'fullwidth'
            ? size?.switchable?.bgColor
            : size?.bgColor
        }
        bgColorRange={
          size?.field === 'fullwidth'
            ? size?.switchable?.bgColorVariation
            : size?.bgColorVariation || '900'
        }
        type={
          size?.field === 'fullwidth' ? size.switchable?.field : 'bgColorForm'
        }
        image={size?.switchable?.image}
        imageUrl={size?.switchable?.imageUrl}
        bgImageOverlay={size?.switchable?.bgImageOverlay}
        bgOverlayOpacity={size?.switchable?.bgOverlayOpacity}
        bgImagePosition={size?.switchable?.bgImagePosition}
        {...boxMiniProps}
      >
        <Box
          maxWidth={{ base: '90vw', lg: '4xl' }}
          marginX="auto"
          textAlign={
            size?.field === 'mini' ? { base: 'left', md: 'center' } : 'center'
          }
          position="relative"
          zIndex="10"
        >
          <Stack alignItems="center" spacing={{ base: '6', lg: '8' }}>
            <>
              {heading && (
                <Heading
                  size={getTextSize('heading')}
                  fontWeight="700"
                  as={headingElement}
                  color={getColor(headingColor, headingColorRange)}
                >
                  {heading}
                </Heading>
              )}
              {summary && (
                <Text
                  as="h3"
                  className="magnolia-text"
                  fontSize={getTextSize('summary')}
                  dangerouslySetInnerHTML={{ __html: summary }}
                  color={getColor(summaryColor, summaryColorRange)}
                ></Text>
              )}
            </>
            <ButtonGroup spacing="4" width="full">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                width="full"
                gap="4"
                justifyContent="center"
                alignItems="center"
              >
                {ctaButtons}
              </Flex>
            </ButtonGroup>
          </Stack>
        </Box>
      </BaseBackground>
    </Container>
  );
};

const NewCTA: React.FC<NewCTAPropsFromAdmin> = withHydrationOnDemand({
  on: ['visible'],
})(NewCTAComponent);

export default NewCTA;
