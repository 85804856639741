import { VStack } from '@chakra-ui/layout';
import { isEmptyText } from '@utils/isEmptyText';
import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import Section from './Section';
const Table = dynamic(() => import('@components/Table'));

interface SectionTableProps {
  headingElement?: HeadingElements;
  title?: string;
  text?: string;
  tableData?: string;
  textAlignment?: 'left' | 'center' | 'right';
  titleAlignment?: 'left' | 'center' | 'right';
}

const SectionTable = ({
  headingElement = 'h2',
  title,
  text,
  tableData,
  textAlignment = 'left',
  titleAlignment = 'left',
}: SectionTableProps): React.ReactElement => {
  let json: { data: Array<Object> } | null = null;

  if (tableData) {
    try {
      json = JSON.parse(tableData);
      if (!Array.isArray(json?.data)) {
        console.error('Invalid tableData: provided JSON data is not an array');
      }
    } catch {
      console.error(
        'Invalid tableData: please check your data is a properly formatted JSON Array'
      );
    }
  }
  const dataset = json?.data;
  const hasText = isEmptyText(text);
  const hasData = json && dataset;

  if (!title && hasText && !hasData) return <></>;
  return (
    <VStack
      py="8"
      px={{ base: 4, lg: 8 }}
      maxW="container.xl"
      mx="auto"
      align="stretch"
      className="Section"
      spacing={4}
    >
      <Section
        title={title}
        headingElement={headingElement}
        richText={text}
        textAlignment={textAlignment}
        titleAlignment={titleAlignment}
      />
      {json && dataset && <Table dataset={dataset} />}
    </VStack>
  );
};

export default withHydrationOnDemand()(SectionTable);
