export enum QueryKey {
  MapFeatures = 'map-features',
  ProviderLocation = 'provider-location',
  CurrentUser = 'current-user',
  CheckPassword = 'check-password',
  Favorites = 'favorites',
  Profiles = 'profiles',
  GetCareRecipientProfiles = 'get-care-recipient-profiles',
  Needs = 'needs',
  Activities = 'activities',
  RelationshipTypes = 'relationship-types',
  Searches = 'searches',
  UserFavoriteSelfSearches = 'user-favorite-self-searches',
  UserGuidedSearches = 'user-guided-searches',
  ProvidersSearch = 'providers-search',
  InterlinkingGeoPages = 'interlinking-geo-pages',
  FacetedSearch = 'faceted-search',
}
