import storeJs from 'store';
import { v4 as uuidV4 } from 'uuid';

const THIRTY_MINUTES_IN_MILLISECONDS = 1800000;
const SESSION_DURATION = THIRTY_MINUTES_IN_MILLISECONDS;

export const createSession = (): string => {
  const sessionId = uuidV4();
  const expiration = Date.now() + SESSION_DURATION;
  const sessionData = { ID: sessionId, Expiration: expiration };

  storeJs.set('session', sessionData);

  return sessionId;
};

export const getSession = () => {
  const session = storeJs.get('session');

  if (!session) {
    return null;
  }

  const { Expiration, Timeout } = session;
  const currentTime = Date.now();
  const isSessionExpired = Expiration < currentTime || Timeout < currentTime;

  if (isSessionExpired) {
    storeJs.remove('session');
    return null;
  }

  return session;
};
