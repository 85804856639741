import { useRouter } from 'next/router';

const useCareType = () => {
  const router = useRouter();

  if (router.query.careTypeOrState) {
    return String(router.query.careTypeOrState);
  }

  if (router.query.careType) {
    return String(router.query.careType);
  }
};

export default useCareType;
