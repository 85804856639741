import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/layout';
import { createID } from '@utils/strings';
import dynamic from 'next/dynamic';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
import { filterNonReactAttributes } from '@utils/filterNonReactAttributes';
import { forwardRef, useRef, useEffect } from 'react';
import HtmlToReact from '@components/HtmlToReact';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

export interface HeadingProps extends ChakraHeadingProps {
  title: string;
  headingElement?: HeadingElements;
  headingSize?: HeadingSizes | HeadingSizes[];
  withContainer?: boolean;
  titleAlignment?: 'left' | 'center' | 'right';
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  deviceVisibility?: DeviceVisibility;
}
const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  (
    {
      title,
      headingElement = 'h2',
      headingSize = 'xl',
      withContainer = true,
      titleAlignment = 'left',
      paddingTop,
      paddingBottom,
      paddingLeft,
      children,
      deviceVisibility,
      ...rest
    },
    ref
  ) => {
    const { id } = rest;
    const headingRef = useRef<HTMLHeadingElement | null>(null);

    useEffect(() => {
      if (ref && 'current' in ref) {
        ref.current = headingRef.current;
      }
    }, [headingRef, ref]);
    const isHidden = useResponsiveDisplay(deviceVisibility);
    if (isHidden) {
      return <></>;
    }
    if (!title) return <></>;

    const attributes = filterNonReactAttributes(rest);

    const getHeading = () => (
      <ChakraHeading
        ref={headingRef}
        as={headingElement}
        size={headingSize}
        id={id ? id : createID(title)}
        textAlign={titleAlignment}
        style={{ scrollMarginTop: 48 }}
        pt={paddingTop}
        pb={paddingBottom}
        pl={paddingLeft}
        {...attributes}
      >
        {HtmlToReact({ html: title })}
        {children}
      </ChakraHeading>
    );

    if (!withContainer) return getHeading();

    return <Container>{getHeading()}</Container>;
  }
);

Heading.displayName = 'Heading';

export default Heading;
