import { VStack } from '@chakra-ui/layout';
import { Section } from '@components/Sections';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import ProviderContext, {
  CatalogFaq,
  Faq,
  Provider,
} from '~/contexts/Provider';
import { Metadata } from '~/types/Magnolia';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

import { mockModMonProvider } from '@mocks/modmon.mock';
import { ParseMagnoliaPage } from '@utils/parser/magnolia';
import { HeadingElements } from '~/@types/heading';
import CareTypeFaqAccordion from './ProviderFAQAccordion';
import StoryImage, { StoryImageProps } from '@components/Image/StoryImage';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';

interface MagnoliaFAQ extends Metadata {
  answer: string;
  question: string;
}

interface FAQs extends Metadata {
  [key: string]: string | Array<string> | MagnoliaFAQ | undefined;
}

interface Props {
  templateId?: string;
  headingElement: HeadingElements;
  text?: string;
  deviceVisibility?: DeviceVisibility;
  switchable?: {
    faqs?: FAQs;
    field: string;
    dataFAQ?: string;
  };
  faqs?: FAQs;
  image?: StoryImageProps;
}

type Switchable = {
  field: string;
  faqs?: FAQs;
  dataFAQ: string | undefined;
};

export function validateAndProcessFAQs(
  switchable: Switchable | undefined,
  provider: Provider | null | undefined,
  manualFaqs?: FAQs
): Faq[] | CatalogFaq[] {
  let items: Faq[] | CatalogFaq[] = [];

  if (switchable?.field === 'manually' || manualFaqs) {
    const faqItems = manualFaqs || switchable?.faqs;
    const mappedFaqs =
      faqItems?.['@nodes']?.map((node) => {
        const faqItem = faqItems[node] as MagnoliaFAQ;
        return {
          content: faqItem.question,
          answer: {
            id: faqItem['@id'],
            content: faqItem.answer,
            status: '',
          },
        };
      }) || [];

    const parsedFaqs = (provider?.faqs ?? []).map((faq) => {
      const parsedQuestion = {
        question: faq.content,
      };

      ParseMagnoliaPage({
        source: parsedQuestion,
        values: { provider: provider?.name || {} },
      });

      return {
        content: parsedQuestion.question,
        answer: {
          id: faq.answer?.id,
          content: faq.answer?.content,
          status: '',
        },
      };
    });

    // If the CMS is not used, the provider's FAQs are used.
    items = (mappedFaqs?.length ? mappedFaqs : parsedFaqs) as
      | Faq[]
      | CatalogFaq[];
  } else if (switchable?.field === 'dataCatalog' && switchable?.dataFAQ) {
    try {
      const json = JSON.parse(switchable?.dataFAQ);
      if (!Array.isArray(json?.data)) {
        console.error('Invalid chartData: provided JSON data is not an array');
      } else {
        items = json.data;
      }
    } catch {
      console.error(
        'Invalid chartData: please check your data is a properly formatted JSON Array'
      );
    }
  }

  return items;
}

const ProviderFAQ = ({
  templateId,
  headingElement,
  text,
  title,
  deviceVisibility,
  switchable,
  faqs,
  image,
}): React.ReactElement<Props> => {
  const plasmicProvider = templateId != '' ? mockModMonProvider : null;
  const provider = useContext(ProviderContext)?.provider || plasmicProvider;
  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }
  let items = validateAndProcessFAQs(switchable, provider, faqs);

  if (!items.length) {
    return <></>;
  }

  return (
    <Container>
      <VStack align="stretch" spacing="4">
        <Section
          title={title}
          headingElement={headingElement}
          richText={text}
        />
        {image?.switchable?.imageUrl && <StoryImage {...image} />}
        <CareTypeFaqAccordion items={items} />
      </VStack>
    </Container>
  );
};

export default ProviderFAQ;
