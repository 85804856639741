import { APIDOMAINS } from '~/types/Domains';
import { WebSiteDetailsVariants } from '~/contexts/Provider';
import { Provider } from '~/contexts/Provider';

export const getProviderWebsiteDetailsVariant = (
  provider: Provider,
  domain: string
): WebSiteDetailsVariants | undefined => {
  const website = APIDOMAINS[domain];
  return provider?.webSiteDetailsVariants?.find((e) => e?.website === website);
};
