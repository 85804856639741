export const getColor = (color?: string, range?: string): string => {
  if (color === 'white' || color === 'black') return color;
  return color && range ? `${color}.${range}` : '';
};

export const getButtonColor = (button) => {
  if (button.state === 'outline') return '';
  return { color: getColor(button?.textColor, '500') };
};

export const getBackgroundColor = (bgColor: string, bgColorRange?: string) => {
  return bgColor === 'white' || bgColor === 'black'
    ? bgColor
    : `${bgColor}.${bgColorRange}`;
};

export type Color =
  | {
      color: string;
      range: string;
    }
  | {
      color: 'white' | 'black';
      range?: never;
    };
