import { sliderAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $thumbSize = cssVar('slider-thumb-size');
const $trackSize = cssVar('slider-track-size');

const baseStyle = definePartsStyle({
  filledTrack: {},
  track: {
    height: '1.5',
  },
});

const sizeLg = definePartsStyle({
  container: {
    [$thumbSize.variable]: `sizes.4`,
    [$trackSize.variable]: `sizes.1.5`,
  },
});

const sizeMd = definePartsStyle({
  container: {
    [$thumbSize.variable]: `sizes.3.5`,
    [$trackSize.variable]: `sizes.1`,
  },
});

const sizeSm = definePartsStyle({
  container: {
    [$thumbSize.variable]: `sizes.2.5`,
    [$trackSize.variable]: `sizes.0.5`,
  },
});

const sizes = {
  lg: sizeLg,
  md: sizeMd,
  sm: sizeSm,
};

export const sliderTheme = defineMultiStyleConfig({ baseStyle, sizes });
