import { createID } from '@utils/strings';
import { useEffect } from 'react';

export function useScrollToAnchor(targetRef, elementId, execute) {
  useEffect(() => {
    const id = createID(elementId);
    if (
      targetRef.current &&
      window !== undefined &&
      window.location.hash.includes(id) &&
      execute
    ) {
      targetRef.current.scroll();
    }
  }, [targetRef, elementId, execute]);
}
