export const usStates = [
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WY',
    value: 'WY',
  },
];

enum stateAbbreviations {
  Alaska = 'AK',
  Alabama = 'AL',
  Arkansas = 'AR',
  Arizona = 'AZ',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  'District of Columbia' = 'DC',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Iowa = 'IA',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Massachusetts = 'MA',
  Maryland = 'MD',
  Maine = 'ME',
  Michigan = 'MI',
  Minnesota = 'MN',
  Missouri = 'MO',
  Mississippi = 'MS',
  Montana = 'MT',
  'North Carolina' = 'NC',
  'North Dakota' = 'ND',
  Nebraska = 'NE',
  'New Hampshire' = 'NH',
  'New Jersey' = 'NJ',
  'New Mexico' = 'NM',
  Nevada = 'NV',
  'New York' = 'NY',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  'Puerto Rico' = 'PR',
  'Rhode Island' = 'RI',
  'South Carolina' = 'SC',
  'South Dakota' = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Virginia = 'VA',
  Vermont = 'VT',
  Washington = 'WA',
  Wisconsin = 'WI',
  'West Virginia' = 'WV',
  Wyoming = 'WY',
}

export const getStateAbbreviation = (state: string): string | null => {
  const lowerCaseState = state.toLowerCase();
  for (const stateName in stateAbbreviations) {
    if (stateName.toLowerCase() === lowerCaseState) {
      return stateAbbreviations[stateName];
    }
  }
  return null;
};

export const getStateNameFromAbbreviation = (
  abbreviation: string
): string | null => {
  const capitalizedAbbreviation = abbreviation.toUpperCase();
  for (const stateName in stateAbbreviations) {
    if (stateAbbreviations[stateName] === capitalizedAbbreviation) {
      return stateName;
    }
  }
  return null;
};
