import { Box, Heading, Text, VStack } from '@chakra-ui/layout';
import { CallToActionProps } from '@components/NewCallToAction/NewCallToAction';
import Image from 'next/image';
import { Provider, ProviderService } from '~/contexts/Provider';
import CostSection from './CostSection';
import ProviderCareTypeServiceModal from './ProviderCareTypeServiceModal';
import { Metadata } from '~/types/Magnolia';

const boxStyles: Record<string, React.ComponentProps<typeof Box>> = {
  'caring.com': {
    bgColor: 'white',
    shadow: 'sm',
    boxShadow: 'lg',
  },
  'seniorhomes.com': {
    bgColor: 'secondary.50',
  },
};

interface Props {
  domain: string;
  provider: Provider;
  service: ProviderService;
  withImage?: boolean;
  withDescription?: boolean;
  withPricing?: boolean;
  withButton?: boolean;
  obfuscateCost?: boolean;
  obfuscatedCostCta?: CallToActionProps;
  metadata: Metadata;
}

const ProviderCareTypeService: React.FC<Props> = ({
  domain,
  provider,
  service,
  withImage = true,
  withDescription = true,
  withPricing = true,
  withButton = false,
  obfuscateCost = false,
  obfuscatedCostCta,
  metadata,
}) => {
  const name = service.category.name;
  const description = service.category.description;
  const image = service.category.imageURL;
  const startingPriceCents = service.costs?.startingPriceCents;

  return (
    <Box borderRadius="md" height="full" width="full" {...boxStyles[domain]}>
      <VStack p="6" spacing="5" alignItems="start" h="full">
        {withImage && image && (
          <Box
            w="full"
            minH="32"
            h="32"
            position="relative"
            data-testid="service-image"
          >
            <Image src={image} alt="" fill style={{ objectFit: 'cover' }} />
          </Box>
        )}
        <VStack
          alignItems="start"
          justifyContent="space-between"
          h="full"
          w="full"
        >
          <VStack spacing="2" alignItems="start" mb="2">
            <Heading as="h3" size="md">
              {name}
            </Heading>
            {withDescription && description && (
              <Text
                fontSize="sm"
                noOfLines={5}
                data-testid="service-description"
              >
                {description}
              </Text>
            )}
          </VStack>
          {withPricing && startingPriceCents && (
            <CostSection
              minimumCost={startingPriceCents / 100}
              obfuscated={obfuscateCost}
              obfuscatedCostCta={obfuscatedCostCta}
              metadata={metadata}
            />
          )}
          <Box pt="2">
            <ProviderCareTypeServiceModal
              provider={provider}
              service={service}
              withButton={withButton}
            />
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export default ProviderCareTypeService;
