import { gql } from 'graphql-request';
import { z } from 'zod';
import cachedClient from './cached-client';

export const globalsFragment = gql`
  fragment GlobalsFragment on Query {
    globals(path: $path, limit: 200) {
      name
      value
    }
  }
`;

const getGlobalsDocument = gql`
  query GetGlobals($path: String!) {
    ...GlobalsFragment
  }
  ${globalsFragment}
`;

export const GlobalParser = z
  .array(
    z.object({
      name: z.string().catch(''),
      value: z.string().catch(''),
    })
  )
  .transform((val) => {
    const globals = Object.fromEntries(
      val.map((item) => [item.name, item.value])
    );
    return globals;
  });

type QueryResponse = {
  globals: z.input<typeof GlobalParser>;
};

export const getGlobals = async (path: string) => {
  try {
    const { globals } = await cachedClient.request<QueryResponse>(
      getGlobalsDocument,
      { path }
    );
    const parsedGlobals = GlobalParser.parse(globals);
    return parsedGlobals;
  } catch (error) {
    console.log('Error getting globals: ', JSON.stringify(error));
  }
};

export type Globals = z.infer<typeof GlobalParser>;
