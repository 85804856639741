import { extendTheme } from '@chakra-ui/theme-utils';
import base from '@styles/themes/base';
import { buttonTheme } from './seniorhomes/button';
import { cardTheme } from './seniorhomes/card';

const seniorhomesTheme = extendTheme(base, {
  styles: {
    global: {
      h1: {
        color: 'primary.700',
      },
      h2: {
        color: 'primary.700',
      },
      h3: {
        color: 'primary.700',
      },
      h4: {
        color: 'primary.700',
      },
      h5: {
        color: 'primary.700',
      },
      h6: {
        color: 'primary.700',
      },
      p: {
        color: 'primary.600',
      },
      footer: {
        p: {
          color: 'white',
          a: {
            textDecoration: 'underline',
          },
        },
        li: {
          color: 'white',
          a: {
            textDecoration: 'none !important',
            hover: {
              textDecoration: 'underline',
            },
          },
        },
      },
      li: {
        color: 'primary.600',
      },
    },
  },
  colors: {
    graphic: {
      '50': '#EEF3F6',
      '100': '#D0DDE7',
      '200': '#B2C8D7',
      '300': '#94B2C7',
      '400': '#769DB7',
      '500': '#5887A8',
      '600': '#466C86',
      '700': '#355165',
      '800': '#233643',
      '900': '#121B22',
    },
    link: {
      '50': '#E6F5FF',
      '100': '#B8E2FF',
      '200': '#8AD0FE',
      '300': '#5DBDFE',
      '400': '#2FABFE',
      '500': '#0198FE',
      '600': '#017ACB',
      '700': '#015B98',
      '800': '#013D65',
      '900': '#001E33',
    },
    primary: {
      '50': '#F0F3F4',
      '100': '#D5DCE2',
      '200': '#BAC6CF',
      '300': '#9FAFBC',
      '400': '#8499A9',
      '500': '#698296',
      '600': '#546878',
      '700': '#3F4E5A',
      '800': '#2A343C',
      '900': '#151A1E',
    },
    secondary: {
      '50': '#F5F3EF',
      '100': '#E4DDD3',
      '200': '#D3C6B6',
      '300': '#C1B09A',
      '400': '#B09A7D',
      '500': '#9E8461',
      '600': '#7F6A4D',
      '700': '#5F4F3A',
      '800': '#3F3527',
      '900': '#201A13',
    },
    tertiary: {
      '50': '#F0F3F4',
      '100': '#D5DCE2',
      '200': '#BAC6CF',
      '300': '#9FAFBC',
      '400': '#8499A9',
      '500': '#698296',
      '600': '#546878',
      '700': '#3F4E5A',
      '800': '#2A343C',
      '900': '#151A1E',
    },
    accent: {
      '50': '#FFF0E6',
      '100': '#FED5B9',
      '200': '#FDBB8B',
      '300': '#FDA05E',
      '400': '#FC8531',
      '500': '#FC6A03',
      '600': '#C95503',
      '700': '#974002',
      '800': '#652A01',
      '900': '#321501',
    },
    background: {
      '50': '#F5F3EF',
      '100': '#E4DDD3',
      '200': '#D3C6B6',
      '300': '#C1B09A',
      '400': '#B09A7D',
      '500': '#9E8461',
      '600': '#7F6A4D',
      '700': '#5F4F3A',
      '800': '#3F3527',
      '900': '#201A13',
    },
    success: {
      '50': '#EEF7F2',
      '100': '#CFE8DA',
      '200': '#B0D9C2',
      '300': '#91CAAA',
      '400': '#72BB92',
      '500': '#52AD7A',
      '600': '#428A61',
      '700': '#316849',
      '800': '#214531',
      '900': '#102318',
    },
    error: {
      '50': '#FBE9EC',
      '100': '#F4C2C9',
      '200': '#EE9BA7',
      '300': '#E77485',
      '400': '#E04D62',
      '500': '#D92640',
      '600': '#AE1E33',
      '700': '#821726',
      '800': '#570F19',
      '900': '#2B080D',
    },
    info: {
      '50': '#F0F3F4',
      '100': '#D5DCE2',
      '200': '#BAC6CF',
      '300': '#9FAFBC',
      '400': '#8499A9',
      '500': '#698296',
      '600': '#546878',
      '700': '#3F4E5A',
      '800': '#2A343C',
      '900': '#151A1E',
    },
  },
  components: {
    Button: buttonTheme,
    Card: cardTheme,
    Link: {
      baseStyle: {
        color: 'link.600',
        textDecoration: 'underline',
      },
    },
    Heading: {
      baseStyle: {
        color: 'primary.700',
      },
      sizes: {
        '4xl': {
          lineHeight: '4.5rem',
          fontSize: { base: '3.75rem', md: '4.5rem' },
          fontWeight: '700',
        },
        '3xl': {
          lineHeight: '3.75rem',
          fontSize: { base: '3rem', md: '3.75rem' },
          fontWeight: '700',
        },
        '2xl': {
          lineHeight: '3rem',
          fontSize: { base: '2.25rem', md: '3rem' },
          fontWeight: '700',
        },
        xl: {
          lineHeight: '2.75rem',
          fontSize: { base: '1.875rem', md: '2.25rem' },
          fontWeight: '700',
        },
        lg: {
          lineHeight: '2.25rem',
          fontSize: { base: '1.25rem', md: '1.875rem' },
          fontWeight: '700',
        },
        md: {
          lineHeight: '1.5rem',
          fontSize: '1.25rem',
          fontWeight: '700',
        },
        sm: {
          lineHeight: '1.125rem',
          fontSize: '1rem',
          fontWeight: '700',
        },
        xs: {
          lineHeight: '1rem',
          fontSize: '.875rem',
          fontWeight: '700',
        },
      },
    },
    Text: {
      sizes: {
        '6xl': {
          lineHeight: '5.6rem',
          fontSize: '3.75rem',
        },
        '5xl': {
          lineHeight: '4.5rem',
          fontSize: '3rem',
        },
        '4xl': {
          lineHeight: '3.375rem',
          fontSize: '2.25rem',
        },
        '3xl': {
          lineHeight: '2.8125rem',
          fontSize: '1.875rem',
        },
        '2xl': {
          lineHeight: '2.25rem',
          fontSize: '1.5rem',
        },
        xl: {
          lineHeight: '1.875rem',
          fontSize: '1.25rem',
        },
        lg: {
          lineHeight: '1.6875rem',
          fontSize: '1.25rem',
        },
        md: {
          lineHeight: '1.5rem',
          fontSize: '1rem',
        },
        sm: {
          lineHeight: '1.3125rem',
          fontSize: '.875rem',
        },
        xs: {
          lineHeight: '1.125rem',
          fontSize: '.75rem',
        },
      },
    },
  },
});

export default seniorhomesTheme;
