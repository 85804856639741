const excludePrefixes = new Set([
  '@',
  'mgnl:',
  'jcr:',
  'bgColorRange',
  'linkText',
  'readMoreButton',
  'actionBehavior',
  'setControlContextData',
  'deviceVisibility',
  'buttonPadding',
  'buttonHeight',
]);

export const filterNonReactAttributes = (attributes: {
  [key: string]: any;
}) => {
  const filteredAttributes = {};

  Object.keys(attributes).forEach((key) => {
    const isExcluded = Array.from(excludePrefixes).some((prefix) =>
      key.startsWith(prefix)
    );
    if (!isExcluded) {
      filteredAttributes[key] = attributes[key];
    }
  });

  return filteredAttributes;
};
