import { Grid, GridItem, Heading } from '@chakra-ui/react';
import { ElementActions } from '@components/Analytics/events/ElementClicked';
import Button from '@components/Button';
import dynamic from 'next/dynamic';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

interface CTABlock {
  heading: 'marketingCTA';
  text: string;
  url: string;
  behavior: '_blank' | '_self' | '_parent' | '_top';
  rel?:
    | string
    | Array<'external' | 'nofollow' | 'noopener' | 'noreferrer' | 'opener'>;
}

interface Props {
  callToActionBlocks: CTABlock[];
}

const CTABlocksComponent: React.FC<Props> = ({ callToActionBlocks }: Props) => {
  return (
    <Container>
      <Grid
        rowGap={8}
        columnGap={6}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        justifyContent="space-between"
      >
        {callToActionBlocks['@nodes'].map((key) => {
          const card = callToActionBlocks[key];
          const rel =
            typeof card.rel === 'string' ? card?.rel?.split(' ') : card.rel;
          return (
            <GridItem
              key={key}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              my={8}
            >
              {card.heading && (
                <Heading
                  size="md"
                  mb={8}
                  textAlign="center"
                  color="primary.900"
                >
                  {card.heading}
                </Heading>
              )}
              <Button
                as="a"
                href={`${card.url}`}
                target={card.behavior || '_self'}
                rel={rel?.join(' ') || ''}
                colorScheme="primary"
                variant="outline"
                textColor="primary.500"
                width={{ base: '100%', sm: 'auto' }}
                borderRadius="sm"
                height="58px"
                elementAction={ElementActions.EXTERNAL_LINK}
              >
                {card.text}
              </Button>
            </GridItem>
          );
        })}
      </Grid>
    </Container>
  );
};

export default CTABlocksComponent;
