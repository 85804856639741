import { useBreakpointValue } from '@chakra-ui/react';
export type DeviceVisibility = 'mobile' | 'desktop' | undefined;

const isHidden = (
  deviceVisibility: DeviceVisibility,
  isMobile: boolean
): boolean => {
  if (
    (deviceVisibility === 'mobile' && !isMobile) ||
    (deviceVisibility === 'desktop' && isMobile)
  ) {
    return true;
  }
  return false;
};

export const useResponsiveDisplay = (
  deviceVisibility: DeviceVisibility
): boolean => {
  const isMobile = useBreakpointValue({ base: true, md: false }) ?? true;
  return isHidden(deviceVisibility, isMobile);
};
