import snippet from '@segment/snippet';
import Script from 'next/script';
interface AnalyticsProps {
  segmentCdnURL: string;
  segmentWriteKey: string;
}
function loadAnalytics(segmentCdnURL, segmentWriteKey) {
  const opts = {
    host: segmentCdnURL,
    apiKey: segmentWriteKey,
    page: false,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}
const Analytics = ({
  segmentCdnURL,
  segmentWriteKey,
}: AnalyticsProps): React.ReactElement => {
  return (
    <Script
      id="analyticsInstallation"
      type="application/javascript"
      dangerouslySetInnerHTML={{
        __html: loadAnalytics(segmentCdnURL, segmentWriteKey),
      }}
      key="website-analyticsInstallation"
    />
  );
};

export default Analytics;
