import isArray from 'lodash/isArray';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { formatNumberLocale } from '~/utils/number';
import { providerIsEnhancedAndNotSuppressed } from '~/utils/providers';
import { getStateAbbreviation } from '~/utils/UsStates';
import { ParserProps } from './index.types';
import isNull from 'lodash/isNull';
import { truncateToOneParagraphIfProviderIsNotEnhanced } from '@utils/parser/truncateToOneParagraphIfProviderIsNotEnhanced';
import { Provider } from '~/contexts/Provider';
function isDefined(value: unknown): boolean {
  return typeof value !== 'undefined' && value !== null;
}

export function getProperty(key, props) {
  const notation = key.split('.');

  for (let i = 0; i < notation.length; i++) {
    const idx = notation[i].match(/\[(.*?)\]+/g);
    if (idx) {
      key = notation[i].replace(idx, '');
      const index = idx[0].replace(/[\[\]]/g, '');
      if (isDefined(props[key]) && isDefined(props[key][index])) {
        props = props[key][index];
      }
    }

    if (isDefined(props[notation[i]])) {
      props = props[notation[i]];
    }
  }

  if (typeof props !== 'object') {
    return props;
  } else {
    return '';
  }
}

export function tensing(singular: string, plural: string, value: number) {
  if (value > 1) {
    return plural;
  }
  return singular;
}

export function ifExists(num: number, retString: string) {
  return num > 0 ? retString : '';
}

export function isGreater(
  num1: number,
  num2: number,
  less: string,
  more: string
) {
  return num1 > num2 ? more : less;
}

export function costComparison(num1: number, num2: number, ele: string = '') {
  let value = ((num1 - num2) / ((num1 + num2) / 2)) * 100;

  let str = '';

  if (value >= 5) {
    str = 'higher than';
  } else if (value >= 2 && value <= 4.99) {
    str = 'slightly higher than';
  } else if (value >= -1.99 && value <= 1.99) {
    str = 'about the same as';
  } else if (value >= -4.99 && value <= -2) {
    str = 'slightly lower than';
  } else if (value <= -5) {
    str = 'lower than';
  }

  if (ele) {
    return '<' + ele + '>' + str + '</' + ele + '>';
  }

  return str;
}

export function topProvidersCount(count: string) {
  const providerCount = Number(count);
  const topProviders =
    providerCount <= 2
      ? ''
      : providerCount < 5
      ? 3
      : providerCount < 10
      ? 5
      : 10;
  return topProviders.toString();
}

export function ifElse(condition: boolean, ifTrue: string, ifFalse: string) {
  return condition ? ifTrue : ifFalse;
}

function parseLogicHelpers(props: ParserProps) {
  // {ifElse(boolean|string|string)} return string
  const ifElseRegex =
    /\{ifElse\s*[^\(]*\(\s*(?<params>([^|]+\|[^|]+\|[\s\S]+))\)+\}/;
  const pIfElse = props.source.match(RegExp(ifElseRegex, 'g')) || [];

  pIfElse.forEach((funcName) => {
    const argsStr = funcName.match(ifElseRegex)?.groups?.params || '';
    const args = argsStr.split('|');
    if (args.length >= 3) {
      let condition: boolean;
      try {
        // Allow conditions to be written like {ifElse(String(...)|...|...)}
        const stringFound = args[0].match(/String\s*[^\(]*\(\s*([^\)]*)\)/);
        if (isArray(stringFound)) {
          const parsedCondition = Parser({
            ...props,
            strip: true,
            source: stringFound[1],
          });
          condition = Boolean(parsedCondition.trim());
        } else {
          const parsedCondition = Parser({
            ...props,
            strip: true,
            source: args[0],
          });
          condition = new Function(`return ${parsedCondition}`)();
        }
      } catch (error) {
        console.error('ifElse: Invalid condition: ', args[0]);
        return;
      }
      const replace = ifElse(Boolean(condition), args[1], args[2]);
      props.source = props.source.replace(funcName, replace);
    }
  });

  // {truncateToOneParagraphIfProviderIsNotEnhanced(string)}
  const truncateToOneParagraphRegex =
    /\{truncateToOneParagraphIfProviderIsNotEnhanced\s*[^\(]*\(\s*(?<params>[^\)]*)\)\}/;
  const pTruncateToOneParagraphIfProviderIsNotEnhanced =
    props.source.match(RegExp(truncateToOneParagraphRegex, 'g')) || [];

  const provider = props.values.provider as Provider;
  const providerIsNotEnhanced = !providerIsEnhancedAndNotSuppressed(provider);

  pTruncateToOneParagraphIfProviderIsNotEnhanced.forEach((funcName) => {
    const argsStr =
      funcName.match(truncateToOneParagraphRegex)?.groups?.params || '';
    if (argsStr) {
      const parsedContent = Parser({
        ...props,
        strip: true,
        source: argsStr,
      });

      const replace = truncateToOneParagraphIfProviderIsNotEnhanced(
        parsedContent,
        providerIsNotEnhanced
      );
      props.source = props.source.replace(funcName, replace);
    }
  });
}

function preParseLogicHelpers(props: ParserProps) {
  let parsedSource = true;

  // We might have nested logic helpers, so make sure we only stop pre-parsing
  // when no content is changed
  while (parsedSource) {
    const sourceBeforeParseLogic = String(props.source);
    parseLogicHelpers(props);
    parsedSource = sourceBeforeParseLogic !== props.source;
  }
}

export const Parser = (props: ParserProps): string => {
  preParseLogicHelpers(props);

  const keys = props?.source?.match?.(/\{[a-zA-Z0-9-_\.\[\]]+\}/g) || [];
  keys.forEach((search) => {
    const key = search.split(/{|}/g)[1];
    const value = getProperty(key, props.values) ?? '';

    if (value || props.strip) {
      props.source = props.source.replace(search, value);
    }
  });

  // CUSTOM FUNCTIONS
  // topProvidersCount
  const pTopProvidersCount = props.source.match(
    /\{topProvidersCount\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );

  pTopProvidersCount &&
    pTopProvidersCount.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);

      if (isArray(argument)) {
        const countString = topProvidersCount(argument[1]);
        props.source = props.source.replace(funcName, countString);
      }
    });

  // {tensing(string|string|number)} returns string
  // {tensing(singular|plural|number)}
  const pTensing = props.source.match(/\{tensing\s*[^\(]*\(\s*([^\)]*)\)+\}/g);
  pTensing &&
    pTensing.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);
      if (isArray(argument)) {
        const args = argument[1].split('|');
        if (args.length == 3) {
          const replace = tensing(args[0], args[1], Number(args[2]));
          props.source = props.source.replace(funcName, replace);
        }
      }
    });
  // {isGreater(number|number|string|string)}
  const pIsGreater = props.source.match(
    /\{isGreater\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pIsGreater &&
    pIsGreater.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);
      if (isArray(argument)) {
        const args = argument[1].split('|');
        if (args.length == 4) {
          const replace = isGreater(
            Number(args[0]),
            Number(args[1]),
            args[2],
            args[3]
          );
          props.source = props.source.replace(funcName, replace);
        }
      }
    });

  // {costComparison(number|number|string)}
  const pCostComparison = props.source.match(
    /\{costComparison\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pCostComparison &&
    pCostComparison.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);
      if (isArray(argument)) {
        const args = argument[1].split('|');
        if (args.length == 2 || args.length == 3) {
          const replace = costComparison(
            Number(args[0]),
            Number(args[1]),
            args[2]
          );
          props.source = props.source.replace(funcName, replace);
        }
      }
    });

  // {stateAbbreviation(string)} returns string
  const pStateAbbreviation = props.source.match(
    /\{stateAbbreviation\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pStateAbbreviation &&
    pStateAbbreviation.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);
      if (isArray(argument)) {
        const replace = getStateAbbreviation(argument[1]);
        props.source = props.source.replace(
          funcName,
          isNull(replace) ? '' : replace
        );
      }
    });

  // NUMBER FUNCTIONS
  // {formatNumberLocale(number)} returns string
  const pFormatNumberLocale = props.source.match(
    /\{formatNumberLocale\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pFormatNumberLocale &&
    pFormatNumberLocale.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);

      if (isArray(argument)) {
        const replace = formatNumberLocale(Number(argument[1]));
        props.source = props.source.replace(funcName, replace);
      }
    });

  // {formatCentsToDollars(number)} returns string
  const pFormatCentsToDollars = props.source.match(
    /\{formatCentsToDollars\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pFormatCentsToDollars &&
    pFormatCentsToDollars.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);

      if (isArray(argument)) {
        const replace = formatNumberLocale(Number(argument[1]) / 100);
        props.source = props.source.replace(funcName, replace);
      }
    });

  // STRING FUNCTIONS

  // careTypeTranslation
  // {careTypeTranslation(string caretype)} return careTypeTranslation string
  const pCareTypeTranslation = props.source.match(
    /\{careTypeTranslation\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );

  pCareTypeTranslation &&
    pCareTypeTranslation.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);
      if (
        isArray(argument) &&
        props.values?.careTypeTranslation?.hasOwnProperty(argument[1])
      ) {
        props.source = props.source.replace(
          funcName,
          props.values?.careTypeTranslation[argument[1]].toString()
        );
      } else {
        props.source = props.source.replace(funcName, '');
      }
    });

  // globalContent
  // {globalContent(key)} return Global Content string if it exists
  const pGlobalContent = props.source.match(
    /\{globalContent\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );

  pGlobalContent &&
    pGlobalContent.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);
      if (
        isArray(argument) &&
        props.values?.global?.hasOwnProperty(argument[1])
      ) {
        props.source = props.source.replace(
          funcName,
          props.values?.global[argument[1]].toString()
        );
      } else {
        props.source = props.source.replace(funcName, '');
      }
    });

  const pStartCase = props.source.match(
    /\{startCase\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pStartCase &&
    pStartCase.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);

      if (isArray(argument)) {
        const replace = startCase(argument[1]);
        props.source = props.source.replace(funcName, replace);
      }
    });

  // {kebabCase(string)} return string
  const pKebabCase = props.source.match(
    /\{kebabCase\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pKebabCase &&
    pKebabCase.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);
      if (isArray(argument)) {
        const replace = kebabCase(argument[1]);
        props.source = props.source.replace(funcName, replace);
      }
    });

  // LOGIC FUNCTIONS
  const pIfExists = props.source.match(
    /\{ifExists\s*[^\(]*\(\s*([^\)]*)\)+\}/g
  );
  pIfExists &&
    pIfExists.forEach((funcName) => {
      const name = funcName.split(/{|}/g)[1];
      const argument = name.match(/\((.*?)\)/);

      if (isArray(argument)) {
        const args = argument[1].split('|');
        if (args.length == 2) {
          const replace = ifExists(Number(args[0]), args[1]);
          props.source = props.source.replace(funcName, replace);
        }
      }
    });

  return props.source;
};

export const strip = (string: string | undefined): string => {
  if (!string) {
    return '';
  }
  return Parser({
    source: string,
    values: {},
    strip: true,
  });
};
