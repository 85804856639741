import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Heading } from '@chakra-ui/layout';

interface Props {
  title: string;
  content: string;
  first?: boolean;
  last?: boolean;
}

const ProviderFAQAccordionItem: React.FC<Props> = ({
  title,
  content,
  first = false,
  last = false,
}) => {
  const accordionItemProp: AccordionItemProps = {};

  if (first) {
    accordionItemProp.borderTop = 'none';
  }
  if (last) {
    accordionItemProp.borderBottom = 'none';
  }

  return (
    <AccordionItem {...accordionItemProp}>
      <AccordionButton px="5" py="0">
        <Heading
          className="magnolia-text"
          as="h3"
          flex="1"
          textAlign="left"
          py="5"
          size="md"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        className="magnolia-text"
        px="5"
        fontSize="sm"
        dangerouslySetInnerHTML={{ __html: content }}
      ></AccordionPanel>
    </AccordionItem>
  );
};

export default ProviderFAQAccordionItem;
