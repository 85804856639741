export const careTypeToApiCareType = {
  'adult-day-care': 'Adult Day Care',
  'assisted-living': 'Assisted Living',
  'continuing-care-retirement-communities': 'Continuing Care Communities',
  'geriatric-care-managers': 'Geriatric Care Managers',
  'home-health-agencies': 'Home Health Agencies',
  hospices: 'Hospice',
  'in-home-care': 'Home Care',
  'independent-living': 'Independent Living',
  'memory-care-facilities': 'Memory Care',
  'nursing-homes': 'Nursing Homes',
  'senior-living': 'Senior Living',
  'senior-care': 'Senior Care',
  '55-plus': 'Senior Apartment',
  'low-income-affordable': 'Low Income Affordable',
};

export const getApiCareType = (value: string) => {
  return Object.keys(careTypeToApiCareType).find(
    (key) => careTypeToApiCareType[key] === value
  );
};
