import { Box, BoxProps } from '@chakra-ui/layout';
import LAYOUT from '@components/Layouts/layoutConstants';
import { forwardRef } from 'react';
interface ContainerProps extends BoxProps {
  ignoreMaxWidth?: boolean;
  noHorizontalPadding?: boolean;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    { children, ignoreMaxWidth = false, noHorizontalPadding = false, ...rest },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        className="layout-container"
        maxW={ignoreMaxWidth ? undefined : LAYOUT.CONTAINER_MAX_WIDTH}
        mx="auto"
        px={
          ignoreMaxWidth || noHorizontalPadding
            ? undefined
            : LAYOUT.CONTAINER_HORIZONTAL_PADDING
        }
        mb="var(--container-spacing, 0px)"
        w="100%"
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

Container.displayName = 'Container';

export default Container;
