import { createContext, ReactNode, useState } from 'react';

// was LocationPromotion
type Promotion = {
  id: string;
  externalPromotionText: string;
  startsAt: string;
  endsAt: string;
  visibleOnlyToFa: boolean;
};

type AmenityType = {
  id: number;
};

// LocationReview
export type Review = {
  id: string;
  createdAt: string;
  rating: number;
  title: string;
  content: string;
  authorUrl: string;
  authorName: string;
  providerResponse: string | null;
  serviceCategoryName: string;
};

// GetLocationByParamLocationAccommodationsResponse
export type Accommodations = {
  id: string;
  accommodationType: {
    name: string;
  };
  title: string | null;
  description: string | null;
  startingPriceCents: number | null;
  priceCurrency: string | null;
  bedCount: number;
  bathroomCount: number;
  squareFeetSize: string | null;
  features: string[];
  images: {
    position: number;
    url: string;
    description: string | null;
  }[];
};

export type Medicares = {
  id: string;
  legacyId: string | null;
  ownershipType: string | null;
  dateCertified: string | null;
  ratingDate: string | null;
  locationServiceId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
  localResourceType: string | null;
  offersNursingCareServices: number | null;
  offersPhysicalTherapyServices: number | null;
  offersOccupationalTherapyServices: number | null;
  offersSpeechPathologyServices: number | null;
  offersMedicalSocialServices: number | null;
  offerHomeHealthAideServices: number | null;
  frequencyBeginCare: string | null;
  frequencyDrugTeaching: string | null;
  frequencyFallCheck: string | null;
  frequencyDepressionCheck: string | null;
  frequencyFluShot: string | null;
  frequencyPneumoniaShot: string | null;
  frequencyDiabetesFootCare: string | null;
  frequencyPainMoving: string | null;
  improvementWalkingOrMovingAround: string | null;
  improvementGettingInAndOutOfBed: string | null;
  improvementBathing: string | null;
  improvementBreathing: string | null;
  improvementWounds: string | null;
  improvementMedicationCompliance: string | null;
  frequencyAdmittedToHospital: string | null;
  frequencyUrgentCareNotReadmitted: string | null;
  frequencyReadmitted: string | null;
  frequencyHospitalRecentVisitCareNotReadmitted: string | null;
  frequencyHospitalCareNotReadmitted: string | null;
  name: string | null;
  latitude: number | null;
  longitude: number | null;
  state: string | null;
  street: string | null;
  city: string | null;
  zipCode: string | null;
  phoneNumber: string | null;
  facilityTypeDescription: string | null;
  nameScore: number | null;
  addressScore: number | null;
  localResourceId: string | null;
  match: boolean;
  confirmedMatch: boolean;
  federalIdMatch: boolean;
  qmRating: string | null;
  overallRating: string | null;
  staffingRating: string | null;
  healthInspectionRating: string | null;
  federalProviderNumber: string | null;
};

//GetLocationByParamLocationAmenitiesResponse
export type Amenities = Array<{
  id: string;
  amenityName: string | null;
  amenityType?: AmenityType;
  amenityId?: number | null;
  amenityTypeId?: number | null;
  amenityCategoryName: string | null;
  amenityCategoryId: number | null;
}>;

export type AlgoliaAmenities = Array<{
  id: string;
  description: string;
  amenityType: {
    id: number;
    name: string;
    amenityCategory: { id: number; name: string };
  };
}>;

export interface LegacyAmenities {
  [key: string]: string | boolean | null | undefined;
}

interface ProviderServiceContract {
  type: string;
  isSuppressed: boolean;
}

export interface ProviderService {
  id: string;
  legacyResourceId: string;
  costs: {
    currency: string;
    startingPriceCents: number;
  };
  category: {
    name: string;
    description: string;
    imageURL: string;
  };
  accommodations: Accommodations[];
  medicares: Medicares[];
  amenities: Amenities;
  contract: ProviderServiceContract;
  isClaimed: boolean;
}
export type Award = {
  year: number;
  name: string;
  imageURL?: string;
};

export interface Provider {
  accommodations?: Accommodations[];
  address?: {
    street?: string;
    number?: string;
    district?: string;
    city: string;
    state: string;
    county?: string;
    country?: string;
    latitude?: number;
    longitude?: number;
    formattedAddress: string;
    zipCode?: string;
  };
  amenities?: Amenities;
  amenitiesLegacy?: LegacyAmenities;
  averageRating: number;
  awards?: Award[] | null;
  canonicalSlug?: string;
  category?: {
    name: string;
  };
  city?: {
    name: string;
    urlName: string;
  };
  description: string;
  enhancedListing?: boolean;
  faqs?: Faq[] | null;
  hidePricing?: boolean;
  id: string;
  lastReviewSnippet?: string;
  localEntityId?: string;
  locationUrl?: string;
  legacyId?: string;
  maximumCost?: Cost;
  meta?: {
    statusCode: number;
    destination?: string;
  };
  minimumCost?: Cost;
  name: string;
  otherWebsiteDetails?: null[] | null;
  parametrizedRatings?: {
    activities: ParametrizedRating;
    facilities: ParametrizedRating;
    food: ParametrizedRating;
    staff: ParametrizedRating;
    value: ParametrizedRating;
  };
  phoneNumber: string;
  photos?: {
    url: string;
    id?: string;
  }[];
  images?: Array<string> | null;
  residentCapacity?: number;
  reviewCount: number;
  reviews?: Review[];
  rollupType?: {
    singularSeoName: string;
    urlName: string;
  };
  services?: ProviderService[];
  slug: string;
  starsBySection?: {
    overall: number;
    health: number;
    quality: number;
    staff: number;
  };
  state?: {
    name: string;
    code?: string;
    urlName: string;
  };
  tags?: string[];
  webSiteDetailsVariants?: WebSiteDetailsVariants[];
  websiteURL?: string;
  urlName?: string;
  careTypesDescription?: string;
  promotions?: Promotion[];

  // from Algolia
  url?: string;
  locationId?: string;
  seniorHomesUrl?: string;
  seniorHomesTags?: string[];
  seniorHomesSlug?: string;
  seniorHomesDescription?: string;

  // locationResponseAdapter
  hasActivePromotions?: boolean;
  caringStars?: Award[];
  homeCarePulse?: Award[];
  hasImages?: boolean;
  careType?: string;

  // TODO: look into this
  rollup?: {
    singularSeoName: string;
    urlName: string;
  };
}

export interface WebSiteDetailsVariants {
  id?: string;
  description?: string;
  slug?: string;
  averageRating?: string | null;
  ratingCount?: number | null;
  lastReviewBody?: string | null;
  website: string;
}

export interface ProviderReview {
  id: string;
  createdAt: string;
  rating: number;
  title: string;
  content: string;
  authorUrl: string;
  authorName: string;
  providerResponse: string | null;
}

type ParametrizedRating = {
  rating: number;
  count: number;
};

export type ParametricReviewData = {
  activitiesName: string;
  activitiesCount: string;
  activitiesRatingValue: string;
  activitiesMinimumRating: string;
  activitiesMaximumRating: string;
  facilitiesName: string;
  facilitiesCount: string;
  facilitiesRatingValue: string;
  facilitiesMinimumRating: string;
  facilitiesMaximumRating: string;
  foodName: string;
  foodCount: string;
  foodRatingValue: string;
  foodMinimumRating: string;
  foodMaximumRating: string;
  staffName: string;
  staffCount: string;
  staffRatingValue: string;
  staffMinimumRating: string;
  staffMaximumRating: string;
  valueName: string;
  valueCount: string;
  valueRatingValue: string;
  valueMinimumRating: string;
  valueMaximumRating: string;
};

export interface Faq {
  content: string;
  answer?: Answer;
}
export interface CatalogFaq {
  question: string;
  answer: string;
}
interface Answer {
  id: string;
  content: string;
  status: string;
}
export interface Cost {
  costCents: number;
  currency: string;
}

export interface LocationServiceOption {
  id: string;
  name: string;
}

export type ProviderProps = {
  provider: Provider | null;
  setProvider: (provider: Provider | null) => void;
};

export const ProviderContext = createContext<ProviderProps | null>(null);

interface ContextProps {
  provider: Provider | null;
  children: ReactNode;
}

export const ProviderContextWrapper: React.FC<ContextProps> = ({
  provider,
  children,
}) => {
  const [providerState, setProviderState] = useState<Provider | null>(provider);

  return (
    <ProviderContext.Provider
      value={{
        provider: providerState,
        setProvider: setProviderState,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export default ProviderContext;
