import Script from 'next/script';

interface AnalyticsShimProps {
  preview: boolean;
}
// This provides a means to disable Segment tracking when in the Magnolia Editor Instance
// See: https://caring.atlassian.net/browse/CME-1397
const AnalyticsShim = ({ preview }: AnalyticsShimProps): React.ReactElement => {
  var html = `
    var tracking = tracking || (function(){
      var _segmentEnabled = true;
      return {
          init : function(arg) { _segmentEnabled = arg; },
          page: function(a) { 
            return _segmentEnabled ? window.analytics.page(a) : false; 
          },
          track: function(a,b) { return _segmentEnabled ? window.analytics.track(a,b) : false },
          identify: function(a,b) { return _segmentEnabled ? window.analytics.identify(a,b) : false },
          group: function(a,b) { return _segmentEnabled ? window.analytics.group(a,b) : false },
          alias: function(a) { return _segmentEnabled ? window.analytics.alias(a) : false },
          ready: function(a) { return _segmentEnabled ? window.analytics.ready(a) : false },
          user: function(a) { return _segmentEnabled ? window.analytics.user(a) : { anonymousId: function(){ return [Math.random().toString(36).slice(2, 10),Math.random().toString(36).slice(2, 6),Math.random().toString(36).slice(2, 6),Math.random().toString(36).slice(2, 6),Math.random().toString(36).slice(2, 14)].join('-'); } } },
          getSegmentEnabled: function(a) { return _segmentEnabled },
      };
    }());
    tracking.init(${!preview});
  `;

  return (
    <Script
      id="analyticsShim"
      type="application/javascript"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      key="analyticsShim"
    />
  );
};

export default AnalyticsShim;
