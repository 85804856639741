import { BreadcrumbItem } from '@components/Navigation/Breadcrumb';
import {
  FilteredEntries,
  StateDataResponse,
} from '@components/Sitemap/SitemapState/SitemapState.types';
import { getRollupForCareType } from '@utils/careTypeRollups';
import { getCareTypeFromURL } from '@utils/getCareTypeFromURL';
import isACareType from '@utils/isACareType';
import { isAState } from '@utils/isAState';
import { modifyTrailingSlash } from '@utils/modifyTrailingSlash';
import { createID, labelToSlug } from '@utils/strings';
import isArray from 'lodash/isArray';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { NextRouter } from 'next/router';
import { Link } from '~/types/LocaleCatalog';
import { Provider } from '../Provider';
import { TenantFunctions } from '../TenantFunctionsContext';
import { buildProviderUrlWithRollup, getRollUpFromRouter } from './utils';

export const homeLink = {
  label: 'Home',
  url: `/`,
};

export const buildCaringFunction = (
  router: NextRouter | undefined
): TenantFunctions => {
  return {
    getProviderDetailsPath: getProviderDetailsPath(router),
    getProviderDescription,
    getBreadcrumbLinks,
    getCareTypePath,
    getCareTypePathForSitemap,
    getCareTypeParamsForSitemap,
    generateContentForStateSitemap,
    getTrailingSlashURL,
    getReviewPath,
    getCountyForSearch,
  };
};

const getProviderDetailsPath: (
  router?: NextRouter
) => TenantFunctions['getProviderDetailsPath'] =
  (router?: NextRouter) =>
  (provider: Provider): string => {
    const {
      url,
      locationUrl,
      slug,
      address: { city, state } = { city: '', state: '' },
      services = [],
    } = provider;

    const rollupType =
      getRollUpFromRouter(router) ||
      getRollupForCareType(kebabCase(services[0]?.category.name)) ||
      'senior-living';

    return (
      url ||
      locationUrl ||
      buildProviderUrlWithRollup({
        rollupType,
        state,
        city,
        slug,
      })
    ).toLowerCase();
  };

const getProviderDescription: TenantFunctions['getProviderDescription'] = (
  provider: Provider
): string => provider.description || '';

const getBreadcrumbLinksFromRouter = (router: NextRouter): BreadcrumbItem[] => {
  let segments = router.asPath
    .split('?')[0] // remove query params
    .split('#')[0] // remove anchor
    .split('/')
    .filter((p) => p);

  const careTypeFromRouter = Object.values(segments).filter((value) =>
    isACareType(value as string)
  );

  const stateFromRouter = Object.values(router.query).find((value) =>
    isAState(value as string)
  );

  const careTypeStartCaseArray = careTypeFromRouter.map((value: string) =>
    startCase(value)
  );

  const careTypeKebabArray = careTypeStartCaseArray.map((value: string) =>
    kebabCase(value)
  );

  const breadcrumbItems = segments.reduce((acc, segment, index) => {
    const lastSegment = acc[index - 1];
    const label = startCase(segment);
    const segments = lastSegment
      ? [...lastSegment.segments, segment]
      : [segment];
    acc.push({ label, segments });
    return acc;
  }, [] as { label: string; segments: string[] }[]);

  const breadcrumbLinks = breadcrumbItems.map(({ label, segments }) => {
    const availableURLSegments = [...segments];
    return {
      label,
      url: `/${availableURLSegments.join('/')}`,
    };
  });
  const hasCareTypeOrState = careTypeFromRouter.length > 0 || stateFromRouter;

  if (hasCareTypeOrState) {
    const careTypeLinks = careTypeFromRouter.reduce(
      (acc: { label: string; url: string }[], el: string, i: number) => {
        const url = `/${careTypeKebabArray.slice(0, i + 1).join('/')}/`;
        const link = {
          label: careTypeStartCaseArray[i],
          url: url,
        };
        acc.push(link);
        return acc;
      },
      []
    );
    return [
      homeLink,
      ...careTypeLinks,
      ...breadcrumbLinks.slice(careTypeFromRouter.length),
    ];
  }

  return [homeLink, ...breadcrumbLinks];
};

const getLinksFromProvider = (provider: Provider): BreadcrumbItem[] => {
  return [
    {
      label: provider.rollup?.singularSeoName ?? '',
      url: `/${provider.rollup?.urlName}/`,
    },
    {
      label: provider.state?.name ?? '',
      url: `/${provider.rollup?.urlName}/${provider.state?.urlName}`,
    },
    {
      label: provider.city?.name ?? '',
      url: `/${provider.rollup?.urlName}/${provider.state?.urlName}/${provider.city?.urlName}`,
    },
    {
      label: provider.name ?? '',
      url:
        provider.url?.toLowerCase() ||
        `/${provider.rollup?.urlName}/${provider.state?.urlName}/${
          provider.city?.urlName
        }/${provider.slug.toLowerCase()}`,
    },
  ];
};

const getBreadcrumbLinks: TenantFunctions['getBreadcrumbLinks'] = ({
  provider,
  router,
  catalog,
}) => {
  let links: BreadcrumbItem[];

  // If the provider is available, use it to create the breadcrumb links.
  if (provider) {
    links = getLinksFromProvider(provider);
  } else {
    links = getBreadcrumbLinksFromRouter(router);
  }

  if (catalog?.county) {
    links = addCounty(links, catalog?.county as string);
  }

  return links;
};

const addCounty = (items: BreadcrumbItem[], county: string) => {
  if (county.length === 0) {
    return items;
  }
  if (
    ![4, 5].includes(items.length) ||
    items[items.length - 1].label === county
  ) {
    return items;
  }

  const countyItem: BreadcrumbItem = {
    label: county,
    url: `${items[items.length === 5 ? 3 : 2].url}/${createID(county)}`,
  };
  const [home, rollUp, careType, state, city] = items;
  return isAState(careType.label)
    ? [home, rollUp, careType, countyItem, state]
    : city
    ? [home, rollUp, careType, state, countyItem, city]
    : [home, rollUp, careType, state, countyItem];
};

const getCareTypePath: TenantFunctions['getCareTypePath'] = (
  resource: Link,
  router: NextRouter,
  enableTrailingSlash: boolean
): string => {
  const pagePath = router.asPath;
  const careTypeFromURL = getCareTypeFromURL(router.query) || '';
  const state = resource?.localResourceTypeRegion?.state?.urlName || '';
  const region = resource?.localResourceTypeRegion?.region?.urlName || '';
  const localResourceType =
    resource?.localResourceTypeRegion?.localResourceType?.urlName || '';

  const basePath = pagePath.split(careTypeFromURL)[0];
  const path = modifyTrailingSlash(
    enableTrailingSlash,
    `${basePath}${labelToSlug(localResourceType)}/${labelToSlug(
      state
    )}/${labelToSlug(region)}`
  );
  return path;
};

const getCareTypePathForSitemap: TenantFunctions['getCareTypePathForSitemap'] =
  (router: NextRouter) => {
    const params = getCareTypeParamsForSitemap(router);

    const careType = kebabCase(params.careType);
    const state = kebabCase(params.state);
    const cityOrCounty = kebabCase(params.cityOrCounty);
    const rollupTypeName = getRollupForCareType(careType);

    return rollupTypeName !== careType
      ? `/${rollupTypeName}/${careType}/${state}/${cityOrCounty}`
      : `/${rollupTypeName}/${state}/${cityOrCounty}`;
  };

const getCareTypeParamsForSitemap: TenantFunctions['getCareTypeParamsForSitemap'] =
  (router: NextRouter) => {
    return {
      state: String(router?.query?.param1),
      cityOrCounty: String(router?.query?.param3),
      careType: String(router?.query?.param2),
    };
  };

const generateContentForStateSitemap: TenantFunctions['generateContentForStateSitemap'] =
  (filteredEntries: FilteredEntries[]) => {
    const content: StateDataResponse = {
      cities: {},
      counties: {},
    };

    for (const element of filteredEntries) {
      if (
        ['geo-city', 'geo-county'].includes(element?.type ? element.type : '')
      ) {
        const { urlPath, state, county, city, careType, type } = element;

        const newElement = { ...element };
        let pathArray = urlPath.split('/');

        const countyKey = county.toLowerCase().replace(/ /g, '-');
        const cityKey = city.toLowerCase().replace(/ /g, '-');

        if (type === 'geo-county') {
          if (!isArray(content.counties[countyKey])) {
            content.counties[countyKey] = [];
          }

          let urlPath = '/sitemap/' + state + '/' + careType + '/' + county;

          newElement.urlPath = urlPath.toLowerCase().replace(/ /g, '-');

          content.counties[countyKey].push(newElement);
        } else {
          if (!isArray(content.cities[cityKey])) {
            content.cities[cityKey] = [];
          }

          let urlPath = '/sitemap/' + state + '/' + careType + '/' + city;
          newElement.urlPath = urlPath.toLowerCase().replace(/ /g, '-');
          content.cities[cityKey].push(newElement);
        }
      }
    }
    return content;
  };

const getTrailingSlashURL: TenantFunctions['getTrailingSlashURL'] = (
  url: string,
  type: string = 'default'
): string => {
  const logic = {
    default: false,
  };

  return modifyTrailingSlash(logic[type], url);
};

const getReviewPath: TenantFunctions['getReviewPath'] = ({
  rollupType,
  state,
  city,
  slug,
  url,
}) => {
  return (
    url ||
    buildProviderUrlWithRollup({
      rollupType,
      state,
      city,
      slug,
    })
  ).toLowerCase();
};

const getCountyForSearch: TenantFunctions['getCountyForSearch'] = (
  county: string
) => {
  return county;
};
