import { IconType } from 'react-icons';
import { BsClipboardPlus, BsFillPersonLinesFill } from 'react-icons/bs';
import { RiCarFill, RiPsychotherapyFill, RiTvFill } from 'react-icons/ri';
import { GiHealthNormal, GiFlowerPot } from 'react-icons/gi';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaAmbulance,
  FaSwimmer,
  FaBuilding,
  FaUserFriends,
  FaMoneyBill,
  FaPaintBrush,
  FaSuitcase,
  FaCouch,
  FaBookmark,
  FaShoppingCart,
  FaUserNurse,
  FaBath,
  FaHome,
} from 'react-icons/fa';
import { TbLicense, TbCertificate } from 'react-icons/tb';
import { IoIosFitness, IoMdNutrition, IoIosPeople } from 'react-icons/io';
import {
  MdAccessible,
  MdAddShoppingCart,
  MdAirplanemodeActive,
  MdArrowForward,
  MdAssignmentTurnedIn,
  MdBuild,
  MdCardTravel,
  MdChat,
  MdChatBubble,
  MdChecklistRtl,
  MdCreditCard,
  MdDirectionsBike,
  MdDirectionsBus,
  MdDirectionsCarFilled,
  MdDirectionsSubway,
  MdDirectionsWalk,
  MdEdit,
  MdEmojiPeople,
  MdEventSeat,
  MdFace,
  MdFavorite,
  MdHail,
  MdLightbulb,
  MdLocalBar,
  MdLocalCafe,
  MdLocalDining,
  MdLocalFlorist,
  MdLocalGasStation,
  MdLocalHospital,
  MdLocalHotel,
  MdLocalParking,
  MdLocationSearching,
  MdLogin,
  MdMedication,
  MdMovie,
  MdOpenInNew,
  MdOtherHouses,
  MdOutlineAttachMoney,
  MdOutlineBed,
  MdOutlinedFlag,
  MdOutlineViewList,
  MdPark,
  MdPets,
  MdPhone,
  MdPushPin,
  MdSavings,
  MdSearch,
  MdShield,
  MdTheaterComedy,
  MdVerifiedUser,
  MdVolunteerActivism,
  MdWaves,
  MdWbTwilight,
  MdWc,
  MdWeekend,
  MdWheelchairPickup,
  MdHomeWork,
  MdLuggage,
  MdCleaningServices,
  MdSecurity,
  MdFastfood,
  MdLanguage,
  MdKitchen,
  MdMedicalServices,
  MdCheck,
  MdArrowBack,
  MdLogout,
  MdContactSupport,
  MdStarBorder,
  MdSupportAgent,
} from 'react-icons/md';

export const STRING_TO_ICON_COMPONENT = {
  MdEdit: <MdEdit />,
  MdAccessible: <MdAccessible />,
  MdAddShoppingCart: <MdAddShoppingCart />,
  MdBuild: <MdBuild />,
  MdCardTravel: <MdCardTravel />,
  MdChatBubble: <MdChatBubble />,
  MdChat: <MdChat />,
  MdEventSeat: <MdEventSeat />,
  MdFace: <MdFace />,
  MdFavorite: <MdFavorite />,
  MdLightbulb: <MdLightbulb />,
  MdPets: <MdPets />,
  MdSavings: <MdSavings />,
  MdMovie: <MdMovie />,
  MdOutlinedFlag: <MdOutlinedFlag />,
  MdPushPin: <MdPushPin />,
  MdShield: <MdShield />,
  MdWaves: <MdWaves />,
  MdWeekend: <MdWeekend />,
  MdAirplanemodeActive: <MdAirplanemodeActive />,
  MdDirectionsBike: <MdDirectionsBike />,
  MdDirectionsSubway: <MdDirectionsSubway />,
  MdDirectionsWalk: <MdDirectionsWalk />,
  MdDirectionsCarFilled: <MdDirectionsCarFilled />,
  MdDirectionsBus: <MdDirectionsBus />,
  MdHail: <MdHail />,
  MdLocalBar: <MdLocalBar />,
  MdLocalCafe: <MdLocalCafe />,
  MdLocalDining: <MdLocalDining />,
  MdLocalFlorist: <MdLocalFlorist />,
  MdLocalGasStation: <MdLocalGasStation />,
  MdLocalHospital: <MdLocalHospital />,
  MdLocalHotel: <MdLocalHotel />,
  MdLocalParking: <MdLocalParking />,
  MdLocationSearching: <MdLocationSearching />,
  MdPark: <MdPark />,
  MdVolunteerActivism: <MdVolunteerActivism />,
  MdTheaterComedy: <MdTheaterComedy />,
  MdPhone: <MdPhone />,
  MdLogin: <MdLogin />,
  FaFacebookF: <FaFacebookF />,
  FaTwitter: <FaTwitter />,
  FaLinkedinIn: <FaLinkedinIn />,
  MdSearch: <MdSearch />,
  MdWheelchairPickup: <MdWheelchairPickup />,
  MdAssignmentTurnedIn: <MdAssignmentTurnedIn />,
  MdOpenInNew: <MdOpenInNew />,
  MdOtherHouses: <MdOtherHouses />,
  MdMedication: <MdMedication />,
  MdWbTwilight: <MdWbTwilight />,
  MdChecklistRtl: <MdChecklistRtl />,
  MdEmojiPeople: <MdEmojiPeople />,
  MdWc: <MdWc />,
  MdArrowBack: <MdArrowBack />,
  MdArrowForward: <MdArrowForward />,
  MdOutlineAttachMoney: <MdOutlineAttachMoney />,
  MdOutlineBed: <MdOutlineBed />,
  MdCreditCard: <MdCreditCard />,
  MdVerifiedUser: <MdVerifiedUser />,
  MdHomeWork: <MdHomeWork />,
  MdLuggage: <MdLuggage />,
  MdCleaningServices: <MdCleaningServices />,
  MdSecurity: <MdSecurity />,
  MdFastfood: <MdFastfood />,
  MdKitchen: <MdKitchen />,
  MdOutlineViewList: <MdOutlineViewList />,
  BsClipboardPlus: <BsClipboardPlus />,
  MdLanguage: <MdLanguage />,
  BsFillPersonLinesFill: <BsFillPersonLinesFill />,
  RiCarFill: <RiCarFill />,
  RiPsychotherapyFill: <RiPsychotherapyFill />,
  RiTvFill: <RiTvFill />,
  GiHealthNormal: <GiHealthNormal />,
  GiFlowerPot: <GiFlowerPot />,
  FaAmbulance: <FaAmbulance />,
  FaSwimmer: <FaSwimmer />,
  FaBuilding: <FaBuilding />,
  FaUserFriends: <FaUserFriends />,
  FaMoneyBill: <FaMoneyBill />,
  FaPaintBrush: <FaPaintBrush />,
  FaSuitcase: <FaSuitcase />,
  FaCouch: <FaCouch />,
  FaBookmark: <FaBookmark />,
  FaShoppingCart: <FaShoppingCart />,
  FaUserNurse: <FaUserNurse />,
  FaHome: <FaHome />,
  FaBath: <FaBath />,
  TbLicense: <TbLicense />,
  TbCertificate: <TbCertificate />,
  IoIosFitness: <IoIosFitness />,
  IoMdNutrition: <IoMdNutrition />,
  IoIosPeople: <IoIosPeople />,
  MdMedicalServices: <MdMedicalServices />,
  MdCheck: <MdCheck />,
  MdLogout: <MdLogout />,
  MdContactSupport: <MdContactSupport />,
  MdStarBorder: <MdStarBorder />,
  MdSupportAgent: <MdSupportAgent />,
};
export type StringToIconKeys = keyof typeof STRING_TO_ICON_COMPONENT;

export const STRING_TO_ICON_CLASS: { [key in StringToIconKeys]: IconType } = {
  MdEdit,
  MdAccessible,
  MdAddShoppingCart,
  MdBuild,
  MdCardTravel,
  MdChatBubble,
  MdChat,
  MdEventSeat,
  MdFace,
  MdFavorite,
  MdLightbulb,
  MdPets,
  MdSavings,
  MdMovie,
  MdOutlinedFlag,
  MdPushPin,
  MdShield,
  MdWaves,
  MdWeekend,
  MdAirplanemodeActive,
  MdDirectionsBike,
  MdDirectionsSubway,
  MdDirectionsWalk,
  MdDirectionsCarFilled,
  MdDirectionsBus,
  MdHail,
  MdLocalBar,
  MdLocalCafe,
  MdLocalDining,
  MdLocalFlorist,
  MdLocalGasStation,
  MdLocalHospital,
  MdLocalHotel,
  MdLocalParking,
  MdLocationSearching,
  MdPark,
  MdVolunteerActivism,
  MdTheaterComedy,
  MdPhone,
  MdLogin,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  MdSearch,
  MdWheelchairPickup,
  MdAssignmentTurnedIn,
  MdOtherHouses,
  MdMedication,
  MdWbTwilight,
  MdChecklistRtl,
  MdEmojiPeople,
  MdWc,
  MdArrowForward,
  MdArrowBack,
  MdOutlineBed,
  MdCreditCard,
  MdOutlineAttachMoney,
  MdOpenInNew,
  MdVerifiedUser,
  MdHomeWork,
  MdLuggage,
  MdCleaningServices,
  MdSecurity,
  MdFastfood,
  MdKitchen,
  MdOutlineViewList,
  BsClipboardPlus,
  MdLanguage,
  BsFillPersonLinesFill,
  RiCarFill,
  RiPsychotherapyFill,
  RiTvFill,
  GiHealthNormal,
  GiFlowerPot,
  FaAmbulance,
  FaSwimmer,
  FaBuilding,
  FaUserFriends,
  FaMoneyBill,
  FaPaintBrush,
  FaSuitcase,
  FaCouch,
  FaBookmark,
  FaShoppingCart,
  FaUserNurse,
  FaBath,
  FaHome,
  TbLicense,
  TbCertificate,
  IoIosFitness,
  IoMdNutrition,
  IoIosPeople,
  MdMedicalServices,
  MdCheck,
  MdLogout,
  MdContactSupport,
  MdStarBorder,
  MdSupportAgent,
};
