const amenityGroups: Record<string, string[]> = {
  costs: [
    'maximumCost',
    'minimumCost',
    'minimumMemoryCost',
    'allInclusiveRent',
    'rentPlusCareFees',
    'entryFee',
    'entryFeeCost',
    'feeStructureExtra',
  ],
  roomAndHousingOptions: [
    'oneBrApartments',
    'twoBrApartments',
    'threeBrApartments',
    'companionSuites',
    'studioApartments',
    'condosTownhomes',
    'detachedHomes',
    'housingExtra',
  ],
  diningOptions: [
    'inRoomKitchenette',
    'inRoomKitchens',
    'diningRoomSharedMeals',
    'roomServiceForMeals',
    'diningExtra',
  ],
  features: [
    'beautyBarberShop',
    'computerRoom',
    'residentTransportation',
    'coffeeShop',
    'partySpace',
    'golf',
    'generalStore',
    'hikingTrails',
    'tvRoom',
    'gardenAndPatio',
    'residentParking',
    'securityGate',
    'pool',
    'tennisCourts',
    'spa',
    'featuresExtra',
  ],
  cleaningServices: [
    'laundryForLinens',
    'privateCleaningServices',
    'housekeeping',
    'laundryForClothing',
  ],
  technologyAndEntertainment: [
    'cableInRooms',
    'internetEnabledRooms',
    'wifi',
    'technologyExtra',
  ],
  healthServices: [
    'skilledNursing',
    'hospiceCare',
    'medicationManagement',
    'physicalTherapy',
    'psychiatricCare',
    'nurseOnStaff',
    'pharma',
  ],
  activities: [
    'offsiteActivities',
    'music',
    'games',
    'stories',
    'religiousServices',
    'lecturesDiscussions',
    'bookClubs',
    'wiiSports',
    'taiChi',
    'fitnessRoom',
    'yoga',
    'gardening',
    'cooking',
    'artsAndCrafts',
    'activitiesExtra',
  ],
  financialGuidance: [
    'homeSaleAssistance',
    'vaBenefitsConsultation',
    'financingAdvice',
    'financialAid',
    'financialGuidanceExtra',
  ],
  affiliations: ['affiliationsExtra'],
  guestServices: ['guestParking', 'guestsAtMealtime', 'overnightGuests'],
  diet: [
    'diabetic',
    'kosher',
    'lowFat',
    'specialDiets',
    'lowSalt',
    'renal',
    'vegetarian',
    'dietExtra',
  ],
  languages: [
    'cantonese',
    'english',
    'farsi',
    'french',
    'german',
    'hebrew',
    'hindi',
    'italian',
    'japanese',
    'korean',
    'mandarin',
    'russian',
    'americanSign',
    'spanish',
    'tagalog',
    'vietnamese',
    'languagesExtra',
  ],
  medicare: [
    'starHealth',
    'starOverall',
    'starQuality',
    'starStaff',
    'medicareProviderNumber',
    'ownershipType',
    'medicareUpdatedAt',
    'offersNursingCareServices',
    'offersPhysicalTherapyServices',
    'offersOccupationalTherapyServices',
    'offersSpeechPathologyServices',
    'offersMedicalSocialServices',
    'offersHomeHealthAideServices',
    'dateCertified',
  ],
  medicareHhcaQuality: [
    'frequencyBeginCare',
    'frequencyDrugTeaching',
    'frequencyFallCheck',
    'frequencyDepressionCheck',
    'frequencyFluShot',
    'frequencyPneumoniaShot',
    'frequencyDiabetesFootCare',
    'frequencyPainMoving',
    'frequencyAdmittedToHospital',
    'frequencyUrgentCareNotReadmitted',
    'frequencyReadmitted',
    'frequencyHospitalRecentVisitCareNotReadmitted',
    'frequencyHospitalCareNotReadmitted',
  ],
  medicareHhcaImprovement: [
    'improvementWalkingOrMovingAround',
    'improvementGettingInAndOutOfBed',
    'improvementBathing',
    'improvementBreathing',
    'improvementWounds',
    'improvementMedicationCompliance',
  ],
  rates: ['overnightRate', 'hourlyRate', 'liveInRate'],
  generalInformation: [
    'residentCapacity',
    'staffPatientRatio',
    'respiteCare',
    'minimumAge',
    'petsAllowed',
  ],
  nurses: ['nursesExtra'],
  specialComment: ['specialCommentExtra'],
  agencyType: ['referralAgency', 'fullServiceAgency', 'agencyExtra'],
  rangeOfServices: [
    'companionCare',
    'personalCareAssistants',
    'inHomeHealth',
    'physicalTherapy',
    'occupationalTherapy',
    'rangeOfServicesExtra',
  ],
  typesOfCare: [
    'transportation',
    'doctorVisitCompanionship',
    'lightHousekeeping',
    'transferAssistance',
    'dressingAndGrooming',
    'bathingAndToileting',
    'exercise',
    'financesBookkeeping',
    'homeMaintenance',
    'bloodWorkAndOtherMedicalCare',
    'walkingWheelchairAssistance',
    'memoryCare',
    'companionship',
    'mealPreparation',
    'errandAndGroceryAssistance',
  ],
  staff: ['maleCaregivers', 'noMaleCaregivers'],
  travel: ['travelDetails'],
  screening: [
    'inPersonInterviews',
    'referenceChecks',
    'immunizations',
    'drivingRecordCheck',
    'creditCheck',
    'residencyConfirmation',
    'backgroundChecks',
    'drugScreening',
    'sexOffenderCheck',
  ],
  training: ['continualTraining'],
  trainingAreas: [
    'patientTransfers',
    'stressManagement',
    'familyCommunication',
    'transitionIssues',
    'memoryCareTraining',
    'ethics',
    'validationTechniques',
    'trainingAreasExtra',
  ],
  paymentOptions: ['insurance', 'creditCard', 'check', 'paymentOptionsExtra'],
  paymentDetails: ['payrollProvidedForCaregivers', 'clients_handle_payroll'],
  bondedInsured: ['bondedAndInsured', 'notBondedAndInsured'],
  licensesHeld: ['licenses', 'stateLicenses'],
};

export default amenityGroups;
