import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/button';
import { ComponentWithAs } from '@chakra-ui/system';
import { StringToIconKeys } from '@components/RenderIcon';
import { getIcon } from '@utils/buttons';
import { filterNonReactAttributes } from '@utils/filterNonReactAttributes';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
export interface ButtonProps
  extends Omit<ChakraButtonProps, 'leftIcon' | 'rightIcon'> {
  leftIcon?: StringToIconKeys | React.ReactElement;
  rightIcon?: StringToIconKeys | React.ReactElement;
  elementName?: ElementNames;
  elementType?: ElementTypes;
  elementAction: ElementActions;
  destinationUrl?: string;
  query?: {
    locationId: string;
    queryId: string;
    listId: string;
  };
}

const Button: ComponentWithAs<'button', ButtonProps> = ({
  children,
  leftIcon,
  rightIcon,
  elementName = ElementNames.GENERIC_BUTTON,
  elementType = ElementTypes.BUTTON,
  elementAction,
  destinationUrl = '',
  query,
  ...rest
}) => {
  const elementClicked = useElementClicked();
  const leftIconComponent =
    typeof leftIcon === 'string' ? getIcon(leftIcon) : leftIcon;
  const rightIconComponent =
    typeof rightIcon === 'string' ? getIcon(rightIcon) : rightIcon;
  const color = rest.colorScheme;
  const textColor = rest.textColor || 'white';
  const filteredProps = filterNonReactAttributes(rest);
  return (
    <ChakraButton
      leftIcon={leftIconComponent}
      rightIcon={rightIconComponent}
      {...filteredProps}
      onClick={() => {
        if (rest.onClick) rest.onClick();
        elementClicked({
          element: {
            type: elementType,
            action: elementAction,
            name: elementName,
            text: children?.toString() ?? '',
            color: color ?? '',
            textColor: textColor,
          },
          destinationUrl: destinationUrl,
          query: query,
        });
      }}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
