export const isACareType = (string: string): boolean => {
  return [
    '55-plus',
    '55-communities',
    '55-plus-communities',
    'adult-day-care',
    'assisted-living',
    'care-homes',
    'continuing-care',
    'continuing-care-retirement-communities',
    'geriatric-care-managers',
    'home-care',
    'home-health-agencies',
    'hospices',
    'in-home-care',
    'independent-living',
    'memory-care',
    'memory-care-facilities',
    'nursing-homes',
    'respite-care',
    'retirement-communities',
    'senior-care',
    'senior-living',
  ].includes(string?.toString());
};

export default isACareType;
