import {
  StringToIconKeys,
  STRING_TO_ICON_COMPONENT,
} from '@components/RenderIcon';

export const DEFAULT_CTA_BEHAVIOR = '_self';
export const DEFAULT_BUTTON_ALIGNMENT = 'center';

export const getIcon = (iconName: StringToIconKeys) => {
  if (!iconName) return undefined;
  return STRING_TO_ICON_COMPONENT[iconName];
};
