import { useEffect, useState } from 'react';
import CookieStorage from 'utils/cookieStorage';

export default function useCookieStorageValue(key) {
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    try {
      if (CookieStorage.enabled) {
        const subId = CookieStorage.observe(key, (val, oldVal) => {
          setValue(val);
        });

        return () => {
          CookieStorage.unobserve(subId);
        };
      }
    } catch (err) {
      console.error('cookieStorage is not available');
    }
  }, [key]);

  return value;
}
