import { Box, Heading } from '@chakra-ui/layout';
import {
  HStack,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import HtmlToReact from '@components/HtmlToReact';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import StoryContext from '~/contexts/StoryContext';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
import { VisuallyHidden } from '@chakra-ui/visually-hidden';

interface Props {
  advertisingDisclosure: string;
  displayOnDesktop?: boolean;
  hasHiddenDisclosure?: boolean;
}

const AdvertisingDisclosure: React.FC<Props> = ({
  advertisingDisclosure,
  displayOnDesktop,
  hasHiddenDisclosure = true,
}) => {
  const storyContext = useContext(StoryContext);
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const displayAdvertisingDisclosure =
    advertisingDisclosure && storyContext?.hasAdvertisingDisclosure;

  const getAdvertisingDisclosure = () => {
    return (
      advertisingDisclosure && HtmlToReact({ html: advertisingDisclosure })
    );
  };

  if (!displayAdvertisingDisclosure) return <></>;

  const advertisingDisclosureTooltip = (
    <Tooltip
      hasArrow
      label={getAdvertisingDisclosure()}
      isOpen={isOpen}
      bg="primary.700"
      padding={2}
      width={320}
    >
      <Box>
        <HStack spacing={2}>
          <Heading
            fontSize="sm"
            onClick={onToggle}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            color="primary.600"
            cursor="pointer"
          >
            Advertising Disclosure
          </Heading>
        </HStack>
        {hasHiddenDisclosure && (
          <VisuallyHidden>{getAdvertisingDisclosure()}</VisuallyHidden>
        )}
      </Box>
    </Tooltip>
  );

  if (isDesktop && displayOnDesktop) return advertisingDisclosureTooltip;
  if (!isDesktop) return <Container>{advertisingDisclosureTooltip}</Container>;
  return <></>;
};

export default AdvertisingDisclosure;
