import LAYOUT from '@components/Layouts/layoutConstants';
import Container from '@components/LayoutStructure/Container';
import { HeaderNavigation, Visibility } from '@components/Navigation';
import dynamic from 'next/dynamic';

const HeaderNonScrolled = dynamic(
  () => import('@components/Navigation/Header/HeaderNonScrolled')
);
const HeaderProviderScrolled = dynamic(
  () => import('./HeaderProviderScrolled')
);
const HeaderGeoScrolled = dynamic(() => import('./HeaderGeoScrolled'));

export interface HeaderScrolledProps {
  logo: HeaderNavigation['logo'];
  main: HeaderNavigation['main'];
  secondary: HeaderNavigation['secondary'];
  metadata: HeaderNavigation['metadata'];
}

interface HeaderProps {
  logo: HeaderNavigation['logo'];
  main: HeaderNavigation['main'];
  secondary: HeaderNavigation['secondary'];
  mainMenuPosition?: string;
  showShadow?: boolean;
  isAccountEnabled?: boolean;
  metadata: HeaderNavigation['metadata'];
  sameBackgroundAsBody?: boolean;
  isScrolledHeader?: boolean;
  isProviderPage?: boolean;
  isGeoPage?: boolean;
  isSemPage?: boolean;
}

export const itemDisplay = (
  visibility: Visibility | null,
  display?: string
): { [key: string]: string } => {
  const displayValue = display ?? 'block';
  switch (visibility) {
    case 'mobile':
      return {
        base: displayValue,
        md: 'none',
      };
    case 'desktop':
      return {
        base: 'none',
        lg: displayValue,
      };
    case 'tablet':
      return {
        base: 'none',
        md: displayValue,
        lg: 'none',
      };
    case 'tabletPlus':
      return {
        base: 'none',
        md: displayValue,
      };
    case 'tabletMinus':
      return {
        base: displayValue,
        lg: 'none',
      };
    default:
      return {
        base: displayValue,
        lg: displayValue,
      };
  }
};

const Header: React.FC<HeaderProps> = ({
  logo,
  main,
  secondary,
  mainMenuPosition,
  isAccountEnabled = false,
  showShadow = true,
  metadata,
  sameBackgroundAsBody = false,
  isScrolledHeader = false,
  isProviderPage = false,
  isGeoPage = false,
  isSemPage = false,
}) => {
  const fixed = !isSemPage;
  return (
    <Container
      ignoreMaxWidth
      as="header"
      display="flex"
      width="100%"
      backgroundColor={sameBackgroundAsBody ? 'transparent' : 'white'}
      height={{
        base: 'auto',
        xl: LAYOUT.HEADER_DESKTOP_HEIGHT,
      }}
      position={fixed ? 'fixed' : undefined}
      left="0"
      right="0"
      top="0"
      boxShadow={showShadow ? 'base' : undefined}
      zIndex="sticky"
      py={{ base: 2, xl: 0 }}
    >
      {isGeoPage && isScrolledHeader && (
        <HeaderGeoScrolled
          logo={logo}
          main={main}
          secondary={secondary}
          metadata={metadata}
        />
      )}
      {isProviderPage && isScrolledHeader && (
        <HeaderProviderScrolled
          logo={logo}
          main={main}
          secondary={secondary}
          metadata={metadata}
        />
      )}
      {!isScrolledHeader && (
        <HeaderNonScrolled
          logo={logo}
          main={main}
          secondary={secondary}
          metadata={metadata}
          mainMenuPosition={mainMenuPosition}
          isAccountEnabled={isSemPage ? false : isAccountEnabled}
        />
      )}
    </Container>
  );
};

export default Header;
