import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const sm = definePartsStyle({
  container: {
    px: '10px',
    py: '1px',
    fontSize: 'xs',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
});

export const tagTheme = defineMultiStyleConfig({
  defaultProps: {
    size: 'sm',
  },
  sizes: { sm },
});
