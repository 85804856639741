import Image, { ImageProps } from 'next/image';
import { MagnoliaImage } from '~/types/Magnolia';
import { Box } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
export interface StoryImageProps extends Omit<ImageProps, 'src' | 'alt'> {
  image?: MagnoliaImage;
  switchable?: {
    field: 'damChooser' | 'externalSource' | string;
    image?: MagnoliaImage;
    imageAlt?: string;
    imageUrl?: string;
  };
  displayAsBackground: boolean;
  desktopHeight?: string;
  mobileHeight?: string;
  borderRadius?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  borderBottomRightRadius?: string;
  borderBottomLeftRadius?: string;
  withContainer?: boolean;
  containerMarginBottom?: string;
  maxW?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  backgroundSize?: 'contain' | 'cover';
  scrapedImageStyle?: {
    float?: 'left' | 'right' | undefined;
    margin?: string;
    width?: string;
  };
}

const StoryImage: React.FC<StoryImageProps> = ({
  image,
  switchable,
  displayAsBackground,
  desktopHeight = '307px',
  mobileHeight = '216px',
  borderRadius = '6px',
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
  withContainer = true,
  containerMarginBottom = '32px',
  maxW = 'xl',
  backgroundSize = 'cover',
  sizes = '',
  scrapedImageStyle = {},
}) => {
  if (!image && !switchable) {
    return null;
  }

  const baseUrl = process.env.NEXT_PUBLIC_MGNL_HOST || null;

  if (!baseUrl) {
    return null;
  }

  let imageUrl = '';
  let alt = '';
  let width = 600;
  let height = 481;

  if (image) {
    imageUrl = baseUrl + '/dam/' + image['@id'] + image['@path'];
    alt = image?.metadata?.caption || '';
    width = image?.metadata?.width || 0;
    height = image?.metadata?.height || 0;
  }

  if (switchable) {
    switch (switchable.field) {
      case 'damChooser':
        if (switchable?.image) {
          imageUrl =
            baseUrl +
            '/dam/' +
            switchable?.image['@id'] +
            switchable?.image['@path'];
          alt = switchable?.image?.metadata?.caption || '';
          width = switchable?.image?.metadata?.width || 0;
          height = switchable?.image?.metadata?.height || 0;
        }
        break;

      case 'externalSource':
        if (switchable?.imageUrl) {
          imageUrl = switchable?.imageUrl;
          alt = switchable?.imageAlt ?? '';
        } else {
          return null;
        }

        break;
      default:
        break;
    }
  }

  const bgImage = {
    backgroundImage: `url(${imageUrl})`,
  };

  const imageStyle = {
    display: displayAsBackground ? 'none' : '',
    marginBottom: containerMarginBottom ? containerMarginBottom : '16px',
    ...scrapedImageStyle,
  };

  const imageComponent = (
    <Image
      src={imageUrl}
      alt={alt}
      width={width}
      height={height}
      style={imageStyle}
      sizes={sizes}
    />
  );

  const getBackround = () => {
    if (
      borderTopLeftRadius ||
      borderTopRightRadius ||
      borderBottomRightRadius ||
      borderBottomLeftRadius
    ) {
      return (
        <Box
          marginBottom={withContainer ? '' : '16px'}
          height={[
            mobileHeight,
            mobileHeight,
            mobileHeight,
            desktopHeight,
            desktopHeight,
            desktopHeight,
          ]}
          position="relative"
          borderRadius={borderRadius}
          borderTopLeftRadius={borderTopLeftRadius}
          borderTopRightRadius={borderTopRightRadius}
          borderBottomRightRadius={borderBottomRightRadius}
          borderBottomLeftRadius={borderBottomLeftRadius}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize={backgroundSize}
          {...bgImage}
        >
          {imageComponent}
        </Box>
      );
    }

    return (
      <Box
        marginBottom={withContainer ? '' : '16px'}
        height={[
          mobileHeight,
          mobileHeight,
          mobileHeight,
          desktopHeight,
          desktopHeight,
          desktopHeight,
        ]}
        position="relative"
        borderRadius={borderRadius}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={backgroundSize}
        {...bgImage}
      >
        {imageComponent}
      </Box>
    );
  };

  if (displayAsBackground && withContainer)
    return (
      <Container maxW={`container.${maxW}`} pt={0} mb={containerMarginBottom}>
        {getBackround()}
      </Container>
    );

  if (withContainer) {
    return (
      <Container maxW={`container.${maxW}`} pt={0} mb={containerMarginBottom}>
        {imageComponent}
      </Container>
    );
  }

  if (displayAsBackground) return getBackround();

  return imageComponent;
};

export default StoryImage;
