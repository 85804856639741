import { MagnoliaImage, Metadata } from 'types/Magnolia';
import { InquiryFormProps } from '../InquiryForm/InquiryForm';
import { Navigation } from '@services/graphql/navigation';

export { CTAAction as FormAction } from '~/components/InquiryForm/InquiryForm.types';
export { RollUpType } from '~/types/RollUpType';

export type ChatOverlayPopupType = (typeof CHAT_OVERLAY_TYPES)[number];
export const CHAT_OVERLAY_TYPES = [
  'bannerStickToBottom',
  'floatingCTA',
] as const;

export type NavigationItemType = (typeof NAVIGATION_ITEM_TYPES)[number];
export const NAVIGATION_ITEM_TYPES = [
  'link',
  'button',
  'search',
  'predictive_search',
  'jump_links',
  'whoAreYouSearchingFor',
] as const;

type NavigationItemPosition = (typeof NAVIGATION_ITEM_POSITION_TYPES)[number];
export const NAVIGATION_ITEM_POSITION_TYPES = ['main', 'secondary'] as const;

export type Visibility = (typeof VISIBILITY_TYPES)[number];
export const VISIBILITY_TYPES = [
  'always',
  'mobile',
  'desktop',
  'tablet',
  'tabletPlus',
  'tabletMinus',
] as const;

export interface NavigationItem {
  type: NavigationItemType;
  position: NavigationItemPosition;
  visibility: Visibility;
  mobile: boolean;
  desktop: boolean;
  text: string;
  textColor?: string;
  textColorRange?: string;
  secondText?: string;
  secondTextColor?: string;
  secondTextColorRange?: string;
  icon: string;
  url: string;
  children: Array<NavigationSubItem>;
  id?: string;
  metadata?: Metadata;
}

interface NavigationSubItem {
  text: string;
  url: string;
}

export interface NavigationLogo {
  mobileLogo: (MagnoliaImage & { link: string; caption: string }) | null;
  logo: (MagnoliaImage & { link: string; caption: string }) | null;
  logoUrl: string;
  logoAlt: string;
  logoMaxWidth: string;
  mobileLogoMaxWidth: string;
}

export type MenuItemInquiryForm =
  | {
      enabled: false;
    }
  | {
      enabled: true;
      ctaAction: InquiryFormProps['ctaAction'];
      ctaText: InquiryFormProps['ctaText'] | null;
      rollUpType: InquiryFormProps['rollUpType'] | null;
      title: InquiryFormProps['title'];
      thankYouMessage: InquiryFormProps['thankYouMessage'];
      thankYouMessageColor: InquiryFormProps['thankYouMessageColor'];
      legalDisclosure: InquiryFormProps['legalDisclosure'];
    };

export type HeaderNavigation = Pick<
  Exclude<Navigation['header'], null>,
  | 'intro'
  | 'logo'
  | 'main'
  | 'secondary'
  | 'metadata'
  | 'mainMenuPosition'
  | 'showShadow'
>;

export type FooterNavigation = Pick<
  Exclude<Navigation['footer'], null>,
  | 'intro'
  | 'logo'
  | 'main'
  | 'secondary'
  | 'metadata'
  | 'bgColor'
  | 'bgColorRange'
  | 'chatOverlay'
>;
