/**
 * English Translations
 */

const en = {
  resource: {
    amenities: {
      petsAllowed: 'Pets',
      costs: 'Costs',
      roomAndHousingOptions: 'Room and housing options',
      diningOptions: 'Dining options',
      features: 'Features',
      cleaningServices: 'Cleaning services',
      technologyAndEntertainment: 'Technology and entertainment',
      healthServices: 'Health services',
      activities: 'Activities',
      financialGuidance: 'Financial guidance',
      affiliations: 'Affiliations',
      guestServices: 'Guest services',
      diet: 'Diet',
      languages: 'Languages',
      medicare: 'Medicare',
      rates: 'Rates',
      generalInformation: 'General',
      nurses: 'Nurses',
      specialComment: 'Special comment',
      agencyType: 'Agency Type',
      rangeOfServices: 'Range of services',
      typesOfCare: 'Types of care',
      staff: 'Staff',
      travel: 'Travel',
      screening: 'Screening',
      training: 'Training',
      trainingAreas: 'Training Areas',
      paymentOptions: 'Payment options',
      paymentDetails: 'Payment details',
      bondedInsured: 'Bonded/insured',
      licensesHeld: 'Licenses',
      oneBrApartments: '1-bedroom apartments',
      twoBrApartments: '2-bedroom apartments',
      threeBrApartments: '3-bedroom apartments',
      medicareHhcaQuality: 'Quality of Service',
      medicareHhcaImprovement: 'Patient Improvement',
    } as Record<string, string>,
  },
  careTypes: {
    'independent-living': 'Independent Living',
    'assisted-living': 'Assisted Living',
    'memory-care-facilities': 'Memory Care',
    '55-plus-communities': '55+ Communities',
    'nursing-homes': 'Nursing Homes',
    'adult-day-care': 'Adult Day Care',
    'continuing-care-retirement-communities':
      'Continuing Care Retirement Communities',
    'home-care': 'Home Care',
    product: 'Product',
    services: 'Services',
  },
};

export default en;
