import { Page } from '~/types/Magnolia';

export interface FilterComponentProps {
  page: Page;
  componentName: string;
}

const getComponentDataFromPage = ({
  page,
  componentName,
}: FilterComponentProps) => {
  return (
    page?.main &&
    page?.main['@nodes']
      ?.map((value) => {
        if (
          page.main[value]['mgnl:template'] &&
          page.main[value]['mgnl:template'] === componentName
        ) {
          return page.main[value].data;
        }
      })
      .filter((value) => value)
  );
};
export default getComponentDataFromPage;
