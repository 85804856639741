import { sessionStarted } from './eventContracts';
import segmentEvents from '~/config/segment-events';

export function trackSessionStarted(pageSessionId: string, sessionId: string) {
  const sessionContract: sessionStarted = {
    page_session_id: pageSessionId,
    session_id: sessionId,
  };
  window.tracking.track(segmentEvents.SESSION_STARTED, sessionContract);
}
