import Button from '@components/Button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Stack } from '@chakra-ui/layout';
import amenityGroups from '~/config/amenity-groups';
import { LegacyAmenities } from '~/contexts/Provider';
import useTranslation from '~/hooks/use-translation';
import startCase from 'lodash/startCase';
import { HeadingElements } from '~/@types/heading';
import Heading from '@components/Heading';
import { useContext, useRef } from 'react';
import SiteContext from '~/contexts/SiteContext';
import ProviderDetailsAmenitiesSectionLegacy from './ProviderDetailsAmenitiesSectionLegacy';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { useScrollToAnchor } from '@hooks/useScrollToAnchor';
import { formatMoney } from '../utils';

type Group = {
  label: string;
  amenities: Array<string>;
};

interface Props {
  amenities: LegacyAmenities;
  heading: string;
  itemsToShow: number;
  starHealth: number | null;
  starOverall: number | null;
  starQuality: number | null;
  starStaff: number | null;
  headingElement?: HeadingElements;
}

const ProviderAmenitiesOffers: React.FC<Props> = ({
  amenities,
  heading,
  itemsToShow,
  headingElement = 'h2',
}) => {
  const targetRef = useRef<HTMLHeadingElement>(null);
  const { isOpen: viewAll, onToggle: toggleViewAll } = useDisclosure({
    defaultIsOpen: itemsToShow === -1,
  });
  const { t } = useTranslation();
  const { groups } = buildGroups(amenities, t);
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const columns: Array<Array<Group>> = [[], [], []];
  let i = 0;

  const filteredAmenities = groups.filter(({ amenities, label }) => {
    const shouldFilterOutGroup =
      domain === 'caring.com' && label === 'Medicare';
    return amenities.length > 0 && !shouldFilterOutGroup;
  });

  // workaround to fix issue with jumplink in chrome on redirects
  useScrollToAnchor(targetRef, heading, filteredAmenities.length === 0);

  if (filteredAmenities.length === 0) return null;
  filteredAmenities.forEach((group) => {
    columns[i].push(group);
    if (i < 2) i++;
    else i = 0;
  });

  const hasMultipleRows = columns.some((column) => column.length > 1);

  return (
    <Stack width="full">
      <Heading
        ref={targetRef}
        headingElement={headingElement}
        headingSize="lg"
        title={heading}
        withContainer={false}
      />
      <Box
        display="grid"
        gridTemplateColumns={{ lg: 'repeat(3, 1fr)', base: '1fr' }}
        gridGap={4}
      >
        {columns.map((column, index) => (
          <Stack key={index} spacing="0">
            {column.map(
              (group, index) =>
                (index === 0 || viewAll) && (
                  <ProviderDetailsAmenitiesSectionLegacy
                    key={index}
                    sectionLabel={group.label}
                    offeringListLegacy={group.amenities}
                    itemsToShow={viewAll ? -1 : itemsToShow}
                  />
                )
            )}
          </Stack>
        ))}
      </Box>

      {hasMultipleRows && (
        <Button
          onClick={toggleViewAll}
          colorScheme="primary"
          size="lg"
          variant="outline"
          elementAction={
            viewAll ? ElementActions.COLLAPSE : ElementActions.EXPAND
          }
          elementName={
            viewAll
              ? ElementNames.VIEW_FEWER_SERVICES
              : ElementNames.VIEW_ALL_SERVICES
          }
          elementType={ElementTypes.BUTTON}
        >
          {viewAll ? 'View fewer services' : 'View all services'}
        </Button>
      )}
    </Stack>
  );
};

export function buildGroups(
  amenities: LegacyAmenities,
  t: (key: string, fallback: string) => string
) {
  // Create a group array where each group consists of a label and an
  // amenities list.
  const groups: Array<Group> = [];
  Object.keys(amenityGroups).forEach((groupKey) => {
    const fallback = startCase(groupKey);
    // Label can be a translation or fallback to capitalization
    const label = t(`resource.amenities.${groupKey}`, fallback);
    // Visible amenities are only those that are not extra
    const visibleAmenities: Array<string> = [];
    amenityGroups[groupKey].forEach((amenityKey) => {
      const amenityExists = !!amenities[amenityKey];
      const isExtraAmenity = !!amenityKey.match(/extra$/i);
      const parsedAmenityKey = isExtraAmenity
        ? amenityKey.replace(/Extra$/i, '')
        : amenityKey;

      if (!amenityExists || !!amenityKey.match(/specialCommentExtra$/i)) return;

      if (typeof amenities[parsedAmenityKey] === 'string') {
        const amenity =
          groupKey !== 'costs'
            ? amenities[amenityKey]
            : formatMoney(amenities[amenityKey]);
        visibleAmenities.push(`${startCase(amenityKey)}: ${amenity}`);
      } else {
        if (isExtraAmenity) {
          visibleAmenities.push(
            t(
              `resource.amenities.${amenityKey}`,
              `${startCase(amenityKey)}: ${amenities[amenityKey]}`
            )
          );
        } else {
          visibleAmenities.push(
            t(`resource.amenities.${amenityKey}`, startCase(amenityKey))
          );
        }
      }
    });

    groups.push({ label, amenities: visibleAmenities });
  });

  return { groups };
}

export default ProviderAmenitiesOffers;
