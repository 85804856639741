export enum Display {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  FULL_PAGE_MODAL = 'full-page-modal',
  FIT_CONTENT_MODAL = 'fit-content-modal',
}

export enum CTAAction {
  DISPLAY_COST_DATA = 'display-cost-data',
  REQUEST_INFO = 'request-info',
}
