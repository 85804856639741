import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { QueryKey } from '@utils/queryKey';
import { useContext, useEffect, useState } from 'react';
import { ONE_DAY_IN_MILLISECONDS } from '~/constants';
import ProviderContext, { Provider } from '~/contexts/Provider';

export enum ProviderStatus {
  LOADING = 1,
  COMPLETE = 2,
  ERROR = 3,
}

type ProviderQuery = {
  providerId?: string;
};

const getProviderLocation = async ({
  queryKey,
}: QueryFunctionContext<[string, ProviderQuery]>): Promise<Provider> => {
  const [_key, { providerId }] = queryKey;

  if (!providerId) throw new Error('ProviderId is required!');

  const url = `/api/location/${providerId}`;

  const result = await fetch(url);
  return result.json();
};

export const useProviderFallback = (providerId?: string) => {
  const providerCtx = useContext(ProviderContext)?.provider || null;
  const [status, setStatus] = useState<ProviderStatus>(
    providerId ? ProviderStatus.LOADING : ProviderStatus.COMPLETE
  );
  const [provider, setProvider] = useState<Provider | null>(providerCtx);

  const { data, error } = useQuery({
    queryKey: [QueryKey.ProviderLocation, { providerId }],
    queryFn: getProviderLocation,
    enabled: !!providerId,
    staleTime: ONE_DAY_IN_MILLISECONDS,
    cacheTime: ONE_DAY_IN_MILLISECONDS,
  });

  useEffect(() => {
    if (data) {
      setProvider(data);
      setStatus(ProviderStatus.COMPLETE);
    } else if (error) {
      setStatus(ProviderStatus.ERROR);
    }
  }, [data, error]);

  return { provider, status };
};

export default useProviderFallback;
