import { Alert } from '@chakra-ui/alert';
import { Button as ChakraButton } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import Heading from '@components/Heading';
import { getColor } from '@utils/getColor';
import { isEmptyText } from '@utils/isEmptyText';
import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
import { headingSizes } from '~/config/headings';
import HtmlToReact from '@components/HtmlToReact';
import { useState } from 'react';
import { truncateText } from '@utils/truncateText';
import Button from '@components/Button';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
const LoadContentOnClientSide = dynamic(
  () => import('@components/LoadContentOnClientSide')
);

interface SectionProps {
  title?: string;
  titleColor?: string;
  titleColorRange?: string;
  headingElement?: HeadingElements;
  headingSize?: HeadingSizes | HeadingSizes[];
  alert?: string;
  alertStatus?: 'default' | 'info' | 'warning' | 'success' | 'error';
  richText?: string;
  richTextColor?: string;
  richTextColorRange?: string;
  richTextSize?: HeadingSizes | HeadingSizes[];
  buttonLabel?: string;
  buttonLink?: string;
  displayInlineBlock?: boolean;
  clientSideRendering?: boolean;
  textAlignment?: 'left' | 'center' | 'right';
  titleAlignment?: 'left' | 'center' | 'right';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'none';
  collapsible?: boolean;
  collapsibleDefaultState: 'expanded' | 'collapsed';
  collapsibleLength: number;
  openInNewTab?: boolean;
}

const Section = ({
  title,
  titleColor,
  titleColorRange,
  headingElement = 'h1',
  headingSize,
  alert,
  alertStatus = 'default',
  richText,
  richTextColor,
  richTextColorRange,
  richTextSize,
  buttonLabel,
  buttonLink,
  clientSideRendering,
  textAlignment = 'left',
  titleAlignment = 'left',
  maxWidth = 'none',
  collapsible = false,
  collapsibleDefaultState,
  collapsibleLength,
  displayInlineBlock = false,
  openInNewTab = false,
}: SectionProps): React.ReactElement => {
  const size = headingSize || headingSizes[headingElement];

  const elementClicked = useElementClicked();
  const [expanded, setExpanded] = useState(
    collapsible && collapsibleDefaultState === 'expanded'
  );

  let content = richText || '';
  const textIsEmpty = isEmptyText(content);

  if (
    collapsible &&
    !expanded &&
    !textIsEmpty &&
    content.length > collapsibleLength
  ) {
    const { textStart, textEnd } = truncateText(content, collapsibleLength);
    content = textStart + `... <div class="hidden">${textEnd}</div>`;
  }

  const parsedRichText =
    richText && !textIsEmpty ? HtmlToReact({ html: content }) : null;

  const onCLickLink = () => {
    buttonLink?.includes('tel:')
      ? elementClicked({
          element: {
            type: ElementTypes.TELEPHONE,
            action: ElementActions.PHONE_CALL,
            name: ElementNames.PHONE_CALL_BUTTON,
            text: buttonLink.replace('tel:', ''),
            color: 'primary',
            textColor: 'white',
          },
          destinationUrl: buttonLink,
        })
      : undefined;
  };

  const getMaxWidth = () => {
    if (maxWidth === 'none') return '100%';
    return maxWidth;
  };
  return (
    <Box
      maxW={getMaxWidth()}
      sx={
        displayInlineBlock
          ? {
              display: 'inline-block',
            }
          : {
              display: 'grid',
              gridTemplateRows: expanded ? '1fr' : '0fr',
              transition: 'grid-template-rows 500ms',
            }
      }
    >
      {title && (
        <Heading
          headingElement={headingElement}
          headingSize={size}
          title={title}
          withContainer={false}
          textAlign={titleAlignment}
          color={getColor(titleColor, titleColorRange)}
        />
      )}
      {alert && (
        <Alert
          status={alertStatus === 'default' ? undefined : alertStatus}
          borderRadius="5px"
          p="5"
          mt="8"
          mb="5"
          {...(alertStatus === 'default' && {
            colorScheme: 'white',
            border: '1px',
            borderColor: 'gray.300',
          })}
          dangerouslySetInnerHTML={{ __html: alert }}
        />
      )}
      {parsedRichText && (
        <Box
          className="magnolia-text"
          fontSize={richTextSize ? richTextSize : 'lg'}
          mt="2"
          textAlign={textAlignment}
          color={getColor(richTextColor, richTextColorRange)}
          sx={{
            '&>p:last-of-type': {
              display: 'inline',
            },
          }}
        >
          {!clientSideRendering && parsedRichText}
          {clientSideRendering && (
            <LoadContentOnClientSide content={parsedRichText} />
          )}
          {collapsible && content.length > collapsibleLength && (
            <Button
              colorScheme="caringBlue"
              variant="link"
              zIndex={10}
              onClick={() => {
                setExpanded((exp) => !exp);
              }}
              elementAction={
                expanded ? ElementActions.COLLAPSE : ElementActions.EXPAND
              }
              elementName={ElementNames.TOGGLE_EXPAND_SECTION}
              elementType={ElementTypes.BUTTON}
            >
              READ {expanded ? 'LESS' : 'MORE'}
            </Button>
          )}
        </Box>
      )}
      {buttonLabel && buttonLink && (
        <ChakraButton
          as="a"
          href={buttonLink}
          onClick={onCLickLink}
          colorScheme="primary"
          variant="outline"
          mt="7"
          target={openInNewTab ? '_blank' : undefined}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
        >
          {buttonLabel}
        </ChakraButton>
      )}
    </Box>
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(Section);
