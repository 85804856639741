// This implementation is based on the following documentation:
// https://docs.google.com/document/d/1sIyGholYYPVaU8kIFQfGTqS9oUGyi9fXifJlzwSixCI/edit
// but we should switch to the following:
// https://nextjs.org/docs/pages/building-your-application/optimizing/analytics

import { SessionData } from '@hooks/InitializeAnalytics';
import segmentEvents from '~/config/segment-events';

enum EntryTypes {
  CLS = 'layout-shift',
  FCP = 'paint',
  FP = 'paint',
  LCP = 'largest-contentful-paint',
  TTFB = 'navigation',
}

interface LayoutShiftAttribution {
  node?: Node;
  previousRect: DOMRectReadOnly;
  currentRect: DOMRectReadOnly;
}

interface LayoutShift extends PerformanceEntry {
  value: number;
  sources: LayoutShiftAttribution[];
  hadRecentInput: boolean;
}

const isEntryTypeSupported = (entryType: string) =>
  PerformanceObserver.supportedEntryTypes.includes(entryType);

function getFP() {
  if (isEntryTypeSupported(EntryTypes.FP)) {
    return new Promise((resolve, reject) => {
      const observer = new PerformanceObserver((list) => {
        const data: number[] = [];
        for (const entry of list.getEntriesByName('first-paint')) {
          data.push(entry.startTime);
        }
        resolve(data[data.length - 1]);
      });
      observer.observe({ type: EntryTypes.FP, buffered: true });
    });
  }
}

function getFCP() {
  if (isEntryTypeSupported(EntryTypes.FCP)) {
    return new Promise((resolve, reject) => {
      const observer = new PerformanceObserver((list) => {
        const data: number[] = [];
        for (const entry of list.getEntriesByName('first-contentful-paint')) {
          data.push(entry.startTime);
        }
        resolve(data[data.length - 1]);
      });
      observer.observe({ type: EntryTypes.FCP, buffered: true });
    });
  }
}

function getCLS() {
  if (isEntryTypeSupported(EntryTypes.CLS)) {
    return new Promise((resolve, reject) => {
      const observer = new PerformanceObserver((list) => {
        const data: number[] = [];
        for (const entry of list.getEntries() as LayoutShift[]) {
          // Count layout shifts without recent user input only
          if (!entry.hadRecentInput) {
            data.push(entry.value);
          }
        }
        const maxValue =
          data.length > 0
            ? data.reduce((prev, current) => (prev > current ? prev : current))
            : 0;
        resolve(maxValue);
      });
      observer.observe({ type: EntryTypes.CLS, buffered: true });
    });
  }
}

function getLCP() {
  if (isEntryTypeSupported(EntryTypes.LCP)) {
    return new Promise((resolve, reject) => {
      const observer = new PerformanceObserver((entryList) => {
        const data: number[] = [];
        for (const entry of entryList.getEntries()) {
          data.push(entry.startTime);
        }
        resolve(data[data.length - 1]);
      });
      observer.observe({ type: EntryTypes.LCP, buffered: true });
    });
  }
}

function getTTFB() {
  if (isEntryTypeSupported(EntryTypes.TTFB)) {
    return new Promise((resolve, reject) => {
      const observer = new PerformanceObserver((entryList) => {
        const [pageNav] = entryList.getEntriesByType('navigation');
        resolve((pageNav as PerformanceNavigationTiming).responseStart);
      });
      observer.observe({ type: EntryTypes.TTFB, buffered: true });
    });
  }
}

async function getCoreWebVitals() {
  return {
    CLS: await getCLS(),
    FCP: await getFCP(),
    FP: await getFP(),
    LCP: await getLCP(),
    TTFB: await getTTFB(),
  };
}

export async function trackCoreWebVitals(sessionProps: SessionData) {
  try {
    const { pageSessionId, sessionId } = sessionProps;
    const eventName = segmentEvents.CORE_WEB_VITALS;
    const eventData = {
      CWV: await getCoreWebVitals(),
      page_session_id: pageSessionId,
      session_id: sessionId,
    };

    window.tracking.track(eventName, eventData);
  } catch (error) {
    console.error('Error loading performance data:', error);
  }
}
