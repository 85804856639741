import { createContext, ComponentType } from 'react';
import { EditablePage as MagnoliaEditablePage } from '@magnolia/react-editor';
import { Page, PageProps, Story } from '~/types/Magnolia';
import { extractPages } from '@components/RelatedArticle/RelatedArticle';
import { validateAndProcessFAQs } from '@components/FAQ/FAQ';
import { Provider } from '~/contexts/Provider';
import { ProviderStatus } from '@hooks/use-provider-fallback';
import { validateProviderCareTypes } from '@components/ProviderCareTypes/ProviderCareTypes';
import { isAmenitiesEmpty } from '~/utils/amenities';
import { providerIsEnhancedAndNotSuppressed } from '~/utils/providers';
import orderBy from 'lodash/orderBy';
import * as htmlparser2 from 'htmlparser2';
import { Domain } from '~/types/Domains';

interface DynamicComponent {
  [key: string]: ComponentType<any>;
}

interface StandardComponent {
  [key: string]: (props: any) => JSX.Element | null;
}

export interface EditablePageProps {
  content: any;
  config?: {
    componentMappings: DynamicComponent | StandardComponent;
  };
  templateAnnotations: any;
}

export type PageContext = Omit<
  PageProps,
  | 'error'
  | 'pagePath'
  | 'redirect'
  | 'catalog'
  | 'provider'
  | 'story'
  | 'plasmic'
> | null;

export type StoryContext = Omit<Story, 'sitePath'> | null;

const nearbySections = [
  'communities',
  'nearbyCitiesSameType',
  'nearbyCountiesSameType',
  'otherCareTypesSameCity',
  'otherCareTypesNearbyCity',
];

export function EditablePage({
  content,
  config,
  templateAnnotations,
}: EditablePageProps) {
  return (
    <MagnoliaEditablePage
      content={content}
      config={config}
      templateAnnotations={templateAnnotations}
    />
  );
}

export const getPageHeadings = (
  pageContext: PageContext,
  storyContext: StoryContext,
  provider: Provider | null,
  status: ProviderStatus,
  domain: Domain | undefined,
  t: (key: string, fallback: string) => string
): Array<string> => {
  const titles: Array<string> = [];

  pageContext?.page.main &&
    pageContext?.page?.main['@nodes']?.map((value) => {
      if (
        pageContext.page.main[value]['mgnl:template'] ===
        'spa-lm:components/story'
      ) {
        const storyTitles = getStoryTitles(storyContext);
        if (storyTitles.length > 0) {
          storyTitles.forEach(function (title) {
            titles.push(title);
          });
        }
      }

      const isRelatedArticleComponent =
        pageContext.page.main[value]['mgnl:template'] ===
        'spa-lm:components/relatedArticles';

      const isTableOfContentsComponent =
        pageContext.page.main[value]['mgnl:template'] ===
        'spa-lm:components/tableOfContents';
      const hasRelatedArticles =
        isRelatedArticleComponent &&
        extractPages(
          pageContext.page.main[value].pages,
          pageContext.page.careType
        ).length > 0;

      if (
        pageContext.page.main[value].headingElement &&
        pageContext.page.main[value].headingElement === 'h2'
      ) {
        if (pageContext.page.main[value].title) {
          if (isRelatedArticleComponent) {
            hasRelatedArticles
              ? titles.push(pageContext.page.main[value].title)
              : null;
          } else if (isTableOfContentsComponent) {
            // do nothing
          } else {
            titles.push(pageContext.page.main[value].title);
          }
        }
      }

      for (let key of nearbySections) {
        const section = pageContext.page.main[value][key];
        if (
          section &&
          section.headingElement &&
          section.headingElement === 'h2'
        ) {
          titles.push(section.heading);
        }
      }

      const columns = pageContext.page.main[value];
      for (const columnName in columns) {
        if (columnName.startsWith('column')) {
          const columnTitles = getTitlesFromColumn(
            columns[columnName],
            pageContext,
            storyContext,
            provider,
            status,
            domain,
            t
          );
          if (columnTitles.length > 0) {
            columnTitles.forEach(function (heading) {
              titles.push(heading.title);
            });
          }
        }
      }
    });
  return titles || [];
};

export const validateComponentRender = (
  template: string,
  pageContext: PageContext,
  value: any,
  provider: Provider | null,
  status: ProviderStatus,
  domain: Domain | undefined,
  t: (key: string, fallback: string) => string,
  extraData?: Record<any, any> | null
) => {
  switch (template) {
    case 'spa-lm:components/relatedArticles':
      const hasRelatedArticles =
        extractPages(
          pageContext?.page?.main[value].pages,
          pageContext?.page?.careType
        ).length > 0;
      return hasRelatedArticles ? true : false;
    case 'spa-lm:components/sectionFAQ':
      const hasFaqs = validateAndProcessFAQs(value.switchable, provider);
      return hasFaqs.length > 0 ? true : false;
    case 'spa-lm:components/providerCareTypes':
      const shouldRenderContent = validateProviderCareTypes(provider);
      return shouldRenderContent;
    case 'spa-lm:components/offeringListing':
      if (!provider || status !== ProviderStatus.COMPLETE || !t) return false;
      const amenities = provider?.amenities ?? [];
      const amenitiesLegacy = provider?.amenitiesLegacy ?? {};
      const componentProps = pageContext?.page?.main[value];
      const useLegacyAmenities = componentProps?.useLegacyAmenities;
      const isNewAmenitiesSchemaEnabled = isAmenitiesEmpty(amenities, domain, t)
        ? false
        : useLegacyAmenities
        ? false
        : true;

      const hasAmenities = !isAmenitiesEmpty(
        isNewAmenitiesSchemaEnabled ? amenities : amenitiesLegacy,
        domain,
        t
      );
      return hasAmenities;
    case 'communities':
      const isBasicListing = provider
        ? !providerIsEnhancedAndNotSuppressed(provider)
        : true;
      const nearbyCommunities =
        orderBy(
          extraData?.data?.nearbyProviders?.results,
          ['bayesian_average'],
          ['desc']
        ) || [];
      const showNearbyCommunities =
        extraData?.communities?.enable && nearbyCommunities.length > 0;
      return showNearbyCommunities && isBasicListing;
    default:
      return true;
  }
};

const getTitlesFromColumn = (
  column: Node,
  pageContext: PageContext,
  storyContext: StoryContext,
  provider: Provider | null,
  status: ProviderStatus,
  domain: Domain | undefined,
  t: (key: string, fallback: string) => string
): Array<{ title: string; linkText: string }> => {
  const columnTitles: Array<{ title: string; linkText: string }> = [];
  for (const componentKey of Object.keys(column)) {
    const component = column[componentKey];
    if (component['mgnl:template'] === 'spa-lm:components/story') {
      applyStoryTitles(columnTitles, storyContext);
    }
    if (
      component.headingElement &&
      component.headingElement === 'h2' &&
      component.title &&
      component.linkText &&
      validateComponentRender(
        component['mgnl:template'],
        pageContext,
        component,
        provider,
        status,
        domain,
        t
      )
    ) {
      columnTitles.push({
        title: component.title,
        linkText: component.linkText,
      });
    }

    const nestedSections = component['@nodes'];
    if (nestedSections && nestedSections.length > 0) {
      for (const sectionKey of nestedSections) {
        const section = component[sectionKey];
        if (
          section.headingElement &&
          section.headingElement === 'h2' &&
          section.title &&
          section.linkText
        ) {
          columnTitles.push({
            title: section.title,
            linkText: section.linkText,
          });
        }

        const collapsibleComponents = section['@nodes'];
        if (collapsibleComponents && collapsibleComponents.length > 0) {
          for (const itemKey of collapsibleComponents) {
            const element = section[itemKey];
            if (
              element.headingElement &&
              element.headingElement === 'h2' &&
              element.title &&
              element.linkText
            ) {
              columnTitles.push({
                title: element.title,
                linkText: element.linkText,
              });
            }
          }
        }
      }
    }
  }
  return columnTitles;
};

export const getCustomHeadings = (
  pageContext: PageContext,
  storyContext: StoryContext,
  provider: Provider | null,
  status: ProviderStatus,
  domain: Domain,
  t: (key: string, fallback: string) => string
): Array<{ title: string; linkText: string }> => {
  const titles: Array<{ title: string; linkText: string }> = [];
  pageContext?.page.main &&
    pageContext?.page?.main['@nodes']?.map((value) => {
      if (
        pageContext.page.main[value]['mgnl:template'] ===
        'spa-lm:components/story'
      ) {
        applyStoryTitles(titles, storyContext);
      }

      if (
        pageContext.page.main[value].headingElement &&
        pageContext.page.main[value].headingElement === 'h2' &&
        pageContext.page.main[value].linkText &&
        validateComponentRender(
          pageContext.page.main[value]['mgnl:template'],
          pageContext,
          value,
          provider,
          status,
          domain,
          t
        )
      ) {
        const el = pageContext.page.main[value];

        titles.push({
          title: el?.title,
          linkText: el?.linkText,
        });
      }

      for (let key of nearbySections) {
        const section = pageContext.page.main[value][key];
        if (
          section &&
          section.headingElement &&
          section.headingElement === 'h2' &&
          section.linkText &&
          validateComponentRender(
            key,
            pageContext,
            pageContext.page.main[value][key],
            provider,
            status,
            domain,
            t,
            {
              data: pageContext.page.main[value]?.data || {},
              communities: pageContext.page.main[value]?.communities || {},
            }
          )
        ) {
          if (section?.heading && section?.linkText) {
            titles.push({
              title: section?.heading,
              linkText: section?.linkText,
            });
          }
        }
      }

      const columns = pageContext.page.main[value];
      for (const columnName in columns) {
        if (columnName.startsWith('column')) {
          const columnTitles = getTitlesFromColumn(
            columns[columnName],
            pageContext,
            storyContext,
            provider,
            status,
            domain,
            t
          );
          if (columnTitles.length > 0) {
            titles.push(...columnTitles);
          }
        }
      }
    });

  return titles || [];
};

export const applyStoryTitles = (
  titles: Array<{ title: string; linkText: string }>,
  storyContext: StoryContext
): Array<{ title: string; linkText: string }> => {
  storyContext?.main &&
    storyContext?.main['@nodes']?.map((value) => {
      if (storyContext?.main) {
        if (
          storyContext.main[value].headingElement &&
          storyContext.main[value].headingElement === 'h2' &&
          storyContext.main[value].linkText
        ) {
          const el = storyContext?.main[value];
          titles.push({
            title: el?.title,
            linkText: el?.linkText,
          });
        } else if (
          storyContext.main[value]['mgnl:template'] === 'spa-lm:components/text'
        ) {
          const dom = htmlparser2.parseDocument(
            storyContext.main[value]['text']
          );
          dom.children.map((element) => {
            if (
              element.type === 'tag' &&
              element.name === 'h2' &&
              element.children[0]['data']
            ) {
              titles.push({
                title: element.children[0]['data'],
                linkText: '',
              });
            }
          });
        }
      }
    });
  return titles;
};

export const getStoryTitles = (storyContext: StoryContext): Array<string> => {
  const titles: Array<string> = [];
  const storyTitles: Array<{ title: string; linkText: string }> = [];

  applyStoryTitles(storyTitles, storyContext);

  storyTitles.forEach(function (heading) {
    titles.push(heading.title);
  });

  return titles;
};

export const PageContext = createContext<PageContext>(null);

export const RawPageContext = createContext<Page | null | undefined>(null);
