import { Accordion } from '@chakra-ui/accordion';
import uniqueId from 'lodash/uniqueId';
import { FAQPageJsonLd } from 'next-seo';
import { CatalogFaq, Faq } from '~/contexts/Provider';

import CareTypeFaqAccordionItem from './ProviderFAQAccordionItem';

interface Props {
  items: CatalogFaq[] | Faq[];
}

const CareTypeFaqAccordion: React.FC<Props> = ({ items }) => {
  const questionStructuredMapping = items.map((item) => ({
    questionName: item.content || item.question,
    acceptedAnswerText: item.answer?.content || item.answer || '',
  }));

  const mappedItems = questionStructuredMapping.map((faqItem, index) => {
    const isFirst = index == 0;
    const isLast = index == items.length - 1;

    return (
      <CareTypeFaqAccordionItem
        key={index}
        title={faqItem.questionName}
        content={faqItem.acceptedAnswerText}
        first={isFirst}
        last={isLast}
      />
    );
  });

  return (
    <>
      <Accordion
        allowMultiple
        defaultIndex={[0]}
        borderColor="gray.300"
        borderWidth={1}
        borderRadius="5"
      >
        {mappedItems}
      </Accordion>
      <FAQPageJsonLd
        scriptId={uniqueId('faq-accordion-scheme_')}
        mainEntity={questionStructuredMapping}
      />
    </>
  );
};

export default CareTypeFaqAccordion;
