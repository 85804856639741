import { parse } from 'node-html-parser';

export function truncateToOneParagraphIfProviderIsNotEnhanced(
  description: string,
  isBasic: boolean
) {
  if (isBasic) {
    let firstParagraph: string = '';
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(description);
    if (isHTML) {
      const doc = parse(description);
      const paragraphs = doc.querySelectorAll('p');
      for (let p of paragraphs) {
        if (p.textContent.trim() !== '') {
          firstParagraph = p.outerHTML;
          p.remove();
          break;
        }
      }
      if (firstParagraph) {
        return firstParagraph;
      }
      return description;
    } else {
      const paragraphs = description.split('\n');
      for (let p of paragraphs) {
        if (p.trim() !== '') {
          firstParagraph = p.trim();
          break;
        }
      }
      if (firstParagraph) {
        return `<p>${firstParagraph}</p>`;
      }
    }
  }
  return description; // if no paragraph found or enhanced, return the original description
}
