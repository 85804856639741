const MAX_NUMBER_OF_ATTEMPTS = 5;

export default function trackAnalyticsEvent(
  eventName: string,
  eventParams?: Record<string, unknown>,
  attempt = 0
) {
  if (attempt > MAX_NUMBER_OF_ATTEMPTS) return;
  if (typeof window?.gtag === 'function') {
    window.gtag('event', eventName, eventParams);
  } else {
    setTimeout(
      () => trackAnalyticsEvent(eventName, eventParams, attempt + 1),
      500
    );
  }
}
