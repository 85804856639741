import { ParsedUrlQuery } from 'querystring';
import isACareType from './isACareType';
import isADefaultPage from './isADefaultPage';
import { isAState } from './isAState';

export const getCareTypeFromURL = (
  params: ParsedUrlQuery | null
): string | null => {
  if (!params) return null;

  const careType = params?.careType as string;
  const careTypeOrState = params?.careTypeOrState as string;
  const careTypeOrProvider = params?.careTypeOrProvider as string;
  const careTypeOrCountyOrCity = params?.careTypeOrCountyOrCity as string;

  if (isADefaultPage(careTypeOrState)) return null;
  if (careType) return careType;
  if (isACareType(careTypeOrProvider)) return careTypeOrProvider;
  if (isACareType(careTypeOrCountyOrCity)) return careTypeOrCountyOrCity;
  if (!isAState(careTypeOrState)) return careTypeOrState;

  return null;
};
