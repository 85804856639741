import WriteAReviewLink, {
  ProviderDetailsWriteAReviewLinkProps,
} from '@components/ReviewWidget/WriteAReviewLink';
import { useContext } from 'react';
import ProviderContext from '~/contexts/Provider';

const NewCTAWriteAReviewLink = (
  props: Omit<ProviderDetailsWriteAReviewLinkProps, 'providerName'>
) => {
  const { provider } = useContext(ProviderContext) || {};
  const { name = '' } = provider || {};
  return <WriteAReviewLink {...props} providerName={name} />;
};

export default NewCTAWriteAReviewLink;
