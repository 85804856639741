import { formatCurrency } from '@utils/number';

export function formatMoney(money?: string | number | boolean | null) {
  if (typeof money === 'number' || typeof money === 'string') {
    const value = Number(money);
    if (value > 0) {
      return formatCurrency(value);
    }
    return money;
  }
  return '';
}
