import { useDisclosure } from '@chakra-ui/react';
import trackAnalyticsEvent from '~/utils/analytics';
import { useEffect } from 'react';

export const useNewReview = (
  provider: string,
  defaultIsOpen: boolean = false
) => {
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen,
  });
  useEffect(() => {
    if (defaultIsOpen) {
      onOpen();
    }
  }, [defaultIsOpen, onOpen]);
  const handleClick = () => {
    if (window && window.location) {
      window.location.hash = 'newreview';
    }
    trackAnalyticsEvent('prov_dets_reviews_write_review_pressed', {
      provider: provider,
    });
    onOpen();
  };

  return {
    onClick: handleClick,
    isOpen,
    onClose,
    onOpen,
  };
};
