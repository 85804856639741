import dynamic from 'next/dynamic';
const Modal = dynamic(() => import('@components/Modal'));
import { useState } from 'react';
import { Provider, ProviderService } from '~/contexts/Provider';
import ProviderCareTypeDetails from './ProviderCareTypeDetails';

interface Props {
  provider: Provider;
  service: ProviderService;
  withButton?: boolean;
}

const ProviderCareTypeServiceModal: React.FC<Props> = ({
  provider,
  service,
  withButton,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Modal size="full" visible={visible} onClose={() => setVisible(false)}>
        <ProviderCareTypeDetails
          provider={provider}
          service={service}
          careType={service.category.name}
          description={service.category.description}
          image={service.category.imageURL}
          accommodations={service.accommodations}
          withButton={withButton}
        />
      </Modal>
    </div>
  );
};

export default ProviderCareTypeServiceModal;
