import { pageView } from '@components/Analytics/events/eventContracts';
import getComponentDataFromPage from '@utils/getComponentDataFromPage';
import { Provider } from '~/contexts/Provider';
import { SiteDefinition } from '~/contexts/SiteContext';
import { Catalog } from '~/types/LocaleCatalog';
import { Page } from '~/types/Magnolia';

interface PageViewType {
  pageSessionId: string;
  sessionId: string;
  page: Page;
  provider?: Provider | null;
  catalog?: Catalog | null;
  site?: SiteDefinition;
}

const PageView = ({
  pageSessionId,
  sessionId,
  page,
  provider,
  catalog,
}: PageViewType) => {
  const hasValidSession = sessionId && pageSessionId;

  if (!hasValidSession || !page) {
    return;
  }

  const searchCounts = getComponentDataFromPage({
    page: page || '',
    componentName: 'spa-lm:components/search',
  });
  const enhancedSearchCounts = getComponentDataFromPage({
    page: page || '',
    componentName: 'spa-lm:components/enhancedSearch',
  });

  const pageContract: pageView = {
    location: {
      city: provider?.address?.city ?? catalog?.city ?? '',
      county: provider?.address?.county ?? '',
      state: provider?.address?.state ?? catalog?.state ?? '',
      zip: provider?.address?.zipCode ?? '',
    },
    page: {
      ad_present: false,
      attributes: {
        counts: {
          in_region:
            enhancedSearchCounts?.[0]?.regionCount ??
            searchCounts?.[0]?.regionCount ??
            0,
          nearby: 0, // Not available
          total:
            enhancedSearchCounts?.[0]?.resultCount ??
            searchCounts?.[0]?.resultCount ??
            0,
        },
        page_number: 0,
        sort_order: '',
      },
      category: page.category ?? '',
      layout: page['mgnl:template'] ?? '',
      tags: '', // Not implemented
      theme: page.theme ?? '',
      title: page.title ?? '',
      topic: page.topic ?? '',
      type: page.pageType ?? '',
    },
    page_session_id: pageSessionId ?? '',
    property: {
      id: provider?.legacyId ?? '',
    },
    resource: {
      care_type: page.careType ?? '',
      id: provider?.legacyId ?? '', // Not in provider response, using provider id
      rollup_care_type: page.rollupCareType ?? '',
    },
    session_id: sessionId ?? '',
    url: window.location.href,
  };
  window.tracking.page(pageContract);
};

export default PageView;
