const LEGACY_BASE_URL = 'https://www.caring.com';

const CARING_STARS_URL = `${LEGACY_BASE_URL}/bestseniorliving`;
const IMAGE_DOMAINS = [
  'localhost',
  'canario.stg.caring.com',
  'canario-staging.s3.amazonaws.com',
  'caring-prod-canario-files.s3.amazonaws.com',
  'images.unsplash.com',
  'plus.unsplash.com',
  'dsjrrk2ui1p8q.cloudfront.net',
  'd13iq96prksfh0.cloudfront.net',
  'dlyhjlf6lts50.cloudfront.net',
  'd2xlm7m6z1xtnp.cloudfront.net',
  'assets.seniorhomes.com',
  'www.seniorhomes.com',
  'caring.com',
  'www.seniorhousingnet.com',
];
export { CARING_STARS_URL, LEGACY_BASE_URL, IMAGE_DOMAINS };

export const STARS_BY_SECTION_LABELS = {
  overall: 'Overall',
  health: 'Health',
  quality: 'Quality',
  staff: 'Staff',
};

export const PARAMETRIZED_RATING_LABELS = {
  activities: 'Activities',
  facilities: 'Facilities',
  food: 'Food',
  staff: 'Staff',
  value: 'Value',
};

export const TWENTY_FIVE_MILES_IN_METERS = 40233;
export const DEFAULT_HITS_PER_PAGE = 20;
export const METERS_PER_MILE = 1609.34;
export const DEFAULT_EMPTY_TEXT = '<p></p>\n';
export const DEFAULT_EMPTY_DESCRIPTION = '<p>No Description</p>\n';
export const DEFAULT_PAGE = 0;

export const ACCOUNT_VALIDATIONS = {
  password_strength_regex:
    /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  password_strength_message: `Password does not meet requirements:
          - At least 8 number of characters
          - Capital and lowercase letters
          - Numbers
          - At least one Special character
          `,
  valid_name_regex: /[A-Za-z](?=.{1,29}$)[A-Za-z]{1,}([ ][A-Z][A-Za-z]{1,})*/,
  invalid_field: 'Invalid field',
  zip_code_regex: /(^\d{5}$)|(^\d{5}-?\d{4}$)/,
  email_regex: /\S+@\S+\.\S+/,
};

export const FEATURE_FLAGS = {
  account_feature_flag: 'ACCOUNT_FEATURE_FLAG',
  display_geo_comparison: 'DISPLAY_GEO_COMPARISON',
};

export const CARING_CONTACT_BY_TELEPHONE_URL = `${LEGACY_BASE_URL}/about/contact-by-telephone`;
export const CARING_PRIVACY_URL = `${LEGACY_BASE_URL}/about/privacy`;
export const CARING_TERMS_URL = `${LEGACY_BASE_URL}/about/terms`;
export const CANARY_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export enum REVIEW_TYPES {
  SENIOR_LIVING = 'senior-living',
  SENIOR_CARE = 'senior-care',
  GENERIC = 'generic',
}

export const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const THIRTY_MINUTES_IN_MILLISECONDS = 1000 * 60 * 30;
export const THIRTY_MINUTES_IN_SECONDS = 60 * 30;
