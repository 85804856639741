import { Box } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { handleShare } from '@utils/share';
import { MdIosShare } from 'react-icons/md';

interface ShareButtonProps {
  showText?: boolean;
}

const ShareButton: React.FC<ShareButtonProps> = ({ showText = false }) => {
  const toast = useToast();
  const elementClicked = useElementClicked();

  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="right"
      data-testid="share-button"
    >
      <Box
        display="flex"
        alignItems="center"
        _hover={{ cursor: 'pointer' }}
        onClick={() => {
          handleShare(window.location.href, toast);
          elementClicked({
            element: {
              type: ElementTypes.BUTTON,
              action: ElementActions.SHARE,
              name: ElementNames.INFO_REQUEST_SECTION,
              text: 'share',
              color: 'primary.500',
              textColor: 'white',
            },
          });
        }}
      >
        {(showText || isDesktop) && (
          <Text
            mr={2}
            textDecoration="underline"
            fontSize="sm"
            fontWeight="400"
          >
            Share
          </Text>
        )}

        <MdIosShare size="24px" />
      </Box>
    </Box>
  );
};

export default ShareButton;
