import {
  Box,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
} from '@chakra-ui/layout';
import { MdCheck } from 'react-icons/md';

interface Props {
  sectionLabel: string;
  offeringListLegacy?: string[];
  isExtra?: boolean;
  itemsToShow?: number;
}

const ProviderDetailsAmenitiesSectionLegacy: React.FC<Props> = ({
  sectionLabel,
  offeringListLegacy,
  isExtra,
  itemsToShow = -1,
}) => {
  const WrapperListComponent = isExtra ? Box : List;
  const WrapperItemComponent = isExtra ? Box : ListItem;
  const offeringsToShow =
    itemsToShow > -1
      ? (offeringListLegacy ?? []).slice(0, itemsToShow)
      : offeringListLegacy;

  return (
    <Stack paddingY="4">
      <Heading as="h3" size="md">
        {sectionLabel}
      </Heading>
      <WrapperListComponent>
        {offeringsToShow?.map((offering, index) => (
          <WrapperItemComponent key={index} fontSize="lg">
            <Stack direction="row">
              {!isExtra && (
                <ListIcon as={MdCheck} color="primary.500" boxSize="6" />
              )}
              <span>{offering}</span>
            </Stack>
          </WrapperItemComponent>
        ))}
      </WrapperListComponent>
    </Stack>
  );
};

export default ProviderDetailsAmenitiesSectionLegacy;
