export const handleShare = async (url: string, toast) => {
  const shareAvailable =
    typeof navigator !== 'undefined' && navigator.share !== undefined;
  if (shareAvailable) {
    try {
      await navigator.share({
        url: url,
      });
    } catch (e: unknown) {
      if (e instanceof Error && e.toString().includes('AbortError')) {
        return;
      } else {
        throw e;
      }
    }
  } else {
    try {
      navigator.clipboard.writeText(url);
      toast({
        description: 'Copied the link to this page!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch {
      toast({
        description:
          'Sorry! This browser does not support copying to the clipboard',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }
};
