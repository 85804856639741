import { radioAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  control: {
    bg: 'chakra-body-bg',
    borderColor: 'gray.500',
  },
});

const sizes = {
  lg: definePartsStyle({
    label: { fontSize: 'sm' },
  }),
  md: definePartsStyle({
    label: { fontSize: 'sm' },
  }),
  sm: definePartsStyle({
    label: { fontSize: 'sm' },
  }),
};

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
