import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderRadius: 'xl',
  },
  header: {
    p: 0,
  },
  body: {
    p: 0,
  },
  footer: {
    p: 0,
  },
});

const sizes = {
  sm: definePartsStyle({
    container: {
      p: 4,
      gap: 4,
    },
  }),
  md: definePartsStyle({
    container: {
      p: 6,
      gap: 3,
    },
  }),
  lg: definePartsStyle({
    container: {
      px: { base: 8, md: '54px' },
      py: 16,
      gap: { base: '34px', md: 6 },
    },
  }),
};

const variants = {
  filled: definePartsStyle({
    container: {
      backgroundColor: 'secondary.50',
    },
  }),
};

export const cardTheme = defineMultiStyleConfig({
  defaultProps: {
    size: 'sm',
    variant: 'filled',
  },
  baseStyle,
  sizes,
  variants,
});
