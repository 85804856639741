import { useToken } from '@chakra-ui/react';

const useThemeToRGB = (color: string, alpha?: number | undefined) => {
  const [primary] = useToken('colors', [color]);
  if (primary === '' || primary === undefined) return;
  const r = parseInt(primary.slice(1, 3), 16);
  const g = parseInt(primary.slice(3, 5), 16);
  const b = parseInt(primary.slice(5, 7), 16);
  return `${r}, ${g}, ${b}${alpha ? ', ' + alpha : ''}`;
};

export default useThemeToRGB;
