import { useEffect, useState } from 'react';

export function useLocalStorage<T>(key: string, fallbackValue: T) {
  const [value, setValue] = useState(fallbackValue);
  useEffect(() => {
    try {
      const stored = localStorage.getItem(key);
      setValue(
        stored && stored !== 'undefined' ? JSON.parse(stored) : fallbackValue
      );
    } catch (err) {
      console.error('localStorage is not available');
    }
  }, [fallbackValue, key]);

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.error('localStorage is not available');
    }
  }, [key, value]);

  return [value, setValue] as const;
}
