import Button from '@components/Button';
import { useRouter } from 'next/router';
import { MdArrowBack } from 'react-icons/md';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import { useContext } from 'react';
import SiteContext from '~/contexts/SiteContext';
import { Domains } from '~/types/Domains';
import { Icon } from '@chakra-ui/icon';

const BackButton = ({ showButton = false }: { showButton?: boolean }) => {
  const router = useRouter();
  const { site } = useContext(SiteContext);
  const isCaringWebSite = site?.path === Domains.CaringDomains.LIVE;

  const handleClick = () => {
    router.back();
  };

  if (!isCaringWebSite || !showButton) {
    return null;
  }

  return (
    <Button
      variant="ghost"
      display="flex"
      fontSize="sm"
      alignItems="center"
      onClick={handleClick}
      elementName={ElementNames.BACK_BUTTON}
      elementAction={ElementActions.GO_BACK}
      elementType={ElementTypes.BUTTON}
      px={1}
      py={1}
      minWidth="auto"
    >
      <Icon as={MdArrowBack} boxSize={8} aria-hidden={true} mr={2} />
      Back
    </Button>
  );
};

export default BackButton;
