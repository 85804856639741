import { SessionStorageKeys } from '@hooks/use-session-storage-value';
import sessionStore from '@utils/sessionStorage';

export const setUTMParameters = (query: {
  [key: string]: any;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_term?: string;
}): void => {
  try {
    if (!sessionStore.enabled) return;

    const { utm_source, utm_campaign, utm_content, utm_medium, utm_term } =
      query;
    utm_source && sessionStore.set(SessionStorageKeys.UTM_SOURCE, utm_source);
    utm_campaign &&
      sessionStore.set(SessionStorageKeys.UTM_CAMPAIGN, utm_campaign);
    utm_content &&
      sessionStore.set(SessionStorageKeys.UTM_CONTENT, utm_content);
    utm_medium && sessionStore.set(SessionStorageKeys.UTM_MEDIUM, utm_medium);
    utm_term && sessionStore.set(SessionStorageKeys.UTM_TERM, utm_term);
  } catch (err) {
    console.log('setUTMParameters');
    console.log(err);
  }
};

export const getUTMParameters = (): {
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_term?: string;
} => {
  const utm_params = {};
  try {
    if (!sessionStore.enabled) return utm_params;

    const utm_source = sessionStore.get(SessionStorageKeys.UTM_SOURCE);
    const utm_campaign = sessionStore.get(SessionStorageKeys.UTM_CAMPAIGN);
    const utm_content = sessionStore.get(SessionStorageKeys.UTM_CONTENT);
    const utm_medium = sessionStore.get(SessionStorageKeys.UTM_MEDIUM);
    const utm_term = sessionStore.get(SessionStorageKeys.UTM_TERM);

    utm_source && (utm_params['utm_source'] = utm_source);
    utm_campaign && (utm_params['utm_campaign'] = utm_campaign);
    utm_content && (utm_params['utm_content'] = utm_content);
    utm_medium && (utm_params['utm_medium'] = utm_medium);
    utm_term && (utm_params['utm_term'] = utm_term);
  } catch (err) {
    console.log('getUTMParameters');
    console.log(err);
  }

  return utm_params;
};
