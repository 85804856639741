import { ButtonGroup, IconButton } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/layout';
import GraphQLImage from '@components/Image/GraphQLImage';
import Container from '@components/LayoutStructure/Container';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import { getBackgroundColor } from '@utils/getColor';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { FooterNavigation } from '../Navigation.types';

export interface FooterProps {
  intro: FooterNavigation['intro'];
  logo: FooterNavigation['logo'];
  main: FooterNavigation['main'];
  secondary: FooterNavigation['secondary'];
  backgroundColor?: string;
  backgroundColorRange?: string;
}

const Footer: React.FC<FooterProps> = ({
  intro,
  logo,
  main,
  secondary,
  backgroundColor = 'primary',
  backgroundColorRange = '700',
}) => {
  const { mobileLogoMaxWidth, logoMaxWidth } = logo;
  return (
    <Container
      ignoreMaxWidth
      as="footer"
      role="contentinfo"
      bg={getBackgroundColor(backgroundColor, backgroundColorRange)}
      textColor="white"
    >
      <Container py="8">
        <Stack spacing="8">
          <Grid
            gap={{
              lg: 0,
              base: 2,
            }}
            templateColumns={{
              lg: 'repeat(3, 1fr)',
              base: 'repeat(1, 1fr)',
            }}
          >
            <GridItem
              colSpan={{
                base: 3,
                md: 1,
              }}
              pr={{
                base: 0,
                lg: 8,
              }}
              mb={{
                lg: 0,
                base: 8,
              }}
            >
              <Box
                textAlign={{
                  md: 'left',
                  base: 'center',
                }}
              >
                <Box display={{ base: 'block', md: 'none' }} overflow="auto">
                  {logo.mobileLogo && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      mb="8"
                      mx="auto"
                    >
                      <GraphQLImage
                        src={logo.mobileLogo}
                        alt={logo.logoAlt}
                        height={Number(mobileLogoMaxWidth) || 44}
                        width={Number(mobileLogoMaxWidth || 51)}
                      />
                    </Box>
                  )}
                  <Text
                    fontSize="md"
                    mt={0}
                    color="white"
                    dangerouslySetInnerHTML={{ __html: intro }}
                  />
                </Box>
                <Box display={{ base: 'none', md: 'block' }}>
                  {logo.logo && (
                    <Box style={{ float: 'left' }} mr={5}>
                      <GraphQLImage
                        src={logo.logo}
                        alt={logo.logoAlt}
                        height={Number(logoMaxWidth) || 44}
                        width={Number(logoMaxWidth) || 51}
                      />
                    </Box>
                  )}
                  <Text
                    fontSize="md"
                    mt={0}
                    color="white"
                    dangerouslySetInnerHTML={{ __html: intro }}
                  />
                </Box>
              </Box>
            </GridItem>
            <GridItem
              colSpan={{
                base: 3,
                md: 2,
              }}
            >
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: `repeat(${main.length}, 1fr)`,
                }}
                templateRows={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(1, 1fr)',
                }}
                minWidth={{ md: 'xl' }}
                rowGap={8}
                columnGap={8}
              >
                {main.map((section) => (
                  <VStack key={section.text} alignItems="start" spacing="8">
                    <Heading size="sm" color="white" as="p" fontWeight="bold">
                      {section.text}
                    </Heading>
                    <UnorderedList spacing="1">
                      {section.children.map((link) => (
                        <ListItem ml={4} key={link.url}>
                          <Text fontSize="md">
                            <Link href={link.url} color="white">
                              {link.text}
                            </Link>
                          </Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </VStack>
                ))}
              </Grid>
            </GridItem>
          </Grid>
          <Divider colorScheme="whiteAlpha" />
          <Stack
            direction={{ base: 'column-reverse', md: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems="center"
          >
            <Text fontSize="sm" color="white">
              &copy; {new Date().getFullYear()} Caring, LLC. All rights
              reserved.
            </Text>
            <ButtonGroup>
              <HStack justifyContent="space-between">
                {secondary
                  .filter((item) => item.link.enabled)
                  .map((item, i) => (
                    <IconButton
                      key={i}
                      bg="primary.700"
                      color="white"
                      _hover={{ bg: 'primary.800' }}
                      as="a"
                      href={item.link.url}
                      aria-label={item.text}
                      icon={
                        <Icon
                          as={STRING_TO_ICON_CLASS[item.icon]}
                          boxSize={6}
                          display="block"
                        />
                      }
                    />
                  ))}
              </HStack>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
};

export default withHydrationOnDemand({
  on: ['visible'],
})(Footer);
