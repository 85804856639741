import { Box, VStack } from '@chakra-ui/layout';
import { isEmptyText } from '@utils/isEmptyText';
import Paragraph from '@components/Paragraph';
import { getBackgroundColor } from '@utils/getColor';
import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { HeadingElements } from '~/@types/heading';
import { Metadata } from '~/types/Magnolia';
import Section from './Section';
const ProgressChart = dynamic(() => import('@components/Charts/ProgressChart'));
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

interface SectionChartProps {
  title?: string;
  headingElement?: HeadingElements;
  text?: string;
  subtext?: string;
  buttonLabel?: string;
  buttonLink?: string;
  displayValuePrefix?: string;
  chartData?: string;
  metadata: Metadata;
  boxShadow?: 'lg' | 'md' | 'sm' | 'none';
  barColor?: string;
  textAlignment?: 'left' | 'center' | 'right';
  titleAlignment?: 'left' | 'center' | 'right';
  bgBorderRadius?: string;
  bgColor?: string;
  bgColorRange?: string;
  textColor?: 'default' | 'light' | 'dark';
}

interface ChartDataRecord {
  label: string;
  value: number;
  displayValue: string;
  color: string;
}

const SectionChart = ({
  title,
  headingElement,
  text,
  subtext,
  buttonLabel,
  buttonLink,
  chartData,
  metadata,
  displayValuePrefix = '',
  barColor = 'default',
  boxShadow = 'none',
  textAlignment = 'left',
  titleAlignment = 'left',
  textColor = 'dark',
  bgColor = 'secondary',
  bgColorRange = '50',
  bgBorderRadius,
}: SectionChartProps): React.ReactElement => {
  let json: { data: Array<ChartDataRecord> } | null = null;

  if (chartData) {
    try {
      json = JSON.parse(chartData);
      if (!Array.isArray(json?.data)) {
        console.error('Invalid chartData: provided JSON data is not an array');
      }
    } catch {
      console.error(
        'Invalid chartData: please check your data is a properly formatted JSON Array'
      );
    }
  }
  const data = json?.data || [];
  const onlyValues = data.map((item) => item.value);

  const maxValue = Math.max(...onlyValues);
  const getValue = (value, maxValue) =>
    (100 * Number(value)) / Number(maxValue);

  const getDisplayValue = (value) =>
    value && (displayValuePrefix || '$') + value.toLocaleString('en-US');

  const colorRange = ['red', 'secondary', 'primary', 'blue'];

  const getColor = (color, index) =>
    color ? color : colorRange[index % colorRange.length];

  const textIsEmpty = isEmptyText(text);
  const hasData = json && data;
  if (!title && textIsEmpty && !hasData) return <></>;

  const backgroundColor = getBackgroundColor(bgColor, bgColorRange);

  const txtColor =
    textColor === 'dark' || textColor === 'default' ? 'gray.800' : 'gray.50';

  return (
    <Container as="section">
      <Box
        boxShadow={boxShadow}
        textColor={txtColor}
        borderRadius={bgBorderRadius}
        bg={backgroundColor}
        p={8}
      >
        {(!textIsEmpty || title) && (
          <Box mb={8}>
            <Section
              title={title}
              headingElement={headingElement}
              richText={text}
              buttonLabel={buttonLabel}
              buttonLink={buttonLink}
              textAlignment={textAlignment}
              titleAlignment={titleAlignment}
              richTextColor={txtColor}
            />
          </Box>
        )}
        {data.length > 0 && (
          <VStack gap="6" w="full">
            {data.map((data, key) => (
              <ProgressChart
                key={key}
                textColor={txtColor}
                id={`chart-${metadata['@id']}-${key}`}
                label={data.label}
                value={getValue(data.value, maxValue)}
                displayValue={getDisplayValue(data.value)}
                color={
                  barColor === 'default' ? getColor(data.color, key) : barColor
                }
              />
            ))}
          </VStack>
        )}
        {subtext && <Paragraph text={subtext} />}
      </Box>
    </Container>
  );
};

export default withHydrationOnDemand({
  on: ['visible'],
})(SectionChart);
