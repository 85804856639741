import { createContext, Dispatch, useContext, useReducer } from 'react';

type ModalReducerActionType =
  | { type: 'SHOW_MODAL'; payload: string }
  | { type: 'HIDE_MODAL'; payload: string };

type ModalContextType = string[];

type ModalDispatchContextType = Dispatch<ModalReducerActionType>;

const initialVisibleModals: ModalContextType = [];
const ModalContext = createContext<ModalContextType>([]);
const ModalDispatchContext = createContext<ModalDispatchContextType>(
  () => null
);

const visibleModalsReducer = (
  state: ModalContextType,
  action: ModalReducerActionType
) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return [...state, action.payload];
    case 'HIDE_MODAL':
      return state.filter((modal) => modal !== action.payload);
    default:
      throw new Error();
  }
};

export const ModalProvider = ({ children }) => {
  const [visibleModals, dispatch] = useReducer(
    visibleModalsReducer,
    initialVisibleModals
  );

  return (
    <ModalContext.Provider value={visibleModals}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalContext.Provider>
  );
};

export const useVisibleModals = () => {
  const visibleModals = useContext(ModalContext);
  const isVisible = (modalName: string) => visibleModals.includes(modalName);
  return { visibleModals, isVisible };
};

export const useModalDispatch = () => {
  const dispatch = useContext(ModalDispatchContext);
  const showModal = (modalName: string) =>
    dispatch({ type: 'SHOW_MODAL', payload: modalName });
  const hideModal = (modalName: string) =>
    dispatch({ type: 'HIDE_MODAL', payload: modalName });
  return { showModal, hideModal };
};

export const useModal = (modalName: string) => {
  const { showModal, hideModal } = useModalDispatch();
  const { isVisible } = useVisibleModals();
  const visible = isVisible(modalName);
  const show = () => showModal(modalName);
  const hide = () => hideModal(modalName);
  return { visible, show, hide };
};

export default ModalContext;
