import { BreadcrumbItem } from '@components/Navigation/Breadcrumb';
import {
  FilteredEntries,
  StateDataResponse,
} from '@components/Sitemap/SitemapState/SitemapState.types';
import { NextRouter, useRouter } from 'next/router';
import { createContext, useContext } from 'react';
import { Domains } from '~/types/Domains';
import { Catalog, Link } from '~/types/LocaleCatalog';
import { TrailingSlashLogicType } from '~/types/TenantFunctions';
import { Provider } from './Provider';
import { buildCaringFunction } from './TenantFunctions/CaringFunctions';
import { buildDefaultFunction } from './TenantFunctions/DefaultFunctions';
import { buildSeniorHomesFunction } from './TenantFunctions/SeniorHomesFunctions';

export interface TenantFunctions {
  getProviderDetailsPath: (provider: Provider) => string;
  getProviderDescription: (provider: Provider) => string;
  getBreadcrumbLinks: (props: {
    router: NextRouter;
    provider: Provider | null | undefined;
    catalog: Catalog | null | undefined;
  }) => BreadcrumbItem[];
  getCareTypePath: (
    resource: Link,
    router: NextRouter,
    enableTrailingSlash: boolean
  ) => string;
  getCareTypePathForSitemap: (router: NextRouter) => string;
  getCareTypeParamsForSitemap: (router: NextRouter) => CareTypeUrlParams;
  generateContentForStateSitemap(
    filteredEntries: FilteredEntries[]
  ): StateDataResponse | void;
  getTrailingSlashURL: (url: string, type?: TrailingSlashLogicType) => string;
  getReviewPath: (props: {
    rollupType?: string;
    state: string;
    city: string;
    slug: string;
    url?: string;
  }) => string;
  getCountyForSearch: (county: string) => string;
}

export const TenantFunctionsContext = createContext<TenantFunctions>(
  buildDefaultFunction()
);

interface CareTypeUrlParams {
  state: string;
  cityOrCounty: string;
  careType: string;
}
interface TenantFunctionProvider {
  tenant: string;
  children: React.ReactNode;
}

export const TenantFunctionsProvider: React.FC<TenantFunctionProvider> = ({
  tenant,
  children,
}) => {
  const router = useRouter();
  const tenantFunctions = tenantSwitcher(tenant, router);

  return (
    <TenantFunctionsContext.Provider value={{ ...tenantFunctions }}>
      {children}
    </TenantFunctionsContext.Provider>
  );
};

export const useTenantFunctions = () => {
  const tenantFunctions = useContext(TenantFunctionsContext);
  if (tenantFunctions === undefined) {
    throw new Error(
      '🚨 useTenantFunctions must be used within a TenantFunctionsContext'
    );
  }

  return tenantFunctions;
};

export const tenantSwitcher = (
  tenant: string,
  router?: NextRouter
): TenantFunctions => {
  switch (tenant) {
    case Domains.SeniorHomesDomains.LIVE:
      return buildSeniorHomesFunction();
    case Domains.CaringDomains.LIVE:
      return buildCaringFunction(router);
    default:
      return buildDefaultFunction();
  }
};
