export const HEADER_DESKTOP_HEIGHT = 72;
const HEADER_MOBILE_HEIGHT = 56;
const STICKY_TOP_OFFSET = 24;

const LAYOUT = {
  COLUMN_GAP: '20px',
  DESKTOP_TEMPLATE_COLUMNS: 'repeat(3, 1fr)',
  MOBILE_TEMPLATE_COLUMNS: 'repeat(2, 1fr)',

  // Container
  CONTAINER_HORIZONTAL_PADDING: '32px',
  CONTAINER_VERTICAL_PADDING: '32px',
  CONTAINER_MAX_WIDTH: '1280px',
  CONTAINER_DESKTOP_SPACING: '64px',
  CONTAINER_MOBILE_SPACING: '32px',

  // Content
  DESKTOP_TOP_PADDING: '48px',
  MOBILE_TOP_PADDING: '32px',

  // Sidebar
  SIDEBAR_DESKTOP_SPACING: '16px',
  SIDEBAR_MOBILE_SPACING: '16px',

  // Header
  HEADER_DESKTOP_HEIGHT: `${HEADER_DESKTOP_HEIGHT}px`,
  HEADER_MOBILE_HEIGHT: `${HEADER_MOBILE_HEIGHT}px`,

  // Sticky
  STICKY_TOP_OFFSET: `${HEADER_DESKTOP_HEIGHT + STICKY_TOP_OFFSET}px`,
};

export default LAYOUT;
