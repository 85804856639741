import { Box } from '@chakra-ui/layout';
import HtmlToReact from '@components/HtmlToReact';
import { isEmptyText } from '@utils/isEmptyText';
import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';

const LoadContentOnClientSide = dynamic(
  () => import('@components/LoadContentOnClientSide')
);
interface ParagraphProps {
  text?: string;
  clientSideRendering?: boolean;
  textAlignment?: 'left' | 'center' | 'right';
}

const Paragraph: React.FC<ParagraphProps> = ({
  text,
  clientSideRendering,
  textAlignment = 'left',
}) => {
  const textIsEmpty = isEmptyText(text);
  const parsedText = text && !textIsEmpty ? HtmlToReact({ html: text }) : null;
  if (parsedText === null) return null;

  return (
    <Box
      className="magnolia-text"
      textStyle="paragraph"
      textAlign={textAlignment}
      px={{ base: 4, lg: 8 }}
      maxW="container.xl"
      py="8"
      mx="auto"
    >
      {!clientSideRendering && parsedText}
      {clientSideRendering && <LoadContentOnClientSide content={parsedText} />}
    </Box>
  );
};

export default withHydrationOnDemand()(Paragraph);
